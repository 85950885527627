<template>
  <div class="products w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-10">
          <h3>Produkter</h3>
        </div>
      </div>
      <div class="mb-4"></div>
      <div class="row">
        <div class="col-md-5">
          <categories />
        </div>
        <div class="col-md-5">
          <standards :standards="standards" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import Categories from "./categories.vue";
import Standards from "./standards.vue";
export default {
  name: "products",
  data() {
    return {
      category: [],
      // standards: [
      // 	'Ipsum, dolor sit amet consectetur adipisicing elit',
      // 	'Dolor sit amet consectetur adipisicing',
      // 	'Voluptates ipsam nobis quam nulla et sequi labore blanditiis',
      // 	'Dolor facere dolorem quos tenetur aspernatur'
      // ]
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey", "standards"]),
  },

  components: {
    Navbar,
    Categories,
    Standards,
  },

  mounted() {
    this.$store
      .dispatch("fetchCategories")
      .then((res) => {
        if (res.data.status == "error") {
          this.$store.dispatch("logout");
          this.$router.push({ name: "login" });
        }
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch("logout");
        this.$router.push({ name: "login" });
      });
  },
};
</script>


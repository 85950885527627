<template>
  <div class="economy w-100"  style="background-color: #fafafa">
    <navbar headertext="Transaktioner" />
    <div class="content p-4">
      <CustomerHeader :customer="customer" v-if="customer != null" />
      <div class="row">
        <div class="col-md-2">
          <input
            type="number"
            ref="am"
            placeholder="Försäkringsnr"
            v-model="insuranceNr"
            @keyup="checkInsuranceNr()"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <select v-model="eventType" class="form-control">
            <option value="addATB">Justera återbäring 2020</option>
          </select>
        </div>
        <div class="col-md-2">
          <input
            type="number"
            v-model="amount"
            class="form-control"
            placeholder="Belopp"
          />
        </div>
        <div class="col-md-2">
          <b-button @click="save()"> {{ buttonText }} </b-button>
        </div>
      </div>
      <div class="row" style="margin-top: 24px">
        <div class="col-md-12">
          <table class="table" v-if="payments != null && payments.length > 0">
            <thead>
              <tr>
                <th>Datum</th>
                <th style="text-align: right;">Belopp</th>
                <th>Text</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, index) in payments" :key="index">
                <td>{{ p.logDate }}</td>
                <td style="text-align: right">
                  {{ p.amount.toLocaleString("sv") }}
                </td>
                <td>{{ p.notes }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td><b>Saldo</b></td>
                <td style="text-align: right">
                  <b>{{ balance.toLocaleString("sv") }}</b>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CustomerHeader from "@/components/CustomerHeader.vue";
import { mapState } from "vuex";

var moment = require("moment");
moment.locale("sv");

export default {
  name: "economy-payment",
  components: {
    Navbar,
    CustomerHeader,
  },
  computed: {
    ...mapState(["hidden", "baseUrl", "apiKey", "accesslevel"]),
  },

  data() {
    return {
      customer: null,
      amount: null,
      wrongAmount: true,
      wrongOcr: true,
      insuranceNr: "",
      eventType: "addATB",
      date: moment().format("YYYYY-MM-DD"),
      payments: [],
      balance: 0,
      searching: false,
      buttonText: "Spara",
    };
  },

  mounted() {
    this.setFocus();
    if (this.$route.query.insuranceNr != null) {
      this.insuranceNr = this.$route.query.insuranceNr;
      this.getAccount();
    }
  },

  methods: {
    setFocus() {
      this.$refs.am.focus();
    },
    getAccount() {
      this.searching = true;
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=account&insuranceID=" +
            this.insuranceNr +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.customer = response.data.data.customer;
            this.payments = response.data.data.account;
            this.balance = response.data.data.balance;
            this.searching = false;
          } else {
            this.wrongOcr = true;
          }
        })
        .catch((error) => {
          this.searching = false;
          this.wrongOcr = true;
          console.log(error);
        });
    },
    save() {
      this.buttonText = "Sparar...";
      window.axios
        .get(
          this.baseUrl +
            "api/edit.php?type=addATB&insuranceID=" +
            this.customer.insuranceID +
            "&amount=" +
            this.amount +
            "&year=2020" +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.buttonText = "Spara";
            this.amount = null;
            this.getAccount();
          } else {
            this.buttonText = "Spara";
          }
        })
        .catch((error) => {
          this.searching = false;
          this.wrongOcr = true;
          console.log(error);
        });
    },

    checkInsuranceNr() {
      var n = this.insuranceNr;
      this.customer = null;
      if (n.length == 6) {
        this.getAccount();
      }
    },
  },
};
</script>

<style scoped>
.red {
  background-color: #ffaaaa;
}
.green {
  background-color: lightgreen;
}
.white {
  background-color: white;
}
input {
  margin-bottom: 8px;
}
</style>
import { render, staticRenderFns } from "./standards.vue?vue&type=template&id=5a926040&scoped=true&"
import script from "./standards.vue?vue&type=script&lang=js&"
export * from "./standards.vue?vue&type=script&lang=js&"
import style0 from "./standards.vue?vue&type=style&index=0&id=5a926040&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a926040",
  null
  
)

export default component.exports
<template>
  <div class="products-category w-100">
    <navbar />
    <div class="content p-4">
      <div class="row products">
        <div class="col-md-12 header">
          <button
            class="btn btn-link"
            @click="back()"
            v-if="SELECTED_TREE != 'PARENT'"
          >
            <font-awesome-icon icon="chevron-left" /> Back to category
          </button>
        </div>
        <div class="col-md-3">
          <h1>Produkter</h1>
          <div class="content-lists" v-if="categoryProducts.length > 0">
            <product-card
              @edit-product="editProduct($event)"
              @navigate="linkPressed($event)"
              @show-product="showProductContent($event)"
              :product="product"
              v-for="(product, index) in categoryProducts"
              :key="index"
              :selected="product.selected || false"
            />
          </div>
          <template v-if="categoryProducts.length == 0">
            <div class="alert alert-secondary" role="alert">
              <p>Ingen produkt hittades</p>
            </div>
          </template>
          <button
            class="btn btn-info mt-3 btn-block"
            @click="addProduct((isParentCategory = true))"
          >
            <font-awesome-icon icon="plus-circle" /> Ny produkt
          </button>
        </div>
        <div class="col-md-9">
          <div class="row" v-if="product != null">
            <div class="col-md-6">
              <h1>Rådgivningstexter</h1>
              <div class="guidelines">
                <table class="table table-striped" v-if="guidelines.length > 0">
                  <thead>
                    <tr>
                      <th scope="col">Text</th>
                      <th scope="col">Valfri</th>
                      <th scope="col">LF</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(guideline, index) in guidelines" :key="index">
                      <template v-if="!guideline.editable">
                        <td class="name">{{ guideline.name }}</td>
                        <td class="option">
                          {{ guideline.optional == "n" ? "Nej" : "Ja" }}
                        </td>
                        <td class="option">
                          {{ guideline.showLf == "n" ? "Nej" : "Ja" }}
                        </td>
                        <td class="action">
                          <span class="edit" @click="editGuideline(guideline)">
                            <font-awesome-icon icon="edit" />
                          </span>
                          <span
                            class="remove"
                            @click="removeGuideline(guideline)"
                          >
                            <font-awesome-icon icon="trash-alt" />
                          </span>
                        </td>
                      </template>
                      <template v-if="guideline.editable">
                        <td colspan="3">
                          <textarea
                            class="form-control"
                            rows="3"
                            v-model="guideline.name"
                          ></textarea>
                          <label>Valfri</label>
                          <div class="form-check mt-1">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="optionYes"
                              value="y"
                              v-model="guideline.optional"
                            />
                            <label class="form-check-label" for="optionYes"
                              >Ja</label
                            >
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="optionNo"
                              value="n"
                              v-model="guideline.optional"
                            />
                            <label class="form-check-label" for="optionNo"
                              >Nej</label
                            >
                          </div>
                          <hr />
                          <label>Visa för Länsförsäkringar</label>
                          <div class="form-check mt-1">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="lfYes"
                              value="y"
                              v-model="guideline.showLf"
                            />
                            <label class="form-check-label" for="lfYes"
                              >Ja</label
                            >
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="lfNo"
                              value="n"
                              v-model="guideline.showLf"
                            />
                            <label class="form-check-label" for="lfNo"
                              >Nej</label
                            >
                          </div>
                          <button
                            type="button"
                            class="btn btn-success btn-sm btn-block mt-2"
                            @click="updateGuideline(guideline)"
                          >
                            Spara
                          </button>
                          <button
                            type="button"
                            class="btn btn-secondary btn-sm btn-block mt-2"
                            @click="closeEditGuideline(guideline)"
                          >
                            Avbryt
                          </button>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="alert alert-secondary"
                role="alert"
                v-if="guidelines.length == 0"
              >
                Ingen rådgivningstext inlagd.
              </div>
              <div class="divider mb-3"></div>
              <h5>Ny rådgivningstext</h5>
              <div class="create-guideline">
                <div
                  class="alert alert-success"
                  role="alert"
                  v-if="
                    successName == 'GUIDELINE' &&
                    submitStatus == 'OK' &&
                    successMessage != null
                  "
                >
                  {{ successMessage }}
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    placeholder="Rådgivningstext"
                    v-model.trim="$v.guideline.name.$model"
                    :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' &&
                        !$v.guideline.name.required &&
                        submitName == 'GUIDELINE',
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="!$v.guideline.name.required"
                  >
                    Ange text
                  </div>
                </div>
                <div class="form-group">
                  <label>Valfri</label>
                  <div
                    class="form-check"
                    :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' &&
                        !$v.guideline.optional.required &&
                        submitName == 'GUIDELINE',
                    }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="optional-yes"
                      value="y"
                      v-model="guideline.optional"
                    />
                    <label class="form-check-label" for="optional-yes"
                      >Ja</label
                    >
                  </div>
                  <div
                    class="form-check"
                    :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' &&
                        !$v.guideline.optional.required &&
                        submitName == 'GUIDELINE',
                    }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="optional-no"
                      value="n"
                      v-model="guideline.optional"
                    />
                    <label class="form-check-label" for="optional-no"
                      >Nej</label
                    >
                  </div>
                  <div
                    class="invalid-feedback"
                    v-if="!$v.guideline.optional.required"
                  >
                    Välj alternativ
                  </div>
                  <hr />
                  <label>Visa för Länsförsäkringar</label>
                  <div
                    class="form-check"
                    :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' &&
                        !$v.guideline.showLf.required &&
                        submitName == 'GUIDELINE',
                    }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="lf-yes"
                      value="y"
                      v-model="guideline.showLf"
                    />
                    <label class="form-check-label" for="lf-yes">Ja</label>
                  </div>
                  <div
                    class="form-check"
                    :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' &&
                        !$v.guideline.showLf.required &&
                        submitName == 'GUIDELINE',
                    }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="lf-no"
                      value="n"
                      v-model="guideline.showLf"
                    />
                    <label class="form-check-label" for="lf-no">Nej</label>
                  </div>
                  <div
                    class="invalid-feedback"
                    v-if="!$v.guideline.showLf.required"
                  >
                    Välj alternativ
                  </div>
                </div>
                <button
                  type="button"
                  class="btn btn-info btn-block"
                  @click="saveGuideLine()"
                >
                  Spara rådgivning
                </button>
              </div>
            </div>
            <div class="col-md-6">
              <h1>Intervall för premiefaktor</h1>
              <div class="intervals">
                <table class="table table-striped" v-if="intervals.length > 0">
                  <thead>
                    <tr>
                      <th scope="col">Startvärde</th>
                      <th scope="col">Slutvärde</th>
                      <th scope="col">Faktor</th>
                      <th scope="col">Giltighetstid</th>
                      <th scope="col">&nbsp;</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(interval, index) in intervals" :key="index">
                      <td>{{ interval.startValue }}</td>
                      <td>{{ interval.endValue }}</td>
                      <td>{{ interval.factor }}</td>
                      <td>{{ interval.startDate }}</td>
                      <td>
                        {{
                          interval.endDate == "9999-12-31"
                            ? "-"
                            : interval.endDate
                        }}
                      </td>
                      <td class="action">
                        <span class="edit" @click="editInterval(interval)">
                          <font-awesome-icon icon="edit" />
                        </span>
                        <span class="remove" @click="deleleInterval(interval)">
                          <font-awesome-icon icon="trash-alt" />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="alert alert-secondary"
                  role="alert"
                  v-if="intervals.length == 0"
                >
                  Inga intervall sparade.
                </div>
              </div>
              <div class="divider mb-3"></div>
              <div class="create-interval">
                <div
                  class="alert alert-success"
                  role="alert"
                  v-if="
                    successName == 'INTERVAL' &&
                    submitStatus == 'OK' &&
                    successMessage != null
                  "
                >
                  {{ successMessage }}
                </div>
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="startValue"
                    placeholder="Fr o m belopp"
                    v-model.trim="$v.interval.startValue.$model"
                    :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' &&
                        !$v.interval.startValue.required &&
                        submitName == 'INTERVAL',
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="!$v.interval.startValue.required"
                  >
                    Ange startvärde
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="endValue"
                    placeholder="T o m belopp"
                    v-model.trim="$v.interval.endValue.$model"
                    :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' &&
                        !$v.interval.endValue.required &&
                        submitName == 'INTERVAL',
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="!$v.interval.endValue.required"
                  >
                    Ange slutvärde
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    id="factor"
                    placeholder="Premiefaktor"
                    v-model.trim="$v.interval.factor.$model"
                    :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' &&
                        !$v.interval.factor.required &&
                        submitName == 'INTERVAL',
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="!$v.interval.factor.required"
                  >
                    Ange faktor
                  </div>
                </div>
                <div class="form-group">
                  <label for="startDate">Startdatum</label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Start datum"
                    id="startDate"
                    v-model.trim="$v.interval.startDate.$model"
                    :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' &&
                        !$v.interval.startDate.required &&
                        submitName == 'INTERVAL',
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="!$v.interval.startDate.required"
                  >
                    Ange startdatum
                  </div>
                </div>
                <div class="form-group">
                  <label for="endDate">Slutdatum</label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Slut datum"
                    id="endDate"
                    v-model.trim="$v.interval.endDate.$model"
                    :class="{
                      'is-invalid':
                        submitStatus == 'ERROR' &&
                        !$v.interval.endDate.required &&
                        submitName == 'INTERVAL',
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="!$v.interval.startDate.required"
                  >
                    Ange slutdatum
                  </div>
                </div>
                <button
                  v-if="!isIntervalEditable"
                  type="button"
                  class="btn btn-info btn-block"
                  @click="saveInterval()"
                >
                  Spara intervall
                </button>
                <template v-if="isIntervalEditable">
                  <button
                    type="button"
                    class="btn btn-warning btn-block"
                    @click="updateInterval()"
                  >
                    Uppdatera intervall
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary btn-block"
                    @click="closeInterval()"
                  >
                    Avbryt
                  </button>
                </template>
              </div>
            </div>
          </div>
          <div
            class="alert alert-info mt-4"
            role="alert"
            v-if="product == null"
          >
            <p>
              Klicka på försäkringen för att hämta underprodukter.<br />
              Klicka på <font-awesome-icon icon="edit" /> för att redigera
              försäkringens egenskaper som namn, avgift och villkor.<br />
              Klicka på
              <font-awesome-icon icon="file-alt" />
              för att redigera rådgivningstexter och intervall för premiefaktor.
            </p>
          </div>
        </div>
      </div>

      <div class="modal">
        <b-modal id="modal-1" size="lg" :title="title" v-model="showModal">
          <div
            class="alert alert-success"
            role="alert"
            v-if="submitStatus == 'OK' && successMessage != null"
          >
            {{ successMessage }}
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="street"
              placeholder="Produktnamn"
              v-model.trim="$v.productName.$model"
              :class="{
                'is-invalid':
                  submitStatus == 'ERROR' &&
                  !$v.productName.required &&
                  submitName == 'PRODUCT',
              }"
            />
            <div class="invalid-feedback" v-if="!$v.productName.required">
              Ange produktnamn
            </div>
          </div>
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              id="flatFee"
              placeholder="Fast avgift"
              v-model.trim="$v.flatFee.$model"
              :class="{
                'is-invalid':
                  submitStatus == 'ERROR' &&
                  !$v.flatFee.required &&
                  submitName == 'PRODUCT',
              }"
            />
            <div class="invalid-feedback" v-if="!$v.flatFee.required">
              Ange fast avgift
            </div>
          </div>
          <div class="form-group">
            <select
              id="inputState"
              class="form-control"
              v-model="evaluationType"
              @change="onEvaluationTypeChange()"
              :class="{
                'is-invalid':
                  submitStatus == 'ERROR' &&
                  !$v.evaluationType.required &&
                  submitName == 'PRODUCT',
              }"
            >
              <option :value="null" selected>Välj värderingstyp</option>
              <template v-if="evaluationTypes.length > 0">
                <option
                  v-for="(evaluation, index) in evaluationTypes"
                  :key="index"
                  :value="evaluation"
                >
                  {{ evaluation.name }}
                </option>
              </template>
            </select>
            <div class="invalid-feedback" v-if="!$v.evaluationType.required">
              Välj värderingstyp
            </div>
          </div>
          <div class="form-group">
            <select
              id="inputState"
              class="form-control"
              v-model="evaluationForm"
              :class="{
                'is-invalid':
                  submitStatus == 'ERROR' &&
                  !$v.evaluationForm.required &&
                  submitName == 'PRODUCT',
              }"
            >
              <option :value="null" selected>Välj värderingsformulär</option>
              <template v-if="evaluationForms.length > 0">
                <option
                  v-for="(evaluationForm, index) in evaluationForms"
                  :key="index"
                  :value="evaluationForm"
                >
                  {{ evaluationForm.formName }}
                </option>
              </template>
            </select>
            <div class="invalid-feedback" v-if="!$v.evaluationType.required">
              Välj värderingsformulär
            </div>
          </div>
          <div class="form-group">
            <select
              id="inputState"
              class="form-control"
              v-model="term"
              :class="{
                'is-invalid':
                  submitStatus == 'ERROR' &&
                  !$v.term.required &&
                  submitName == 'PRODUCT',
              }"
            >
              <option :value="null" selected>Välj villkor</option>
              <template v-if="terms.length > 0">
                <option
                  v-for="(term, termKey) in terms"
                  :key="termKey"
                  :value="term"
                >
                  {{ term.term }}
                </option>
              </template>
            </select>
            <div class="invalid-feedback" v-if="!$v.term.required">
              Välj villkor
            </div>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="street"
              placeholder="Enhet"
              v-model.trim="unit"
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput1">Bonus</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                v-model="bonus"
                id="yes"
                value="y"
              />
              <label class="form-check-label" for="yes">Ja</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                v-model="bonus"
                id="no"
                value="n"
              />
              <label class="form-check-label" for="no">Nej</label>
            </div>
            <div class="invalid-feedback" v-if="!$v.bonus.required">
              Välj bonus
            </div>
          </div>
          <template v-slot:modal-footer="{ cancel }">
            <b-button variant="secondary" @click="cancel()">Avbryt</b-button>
            <b-button
              variant="success"
              @click="saveProduct()"
              :disabled="submitStatus === 'PENDING'"
              v-if="!editable"
              >Spara</b-button
            >
            <b-button
              variant="success"
              @click="updateProduct()"
              :disabled="submitStatus === 'PENDING'"
              v-if="editable"
              >Uppdatera</b-button
            >
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import ProductCard from "@/components/ProductCard.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "products-category",
  data() {
    return {
      showModal: false,
      selectedCategory: null,
      product: null,
      SELECTED_TREE: null,
      productID: null,
      productName: null,
      evaluationType: null,
      evaluationForm: null,
      flatFee: null,
      parentID: null,
      parentIsCategory: null,
      submitStatus: null,
      successMessage: null,
      categoryProducts: [],
      title: "Ny produkt",
      evaluationForms: [],
      guidelines: [],
      intervals: [],
      guideline: {
        name: null,
        optional: null,
      },
      interval: {
        intervalID: null,
        startValue: null,
        endValue: null,
        factor: null,
        startDate: null,
        endDate: null,
      },
      isIntervalEditable: false,
      submitName: null,
      successName: null,
      terms: [],
      term: null,
      editable: false,
      isSelected: false,
      selectedGuideline: null,
      bonus: null,
      unit: null,
    };
  },

  validations: {
    productName: { required },
    flatFee: { required },
    evaluationType: { required },
    guideline: {
      name: { required },
      optional: { required },
      showLf: { required },
    },
    interval: {
      startValue: { required },
      endValue: { required },
      factor: { required },
      startDate: { required },
      endDate: { required },
    },
    evaluationForm: {
      required: requiredIf(function () {
        return (
          this.evaluationType != null &&
          this.evaluationType.evaluationTypeID != 0
        );
      }),
    },
    term: { required },
    bonus: { required },
  },

  computed: {
    ...mapState([
      "baseUrl",
      "apiKey",
      "evaluationTypes",
      "categories",
      "products",
      "subproducts",
    ]),
  },

  components: {
    Navbar,
    ProductCard,
  },

  mounted() {
    var categoryID = this.$route.params.categoryID;
    this.parentID = parseInt(categoryID);
    this.selectedCategory = this.categories.find(
      (category) => category.categoryID == categoryID
    );
    this.$store
      .dispatch("fetchProducts")
      .then(() => {
        this.filterProducts();
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch("logout");
        this.$router.push({ name: "login" });
      });

    this.fetchEvaluationForm();
    this.fetchTerms();
  },

  methods: {
    back() {
      var categoryID = this.$route.params.categoryID;
      this.parentID = parseInt(categoryID);
      this.categoryProducts = this.products.filter(
        (product) =>
          product.parentID == this.parentID && product.parentIsCategory == "y"
      );
      this.categoryProducts.map((catProduct) => (catProduct.selected = false));
      this.SELECTED_TREE = "PARENT";
    },

    filterProducts() {
      this.categoryProducts = this.products.filter(
        (product) =>
          product.parentID == this.parentID && product.parentIsCategory == "y"
      );
      this.SELECTED_TREE = "PARENT";
    },

    addProduct(isParentCategory) {
      this.editable = false;
      this.title = "Ny produkt";
      this.productName = null;
      this.flatFee = null;
      this.evaluationType = null;
      this.evaluationForm = null;
      this.term = null;
      this.parentIsCategory = isParentCategory ? "y" : "n";
      this.showModal = !this.showModal;
      this.successMessage = null;
    },

    linkPressed(productID) {
      this.categoryProducts = this.subproducts.filter(
        (sp) => sp.parentID == productID
      );

      this.product = null;
      this.SELECTED_TREE = "CHILD";

      // change route
    },

    showProductContent(selectedProduct) {
      this.categoryProducts.map((product) => {
        if (product == selectedProduct) {
          product.selected = true;
        } else {
          product.selected = false;
        }
      });
      this.product = selectedProduct;
      this.parentID = selectedProduct.productID;
      this.product.products = this.products.filter(
        (p) =>
          p.parentID == selectedProduct.productID && p.parentIsCategory == "n"
      );

      this.fetchGuideLines();
      this.fetchIntervals();
    },

    editProduct(product) {
      this.editable = true;
      this.evaluationType = this.evaluationTypes.find(
        (ets) => ets.evaluationTypeID == product.evaluationTypeID
      );
      this.evaluationForm =
        this.evaluationForms.find(
          (efs) => efs.formID == product.evaluationFormID
        ) || null;
      this.productID = product.productID;
      this.title = product.name;
      this.productName = product.name;
      this.flatFee = product.flatFee;
      this.term = this.terms.find((term) => term.termID == product.termID);
      this.bonus = product.bonus;
      this.unit = product.unit;
      this.parentIsCategory = product.parentIsCategory;
      this.showModal = true;
    },

    updateProduct() {
      this.$v.productName.$touch();
      this.$v.flatFee.$touch();
      this.$v.evaluationType.$touch();
      this.$v.evaluationForm.$touch();
      this.$v.bonus.$touch();

      if (
        this.$v.productName.$invalid ||
        this.$v.flatFee.$invalid ||
        this.$v.evaluationType.$invalid ||
        this.$v.evaluationForm.$invalid ||
        this.$v.bonus.$invalid
      ) {
        this.submitStatus = "ERROR";
        this.submitName = "PRODUCT";
      } else {
        const formID =
          this.evaluationForm != null && this.evaluationForm.formID > 0
            ? this.evaluationForm.formID
            : 0;
        var formData = new FormData();
        formData.append("id", this.productID);
        formData.append("name", this.productName);
        formData.append(
          "evaluationTypeID",
          parseInt(this.evaluationType.evaluationTypeID)
        );
        formData.append("evaluationFormID", formID);
        formData.append("flatFee", this.flatFee);
        formData.append("parentID", this.parentID);
        formData.append("parentIsCategory", this.parentIsCategory);
        formData.append("termID", this.term.termID);
        formData.append("bonus", this.bonus);
        formData.append("unit", this.unit);
        window.axios
          .post(this.baseUrl + "api/product.php?key=" + this.apiKey, formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.productName = null;
              this.evaluationType = null;
              this.evaluationForm = null;
              this.flatFee = null;
              this.bonus = null;
              this.unit = null;
              this.submitStatus = "OK";
              this.showModal = false;
              this.editable = false;

              this.$store.dispatch("fetchProducts");
              this.fetchEvaluationForm();

              setTimeout(() => {
                this.filterProducts();
              }, 500);

              this.$swal("Meddelande", "Produkten har uppdaterat", "success");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("logout");
            this.$router.push({ name: "login" });
          });
      }
    },

    saveProduct() {
      this.$v.productName.$touch();
      this.$v.flatFee.$touch();
      this.$v.evaluationType.$touch();
      this.$v.evaluationForm.$touch();
      if (
        this.$v.productName.$invalid ||
        this.$v.flatFee.$invalid ||
        this.$v.evaluationType.$invalid ||
        this.$v.evaluationForm.$invalid
      ) {
        this.submitStatus = "ERROR";
        this.submitName = "PRODUCT";
      } else {
        const formID =
          this.evaluationForm != null && this.evaluationForm.formID > 0
            ? this.evaluationForm.formID
            : 0;
        var formData = new FormData();
        formData.append("name", this.productName);
        formData.append(
          "evaluationTypeID",
          parseInt(this.evaluationType.evaluationTypeID)
        );
        formData.append("evaluationFormID", formID);
        formData.append("flatFee", this.flatFee);
        formData.append("parentID", this.parentID);
        formData.append("parentIsCategory", this.parentIsCategory);
        formData.append("termID", this.term.termID);

        window.axios
          .post(this.baseUrl + "api/product.php?key=" + this.apiKey, formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$store.dispatch("fetchProducts");
              this.productName = null;
              this.evaluationType = null;
              this.evaluationForm = null;
              this.flatFee = null;
              this.submitStatus = "OK";
              this.showModal = false;

              this.$swal("Meddelande", "Produkten har sparats", "success");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("logout");
            this.$router.push({ name: "login" });
          });
      }
    },

    fetchEvaluationForm() {
      window.axios
        .get(this.baseUrl + "api/get.php?type=forms&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.evaluationForms = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchGuideLines() {
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=guidelines&productID=" +
            this.product.productID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            var guidelines = response.data.data;
            if (guidelines.length > 0) {
              guidelines.map((guideline) => (guideline.editable = false));
            }
            this.guidelines = guidelines;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchIntervals() {
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=intervals&productID=" +
            this.product.productID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.intervals = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchTerms() {
      window.axios
        .get(this.baseUrl + "api/get.php?type=draftTerms&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.terms = response.data.data;
          }
        });
    },

    saveGuideLine() {
      this.$v.guideline.name.$touch();
      this.$v.guideline.optional.$touch();
      if (
        this.$v.guideline.name.$invalid ||
        this.$v.guideline.optional.$invalid
      ) {
        this.submitStatus = "ERROR";
        this.submitName = "GUIDELINE";
      } else {
        var formData = new FormData();
        formData.append("name", this.guideline.name);
        formData.append("optional", this.guideline.optional);
        formData.append("productID", this.product.productID);
        formData.append("showLf", this.guideline.showLf);

        window.axios
          .post(this.baseUrl + "api/guideline.php?key=" + this.apiKey, formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.guideline.name = null;
              this.fetchGuideLines();
            }
          });
      }
    },

    saveInterval() {
      this.$v.interval.startValue.$touch();
      this.$v.interval.endValue.$touch();
      this.$v.interval.factor.$touch();
      this.$v.interval.startDate.$touch();
      this.$v.interval.endDate.$touch();
      if (
        this.$v.interval.startValue.$invalid ||
        this.$v.interval.endValue.$$invalid ||
        this.$v.interval.factor.$invalid
      ) {
        this.submitStatus = "ERROR";
        this.submitName = "INTERVAL";
      } else {
        var formData = new FormData();
        formData.append("startValue", this.interval.startValue);
        formData.append("endValue", this.interval.endValue);
        formData.append("factor", this.interval.factor);
        formData.append("productID", this.product.productID);
        formData.append("startDate", this.interval.startDate);
        formData.append("endDate", this.interval.endDate);

        window.axios
          .post(this.baseUrl + "api/interval.php?key=" + this.apiKey, formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.fetchIntervals();
              this.submitStatus = "OK";
              this.successName = "INTERVAL";
              this.successMessage = "Interval värde har sparat";
              this.interval = {
                startValue: null,
                endValue: null,
                factor: null,
              };
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    onEvaluationTypeChange() {
      if (
        this.evaluationType == null ||
        this.evaluationType.evaluationTypeID == 0
      ) {
        this.evaluationForm = null;
      }
    },

    editGuideline(guideline) {
      this.guidelines.forEach((gl) => {
        if (gl == guideline) {
          gl.editable = true;
        } else {
          gl.editable = false;
        }
      });
    },

    closeEditGuideline(guideline) {
      guideline.editable = false;
    },

    updateGuideline(guideline) {
      if (guideline.name != null || guideline.name != "") {
        var formData = new FormData();
        formData.append("id", guideline.guidelineID);
        formData.append("name", guideline.name);
        formData.append("optional", guideline.optional);
        formData.append("productID", guideline.productID);
        formData.append("showLf", guideline.showLf);
        window.axios
          .post(this.baseUrl + "api/guideline.php?key=" + this.apiKey, formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.fetchGuideLines();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    removeGuideline(guideline) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Vill du verkligen radera rådgivning?",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Radera",
          cancelButtonText: "Avbryt",
        })
        .then((result) => {
          if (result.isConfirmed) {
            var formData = new FormData();
            formData.append("id", guideline.guidelineID);
            formData.append("action", "delete");
            window.axios
              .post(
                this.baseUrl + "api/guideline.php?key=" + this.apiKey,
                formData
              )
              .then((response) => {
                if (response.data.status == "ok") {
                  this.fetchGuideLines();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    },

    editInterval(interval) {
      this.isIntervalEditable = true;
      this.interval = {
        intervalID: interval.intervalID,
        startValue: interval.startValue,
        endValue: interval.endValue,
        factor: interval.factor,
        startDate: interval.startDate,
        endDate: interval.endDate,
      };
      this.submitStatus = null;
      this.successName = null;
      this.successMessage = null;
    },

    deleleInterval(interval) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Vill du verkligen radera interval?",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Radera",
          cancelButtonText: "Avbryt",
        })
        .then((result) => {
          if (result.isConfirmed) {
            var formData = new FormData();
            formData.append("id", interval.intervalID);
            formData.append("action", "delete");
            window.axios
              .post(
                this.baseUrl + "api/interval.php?key=" + this.apiKey,
                formData
              )
              .then((response) => {
                if (response.data.status == "ok") {
                  this.fetchIntervals();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    },

    closeInterval() {
      this.isIntervalEditable = false;
      this.interval = {
        intervalID: null,
        startValue: null,
        endValue: null,
        factor: null,
        startDate: null,
        endDate: null,
      };
      // this.$nextTick(() => { this.$v.interval.$reset(); });
      // setTimeout(() => { this.$v.interval.$reset() }, 0)
    },

    updateInterval() {
      this.$v.interval.startValue.$touch();
      this.$v.interval.endValue.$touch();
      this.$v.interval.factor.$touch();
      this.$v.interval.startDate.$touch();
      this.$v.interval.endDate.$touch();
      if (
        this.$v.interval.startValue.$invalid ||
        this.$v.interval.endValue.$$invalid ||
        this.$v.interval.factor.$invalid ||
        this.$v.interval.startDate.$invalid ||
        this.$v.interval.endDate.$invalid
      ) {
        this.submitStatus = "ERROR";
        this.submitName = "INTERVAL";
      } else {
        var formData = new FormData();
        formData.append("id", this.interval.intervalID);
        formData.append("startValue", this.interval.startValue);
        formData.append("endValue", this.interval.endValue);
        formData.append("factor", this.interval.factor);
        formData.append("productID", this.product.productID);
        formData.append("startDate", this.interval.startDate);
        formData.append("endDate", this.interval.endDate);
        window.axios
          .post(this.baseUrl + "api/interval.php?key=" + this.apiKey, formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.isIntervalEditable = false;
              this.interval = {
                intervalID: null,
                startValue: null,
                endValue: null,
                factor: null,
                startDate: null,
                endDate: null,
              };
              this.fetchIntervals();
              this.submitStatus = "OK";
              this.successName = "INTERVAL";
              this.successMessage = "Interval värde har uppdaterat";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.products-category {
  .vl {
    border-left: 1px solid #ddd;
  }
}

.products {
  h1,
  .guidelines h1,
  .intervals h1 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .header {
    margin-bottom: 15px;
    button {
      margin-left: 0;
      padding-left: 0;
      color: #636363;
      font-size: 14px;
      font-weight: 600;
    }
    .card {
      background: #f1f1f1;
      border: 0;
    }
  }
  .divider {
    border-top: 1px solid #ddd;
  }
  .guidelines,
  .intervals {
    table {
      th {
        font-size: 13px;
      }
      tr {
        font-size: 15px;
        .action {
          display: flex;
          flex-direction: row;
          // justify-content: space-around;
          .edit {
            color: #2874a6;
            font-size: 14px;
            margin-right: 10px;
            cursor: pointer;
            &:hover {
              color: #5dade2;
            }
          }
          .remove {
            color: #cb4335;
            font-size: 14px;
            cursor: pointer;
            &:hover {
              color: #ec7063;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="objects">
    <div class="row">
      <div class="col-md-2">
        <template v-if="selectedProducts.length > 0">
          <div class="draggable-item mb-3">
            <ul v-if="objectLists.length > 0">
              <li
                v-for="(object, objectIndex) in objectLists"
                :key="objectIndex"
                draggable
                @dragstart="(status === 'draft') ? startDrag($event, object) : null"
              >
                {{ object.name }}
              </li>
            </ul>
          </div>
        </template>
      </div>
      <div class="col-md-10">
        <template v-if="insurances.length > 0">
          <ul class="objectlist">
            <li
              class="object"
              v-for="(insurance, index) in productsWithObjects"
              :key="index"
              :class="{ dragging: insurance.dragging }"
              @dragenter="(status === 'draft') ? onDragging(insurance) : null"
              @dragover="(status === 'draft') ? onDragging(insurance) : null"
              @dragleave="(status === 'draft') ? onLeaving(insurance) : null"
              @dragend="(status === 'draft') ? onLeaving(insurance) : null"
              @drop="(status === 'draft') ? onDrop($event, insurance) : null"
              @dragstart.prevent
              @dragenter.prevent
              @dragover.prevent
            >
              <p class="title">{{ insurance.name }}</p>
              <div class="objects-rw" v-if="insurance.objects.length > 0">
                <ul>
                  <li v-for="(obj, key) in insurance.objects" :key="key">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-row">
                          <div class="col">
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Ange namn"
                                v-model="obj.title"
                                v-on:keyup="updateObject(obj)"
                                @change="inputChanged(obj)"
                              />
                            </div>
                          </div>
                          <button
                            type="button"
                            v-if="insurance.evaluationFormID > 0"
                            class="btn btn-light"
                            @click="getEvaluationForms(insurance, obj)"
                          >
                            <font-awesome-icon icon="home" size="6x" />
                          </button>
                          <button
                            type="button"
                            v-if="insurance.evaluationFormID == 2"
                            class="btn btn-light ml-2"
                            @click="getBuildingForm(insurance, obj)"
                          >
                            <font-awesome-icon icon="clipboard" />
                          </button>
                          <div class="col">
                            <div class="form-group">
                              <input
                                v-if="insurance.evaluationFormID >= 0"
                                type="number"
                                class="form-control"
                                :placeholder="insurance.unit == 'SEK' || insurance.unit == null ? 'Värdering' : insurance.unit"
                                v-model="obj.objectValue"
                                @keyup="updateObject(obj)"
                                @change="inputChanged(obj)"
                              />
                              <input
                                v-if="insurance.evaluationFormID == -1"
                                type="text"
                                class="form-control"
                                placeholder="Personnummer"
                                v-model="obj.objectValue"
                                @keyup="updateObject(obj)"
                                @change="inputChanged(obj)"
                              />
                            </div>
                          </div>
                          <div class="col">
                            <button
                              type="button"
                              class="btn btn-danger"
                              @click="deleteObject(obj)"
                            >
                              <font-awesome-icon
                                icon="trash-alt"
                                class="remove"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        :class="{
                          'col-md-2': obj.files.length > 0,
                          'col-md-6': obj.files.length == 0,
                        }"
                      >
                        <vue-dropzone
                          ref="fileDropzone"
                          :options="dropzoneOptions"
                          id="customdropzone"
                          @vdropzone-drop="onFileDrop(obj)"
                          :useCustomSlot="true"
                        >
                          <div class="dropzone-custom-content">
                            <h3 class="dropzone-custom-title">
                              Dra och släpp filen här
                            </h3>
                          </div>
                        </vue-dropzone>
                      </div>
                      <div class="col-md-4" v-if="obj.files.length > 0">
                        <div class="files">
                          <figure
                            class="figure"
                            v-for="(file, fileIndex) in obj.files"
                            :key="fileIndex"
                          >
                            <img
                              :src="require('@/assets/pdf.png')"
                              v-if="file.mimetype == 'application/pdf'"
                            />
                            <img
                              :src="getURL(file.fileID)"
                              v-if="file.mimetype !== 'application/pdf'"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </template>
        <div class="alert alert-primary" role="alert" v-else>
          <p>Inga produkter valda som ska associeras med objekt.</p>
        </div>
      </div>
    </div>

    <div class="modal">
      <b-modal
        ref="vuemodal"
        id="modal-1"
        size="lg"
        :title="'Huvudbyggnad'"
        v-model="showModal"
        :hide-footer="buildingType == null"
        @hide="onHideModal"
      >
        <template
          v-if="buildingType == null && !loading && evaluationFormID == 2"
        >
          <building-types
            :buildings="buildingTypes"
            @select-house="houseTypeSelected"
            @building-selected="selectedHouseType"
          />
        </template>
        <template v-if="isFormAvailable">
          <div
            class="form-group"
            v-for="(ef, efKey) in evaluationForms"
            :key="efKey"
          >
            <label>{{ ef.question }}</label>
            <template v-if="ef.options.length == 0">
              <input
                type="text"
                class="form-control"
                v-model="ef.value"
                v-if="ef.options.length == 0"
              />
            </template>
            <template v-if="ef.options.length > 0">
              <div
                class="form-check"
                v-for="(option, optionKey) in ef.options"
                :key="optionKey"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  :id="'option' + option.optionID"
                  :name="'option' + ef.formID + efKey"
                  :value="option.points"
                  @change="onOptionChanged(ef, optionKey)"
                />
                <label
                  class="form-check-label"
                  :for="'option' + option.optionID"
                  >{{ option.optionName }}</label
                >
              </div>
            </template>
          </div>
          <div v-if="evaluationFormID == 8">
            <h3>Klasser</h3>
            <ol>
              <li>
                Isolerade djurstallar exklusive byggnadsinventarier.
                Verkstadslokaler.
              </li>
              <li>
                Oisolerade stallar och maskinhallar i bra skick som ska byggas
                upp i ungefär den storlek och planlösning som fanns före skadan.
              </li>
              <li>
                Gamla ekonomibyggnader i bra skick men svåra att utnyttja på ett
                rationellt sätt. Värde sätts enligt rationellt värde, dvs som
                nuvarande byggnad nyttjas.
              </li>
              <li>
                Äldre ekonomibyggnader, överskottshus, med sämre underhåll.
              </li>
              <li>Rivningsobjekt.</li>
            </ol>
          </div>
          <button
            type="button"
            class="btn btn-success btn-lg btn-block"
            v-if="evaluationFormID == 4 && !loading"
            @click="updateObjectFarmField()"
          >
            Spara
          </button>
          <button
            type="button"
            class="btn btn-success btn-lg btn-block"
            v-if="evaluationFormID == 8 && !loading"
            @click="updateObjectFarmBuilding()"
          >
            Spara
          </button>
        </template>
        <template
          v-slot:modal-footer="{ cancel }"
          v-if="buildingType != null && !loading"
        >
          <b-button variant="secondary" @click="cancel()">Avbryt</b-button>
          <b-button variant="success" @click="saveEvaluationForms()"
            >Spara</b-button
          >
        </template>
        <div class="text-center mt-4 mb-4" v-if="loading">
          <pulse-loader :loading="loading" size="18px"></pulse-loader>
        </div>
      </b-modal>
    </div>

    <div class="Outbuilding-modal">
      <b-modal
        ref="vuemodal"
        id="modal-1"
        size="lg"
        :title="'Uthus'"
        v-model="showOutbuilding"
        :hide-footer="true"
        @hide="reset()"
      >
        <div class="form-group">
          <label for="building-area">Byggnadyta</label>
          <input
            type="number"
            class="form-control"
            id="building-area"
            placeholder="Byggnadyta"
            v-model="buildingArea"
          />
        </div>
        <div class="form-group">
          <label for="exterior-walls">Ytterväggar</label>
          <select
            class="form-control"
            id="exterior-walls"
            v-model="exteriorWalls"
          >
            <option :value="null">Välj ytterväggar</option>
            <option value="n">Ej vinterbonade</option>
            <option value="y">Vinterbonade</option>
          </select>
        </div>
        <button
          type="button"
          class="btn btn-success btn-lg btn-block mb-4 mt-2"
          @click="saveSmallBuilding()"
        >
          Spara
        </button>
      </b-modal>
    </div>
  </div>
</template>

<script>
import houseTypes from "@/assets/building_types.json";
import { mapState } from "vuex";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import BuildingTypes from "@/components/BuildingTypes.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import _ from "lodash";
export default {
  name: "insurance-object",
  props: ["insuranceid", "status"],
  data() {
    return {
      buildingTypes: [],
      evaluationForms: [],
      showModal: false,
      objectID: null,
      dropzoneOptions: {
        url: null,
        params: {
          objectID: null,
        },
        headers: {
          "Cache-Control": null,
          "X-Requested-With": null,
        },
        thumbnailWidth: 150,
        maxFilesize: 100,
        uploadMultiple: true,
        parallelUploads: 1000,
        // autoProcessQueue: false,
        // autoQueue: false,
      },
      objectLists: [
        {
          itemID: 1,
          name: "Byggnad",
          selected: false,
        },
        {
          itemID: 2,
          name: "Person",
          selected: false,
        },
        {
          itemID: 3,
          name: "Föremål",
          selected: false,
        },
      ],
      dragging: false,
      buildingType: null,
      totalAreas: 0,
      totalPoints: 0,
      loading: false,
      evaluationFormID: null,
      componentKey: 0,
      showOutbuilding: false,
      buildingArea: 0,
      exteriorWalls: null,
      insurance: null,
      object: null,
    };
  },

  computed: {
    ...mapState([
      "baseUrl",
      "apiKey",
      "selectedProducts",
      "insurances",
    ]),
    productsWithObjects: function () {
      return this.insurances.filter((ins) => {
        if (ins.evaluationTypeID == 0) {
          return false;
        } else {
          return true;
        }
      });
    },

    isFormAvailable: function () {
      return (
        (this.evaluationForms.length > 0 &&
          this.buildingType != null &&
          !this.loading) ||
        this.evaluationFormID != 2
      );
    },
  },

  watch: {
    evaluationForms: function () {
      this.evaluationForms.map((efs) => {
        efs.value = null;
        efs.options.map((option) => (option.selected = false));
      });
    },
  },

  components: {
    vueDropzone: vue2Dropzone,
    BuildingTypes,
    PulseLoader,
  },

  created() {
    this.dropzoneOptions.url =
      "https://test.akerboharad.se/api/uploadObject.php?key=" + this.apiKey;
  },

  mounted() {
    this.buildingTypes = houseTypes;
    // 'Content-Type': 'application/x-www-form-urlencoded'
    this.dropzoneOptions.url =
      "https://test.akerboharad.se/api/uploadObject.php?key=" + this.apiKey;
    this.$store.dispatch("fetchAllObjects", this.insuranceid);
  },

  methods: {
    startDrag(event, ol) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("itemID", ol.itemID);
    },

    onDragging(insurance) {
      if (insurance.evaluationTypeID == 2) {
        this.$store.commit("INSURANCE_ON_DROP", insurance);
      }
    },

    onLeaving(insurance) {
      this.$store.commit("INSURANCE_ON_LEAVING", insurance);
    },

    onObjectDrop() {},

    onDrop(event, insurance) {
      const itemID = event.dataTransfer.getData("itemID");
      const item = this.objectLists.find((item) => item.itemID == itemID);
      item.selected = true;

      if (insurance.evaluationTypeID == 2) {
        window.axios
          .get(
            this.baseUrl +
              "api/draft.php?type=createObject&draftProductID=" +
              insurance.draftProductID +
              "&key=" +
              this.apiKey
          )
          .then(() => {
            this.$store.dispatch("fetchAllObjects", this.insuranceid);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    inputChanged(obj) {
      obj.changed = true;
      obj.saved = false;
    },

    updateObject: _.debounce(function (obj) {
      const objID = obj.objectID;
      const title = obj.title;
      const value = obj.objectValue;
      window.axios
        .get(
          this.baseUrl +
            "api/draft.php?type=updateObject&objectID=" +
            objID +
            "&title=" +
            title +
            "&value=" +
            value +
            "&key=" +
            this.apiKey
        )
        .then(() => {
          this.$store.dispatch("fetchAllObjects", this.insuranceid);
          obj.changed = false;
          obj.saved = true;
          this.componentKey += 1;
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000),

    deleteObject(obj) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Vill du verkligen radera objektet?",
          inputPlaceholder: "Anledning",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Radera",
          cancelButtonText: "Avbryt",
          showLoaderOnConfirm: true,
        })
        .then((result) => {
          if (result.value) {
            window.axios
              .get(
                this.baseUrl +
                  "api/draft.php?type=deleteObject&objectID=" +
                  obj.objectID +
                  "&key=" +
                  this.apiKey
              )
              .then((response) => {
                if (response.data.status == "ok") {
                  this.$swal.fire("Klart!", "Objekt är raderad.", "success");
                  this.$store.dispatch("fetchAllObjects", this.insuranceid);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    },

    getEvaluationForms(insurance, object) {
      this.evaluationFormID = insurance.evaluationFormID;
      this.objectID = object.objectID;
      this.showModal = true;
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=evaluationForm&formID=" +
            insurance.evaluationFormID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.evaluationForms = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    calculateTotalPoints() {
      this.totalPoints = 0;
      if (this.evaluationForms.length > 0) {
        this.evaluationForms.map((efs) => {
          if (efs.options.length > 0) {
            efs.options.map((option) => {
              if (option.selected) {
                this.totalPoints += option.points;
              }
            });
          } else if (efs.questionID == 11) {
            this.totalAreas = parseInt(efs.value);
          } else {
            var value =
              efs.value != null && efs.value != "" ? parseInt(efs.value) : 0;
            this.totalPoints += efs.questionPoints * value;
          }
        });
      }
    },

    saveEvaluationForms() {
      this.loading = true;
      this.calculateTotalPoints();
      this.saveEvaluationForm().then((response) => {
        this.loading = true;
        if (response.data.status == "ok") {
          var res = response.data.data;
          // Update object value
          this.updateObjectValue(res.value);
        } else {
          this.loading = false;
        }
      });
    },

    updateObjectValue(value) {
      window.axios
        .get(
          this.baseUrl +
            "api/draft.php?type=updateObject&objectID=" +
            this.objectID +
            "&value=" +
            value +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.$store
              .dispatch("fetchAllObjects", this.insuranceid)
              .then(() => {
                this.loading = false;
                this.buildingType = null;
                this.showModal = false;
              });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    saveEvaluationForm() {
      var points = this.totalPoints;
      var area = this.totalAreas;
      var houseType = this.buildingType.id;
      return new Promise((resolve, reject) => {
        window.axios
          .get(
            this.baseUrl +
              "api/get.php?type=value&points=" +
              points +
              "&houseType=" +
              houseType +
              "&area=" +
              area +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            this.loading = false;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    onFileDrop(obj) {
      this.dropzoneOptions.params.objectID = obj.objectID;
    },

    getURL(fileID) {
      return (
        this.baseUrl +
        "api/getFile.php?fileID=" +
        fileID +
        "&key=" +
        this.apiKey
      );
    },

    onOptionChanged(ef, optionKey) {
      const selectedIndex = this.evaluationForms.indexOf(ef);
      if (ef.options.length > 0) {
        this.evaluationForms[selectedIndex].options.map((option, index) => {
          if (index == optionKey) {
            option.selected = true;
          } else {
            option.selected = false;
          }
        });
      }
    },

    houseTypeSelected(building) {
      this.buildingTypes.map((bt) => {
        if (bt == building) {
          bt.selected = true;
        } else {
          bt.selected = false;
        }
      });
    },

    selectedHouseType() {
      this.buildingType = this.buildingTypes.find((bt) => bt.selected);
    },

    onHideModal() {
      this.buildingType = null;
      // this.buildingTypes.map((building) => building.selected = false)
    },

    updateObjectFarmField() {
      this.loading = true;
      this.saveFarmFields().then((response) => {
        this.loading = true;
        if (response.data.status == "ok") {
          let res = response.data.data;

          // Update object value
          this.updateObjectValue(res.value);
        } else {
          this.loading = false;
        }
      });
    },

    updateObjectFarmBuilding() {
      this.loading = true;
      this.saveFarmBuilding().then((response) => {
        this.loading = true;
        if (response.data.status == "ok") {
          let res = response.data.data;

          // Update object value
          this.updateObjectValue(res.value);
        } else {
          this.loading = false;
        }
      });
    },

    saveFarmBuilding() {
      var rating = 0;
      var area = 0;
      this.evaluationForms.map((ef) => {
        if (ef.questionID == 51) {
          rating = ef.value;
        } else if (ef.questionID == 50) {
          area = ef.value;
        }
      });
      return new Promise((resolve, reject) => {
        window.axios
          .get(
            this.baseUrl +
              "api/get.php?type=valueFarmBuilding&area=" +
              area +
              "&rating=" +
              rating +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    saveFarmFields() {
      var forest = 0;
      var field = 0;
      this.evaluationForms.map((ef) => {
        if (ef.questionID == 33) {
          forest = ef.value;
        } else if (ef.questionID == 38) {
          field = ef.value;
        }
      });
      return new Promise((resolve, reject) => {
        window.axios
          .get(
            this.baseUrl +
              "api/get.php?type=valueFarm&forest=" +
              forest +
              "&field=" +
              field +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getBuildingForm(insurance, object) {
      this.insurance = insurance;
      this.object = object;
      this.objectID = object.objectID;
      this.showOutbuilding = true;
    },

    saveSmallBuilding() {
      this.valueSmallBuilding().then((res) => {
        if (res.data.status == "ok") {
          let newValue = res.data.data.value;
          let value = newValue + this.object.objectValue;
          this.updateObjectValue(value);
          this.buildingArea = 0;
          this.exteriorWalls = null;
          this.insurance = null;
          this.object = null;
          this.showOutbuilding = false;
        } else {
          this.showOutbuilding = false;
        }
      });
    },

    // api/get.php?type=valueSmallBuilding&area=&winter
    valueSmallBuilding() {
      return new Promise((resolve, reject) => {
        window.axios
          .get(
            this.baseUrl +
              "api/get.php?type=valueSmallBuilding&area=" +
              this.buildingArea +
              "&winter" +
              this.exteriorWalls +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    reset() {
      this.buildingArea = 0;
      this.exteriorWalls = null;
    },
  },
};
</script>

<style lang="scss">
.objects {
  .alert p {
    margin: 0;
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      font-weight: 600;
      background: #f7f7f7;
      list-style: none;
      padding: 20px 25px;
    }
    li:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }
  .draggable-item {
    li:hover {
      border: 2px dashed #f7dc6f;
      cursor: pointer;
      background: #fef9e7;
      padding: 18px 23px;
    }
  }
  .objectlist {
    border-top: 1px solid #ddd;
    padding: 0;
    margin: 0;
    .object {
      list-style: none;
      padding: 0;
      margin: 0;
      padding: 20px 15px;
      position: relative;
      &:hover {
        background: #f1f1f1;
        .objects-rw li {
          background: #fff;
        }
      }
      .title {
        font-weight: 600;
        margin: 0;
      }
      .objects-rw {
        margin-top: 10px;
        border-radius: 4px;
        ul {
          padding: 0;
          li {
            list-style: none;
            padding: 10px 0;
            background: #f1f1f1;
            padding: 10px 15px;
            .col-actions {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
            }
            .form-group {
              margin-bottom: 0;
              margin-right: 10px;
              button {
                font-weight: 600;
                font-size: 15px;
                .remove,
                .delete {
                  color: #fff;
                  font-size: 13px;
                }
                svg {
                  font-size: 14px;
                }
              }
            }
          }
          li.dragging {
            background: #eff9ff;
            border: 2px dashed #ddd;
            border-radius: 4px;
          }
          .vue-dropzone {
            background: #fff;
            border: 2px dashed #ddd;
            min-height: 25px;
          }
          .dropzone {
            .dz-message {
              margin: 0;
              padding: 0;
              .dropzone-custom-title {
                font-size: 15px;
                color: #abb2b9;
              }
            }
            .dz-preview {
              width: 60px;
              height: 60px;
              min-height: 0;
              margin: 0 10px 0 0;
              .dz-image img {
                width: 100%;
                height: 100%;
              }
              .dz-error-mark {
                top: 4%;
              }
            }
          }
          #customdropzone .dz-success-mark,
          .dz-error-mark,
          .dz-remove {
            display: none;
          }
          .files {
            background: #fff;
            padding: 10px;
            border: 1px dashed #ddd;
            .figure {
              width: 40px;
              height: 40px;
              margin-right: 5px;
              margin-bottom: 5px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
    .object:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
    .object.dragging {
      background: #eff9ff;
      border: 2px dashed #ddd;
      border-radius: 4px;
      .title {
        color: #afafaf;
      }
      .objects-rw li {
        background: #fff;
      }
      .objects-rw li.dragging {
        background: #eff9ff;
      }
    }
  }
  .buildingTypes {
    .figure {
      width: 90px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (min-width: 19912px) {
    .objects-rw {
      .form-row {
        .form-1 {
          display: inline;
        }
      }
    }
  }
}
</style>
<template>
  <div class="economy w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-2">
            <input type="date" v-model="date" class="form-control"/>
          <input
            type="number"
            ref="am"
            placeholder="OCR"
            v-model="refnr"
            @keyup="checkOcr()"
            class="form-control"
            :class="{
              red: wrongOcr && refnr != null && refnr.length > 0,
              green: !wrongOcr && refnr != null && refnr.length > 0,
              white: refnr == null || refnr.length == 0,
            }"
          />
          <input
            type="number"
            placeholder="Belopp"
            v-model="amount"
            class="form-control"
            @keydown.13="checkout"
            @keyup="checkAmount()"
            :class="{
              red: wrongAmount && amount != null && amount.length > 0,
              green: !wrongAmount && amount != null && amount.length > 0,
              white: amount == null || amount.length == 0,
            }"
          />
          <b-button @click="save()"> {{ buttonText }} </b-button>
        </div>
        <div class="col-md-10" style="margin-top: 52px;">
          <p>
            Kund:
            {{
              searching
                ? "Söker..."
                : customer == null
                ? "Ange giltigt OCR-nummer."
                : customer.category == "pp"
                ? customer.firstName + " " + customer.lastName
                : customer.companyName
            }}{{ customer == null ? '' : ', ' + customer.street + ', ' + customer.postcode + ' ' + customer.city + '. (Kundnummer: ' + customer.customerNr + ')'}}
          </p>
        </div>
        <div class="row" style="margin-top: 24px">
          <div class="col-md-12">
            <h3>Senaste inbetalningarna</h3>
            <table class="table" v-if="payments != null && payments.length > 0">
              <thead>
                <tr>
                  <th>Försäkringsnr</th>
                  <th>Kund</th>
                  <th>Inbetalt</th>
                  <th>Saldo</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, index) in payments" :key="index">
                  <td>{{ p.insuranceNr }}</td>
                  <td>
                    {{
                      p.category == "pp"
                        ? p.firstName + " " + p.lastName
                        : p.companyName
                    }}
                  </td>
                  <td style="text-align: right">
                    {{ p.amount.toLocaleString("sv") }}
                  </td>
                  <td style="text-align: right">{{ p.balance }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";

var moment = require("moment");
moment.locale("sv");

export default {
  name: "economy-payment",
  components: {
    Navbar,
  },
  computed: {
    ...mapState(["hidden", "baseUrl", "apiKey", "accesslevel"]),
  },

  data() {
    return {
      customer: null,
      amount: null,
      wrongAmount: true,
      wrongOcr: true,
      refnr: "",
      date: moment().format('YYYYY-MM-DD'),
      payments: [],
      searching: false,
      buttonText: "Spara",
    };
  },

  mounted() {
    this.setFocus();
    this.getPayments();
  },

  methods: {
    setFocus() {
      this.$refs.am.focus();
    },
    getPayments() {
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=latestPayments" +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.payments = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInsurance(insuranceNr) {
      this.searching = true;
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=customer&insuranceID=" +
            insuranceNr +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.customer = response.data.data;
            this.searching = false;
          } else {
            this.wrongOcr = true;
          }
        })
        .catch((error) => {
          this.searching = false;
          this.wrongOcr = true;
          console.log(error);
        });
    },
    checkOcr() {
      var n = this.refnr;
      this.customer = null;
      this.wrongOcr = true;
      if (n.length > 2) {
        var lenDigit = n.substring(n.length - 2, n.length - 1);
        if (lenDigit == n.length) {
          var checkDigit = n.substring(n.length - 1, n.length);
          if (this.checksum(n.substring(0, n.length - 1)) == checkDigit) {
            this.getInsurance(n.substring(0, n.length - 2));
            this.wrongOcr = false;
          }
        }
      }
    },
    save() {
      this.buttonText = "Sparar...";
      window.axios
        .get(
          this.baseUrl +
            "api/edit.php?type=pay&insuranceID=" +
            this.customer.insuranceID +
            "&amount=" +
            this.amount +
            "&date=" +
            this.date +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.buttonText = "Spara";
            this.amount = null;
            this.customer = null;
            this.refnr = "";
            this.getPayments();
            this.setFocus();
          } else {
            this.buttonText = "Spara";
          }
        })
        .catch((error) => {
          this.searching = false;
          this.wrongOcr = true;
          console.log(error);
        });
    },
    checkout() {
      if (!this.wrongAmount) {
        this.save();
      }
    },
    checkAmount() {
      if (parseInt(this.amount) + this.customer.balance == 0) {
        this.wrongAmount = false;
      } else {
        this.wrongAmount = true;
      }
    },
    checksum(n) {
      var sum = 0,
        p;
      for (var i = 0; i < n.length; i++) {
        if (i % 2 == 1) {
          p = n.substring(i, i + 1);
        } else {
          p = parseInt(n.substring(i, i + 1) * 2);
          if (p > 9) {
            p = this.digitSum(p);
          }
        }
        sum += parseInt(p);
      }
      return (10 - (sum % 10)) % 10;
    },
    digitSum(n) {
      var sum = 0;
      n = n.toString();
      for (var i = 0; i < n.length; i++) {
        var p = parseInt(n.substring(i, i + 1));
        sum += p;
      }
      return sum;
    },
  },
};
</script>

<style scoped>
.red {
  background-color: #ffaaaa;
}
.green {
  background-color: lightgreen;
}
.white {
  background-color: white;
}
input {
  margin-bottom: 8px;
}
</style>
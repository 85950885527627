<template>
  <div class="edit-damage w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-7">
          <h4>Redigera skadehantering</h4>
          <div
            class="alert alert-success mt-3"
            role="alert"
            v-if="submitStatus == 'OK' && successMessage != null"
          >{{ successMessage }}</div>
          <div class="form-group">
            <label for="insuranceNr">Försäkringsnummer</label>
            <input
              type="text"
              class="form-control form-control-lg"
              id="insuranceNr"
              placeholder="Försäkringsnummer"
              v-model="insuranceNr"
              readonly
              :class="{ 'is-invalid': submitStatus == 'ERROR' && $v.insuranceNr.$error }"
            />
            <div
              class="invalid-feedback"
              v-if="!$v.insuranceNr.required"
            >Du måste ange försäkringsnummer</div>
          </div>
          <div class="form-group">
            <label for="damageDate">Skadedatum</label>
            <datepicker
              format="yyyy-MM-dd"
              input-class="form-control form-control-lg"
              :bootstrap-styling="true"
              v-model="damageDate"
              :language="sv"
              :disabledDates="disabledDates"
              placeholder="Ange datum"
              :class="{ 'is-invalid': submitStatus == 'ERROR' && $v.damageDate.$error }"
            ></datepicker>
            <div class="invalid-feedback" v-if="!$v.damageDate.required">Välj skadedatum</div>
          </div>
          <div class="form-group">
            <label for="reportDate">Rapporteringsdatum</label>
            <datepicker
              format="yyyy-MM-dd"
              input-class="form-control form-control-lg"
              :bootstrap-styling="true"
              v-model="reportDate"
              :language="sv"
              :disabledDates="disabledDates"
              placeholder="Välj rapporteringsdatum"
            ></datepicker>
          </div>
          <div class="form-group">
            <label for="endDate">Ärendet avslutat</label>
            <datepicker
              format="yyyy-MM-dd"
              input-class="form-control form-control-lg"
              :bootstrap-styling="true"
              v-model="endDate"
              :language="sv"
              :disabledDates="disabledDates"
              placeholder="Ärendet avslutat"
            ></datepicker>
          </div>
          <div class="form-group">
            <label for="prognosis">Prognostiserad kostnad</label>
            <input
              v-model="prognosis"
              type="number"
              class="form-control form-control-lg"
              id="prognosis"
              placeholder="Prognostiserad kostnad"
            />
          </div>
          <div class="form-group">
            <label for="category">Kategori</label>
            <select
              id="category"
              class="form-control form-control-lg"
              v-model="categoryID"
              :class="{ 'is-invalid': submitStatus == 'ERROR' && $v.categoryID.$error }"
            >
              <option :value="null">Välj kategori</option>
              <template v-if="categories.length > 0">
                <option
                  :value="category.categoryID"
                  v-for="(category, index) in categories"
                  :key="index"
                  :selected="category.categoryID == categoryID"
                >{{ category.category }}</option>
              </template>
            </select>
            <div class="invalid-feedback" v-if="!$v.categoryID.required">Du måste välja kategori</div>
          </div>
          <div class="form-group">
            <label for="damageReport">Godkänd skadeanmälan upprättad:</label>
            <br />
            <div style="padding: 0.5 rem 1rem;">
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  v-model="damageReport"
                  class="form-check-input"
                  id="n"
                  value="n"
                />
                <label for="n" class="form-check-label">nej</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  v-model="damageReport"
                  class="form-check-input"
                  id="y"
                  value="y"
                />
                <label for="y" class="form-check-label">ja</label>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-secondary btn-block btn-lg btnGr"
            @click="updateDamage()"
          >Uppdatera</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import { required } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
import moment from "moment";
export default {
  name: "edit-damage",
  data() {
    return {
      sv: sv,
      categories: [],
      damage: null,
      insuranceNr: null,
      damageDate: null,
      reportDate: new Date(),
      endDate: null,
      prognosis: null,
      categoryID: null,
      damageReport: null,
      disabledDates: {
        from: new Date(),
      },
      submitStatus: null,
      successMessage: null,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  validations: {
    insuranceNr: { required },
    damageDate: { required },
    categoryID: { required },
  },

  components: {
    Navbar,
    Datepicker,
  },

  mounted() {
    this.fetchDamageCategories();
    this.fetchDamage();
  },

  methods: {
    fetchDamageCategories() {
      window.axios
        .get(this.baseUrl + "api/getDamageCategories.php?key=" + this.apiKey)
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchDamage() {
      let damageID = this.$route.params.damageID;
      window.axios
        .get(
          this.baseUrl +
            "api/getDamage.php?id=" +
            damageID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.damage = response.data;
            this.insuranceNr = this.damage.damage.insuranceNr;
            this.damageDate = this.damage.damage.damageDate;
            this.reportDate = this.damage.damage.reportDate;
            this.endDate = this.damage.damage.endDate;
            this.prognosis = this.damage.damage.prognosis;
            this.categoryID = this.damage.damage.categoryID;
            this.damageReport =
              this.damage.damage.damageReport == null ? "n" : "y";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateDamage() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        let damageID = this.$route.params.damageID;

        this.submitStatus = "PENDING";

        let damageDate = moment(this.damageDate).format("YYYY-MM-DD");
        let reportDate = moment(this.reportDate).format("YYYY-MM-DD");
        let endDate = moment(this.endDate).format("YYYY-MM-DD");

        var formData = new FormData();
        formData.append("id", damageID);
        formData.append("insuranceNr", this.insuranceNr);
        formData.append("damageDate", damageDate);
        formData.append("categoryID", this.categoryID);
        formData.append("damageReport", this.damageReport);
        formData.append("reportDate", reportDate);
        formData.append("endDate", endDate);
        formData.append("prognosis", this.prognosis);
        window.axios
          .post(this.baseUrl + "api/damage.php?key=" + this.apiKey, formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "OK";
              this.$router.push({
                name: "damage-details",
                params: { damageID: damageID },
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
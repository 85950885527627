<template>
  <div class="navbar-holder">
    <b-navbar>
      <b-navbar-brand style="margin-left: 25px">
        {{ headertext != null ? headertext : "" }}
      </b-navbar-brand>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav> </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto whitelink">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content v-if="realname != null">
              {{ realname }}
              <b-badge v-if="newAlerts > 0 && !isPartner" variant="danger">{{
                newAlerts
              }}</b-badge>
            </template>
            <b-dropdown-item href="#" v-if="!isPartner" @click="settings"
              >Inställningar</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="alerts" v-if="!isPartner">
              Uppföljningar
              <b-badge>{{ totalAlerts }}</b-badge>
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="logout">Logga ut</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      isActive: true,
      newAlerts: 0,
      totalAlerts: 0,
    };
  },

  props: ["headertext"],

  computed: {
    ...mapState(["apiKey", "realname", "baseUrl", "isPartner"]),

    ...mapGetters(["isPartner"]),
  },

  methods: {
    toggle() {
      this.isActive = !this.isActive;
      this.$store.dispatch("hideSidebar", this.isActive);
    },

    settings() {
      this.$router.push({ name: "settings" });
    },

    alerts() {
      this.$router.push({ name: "alerts" });
    },

    logout() {
      if (this.apiKey) {
        var postdata = new FormData();
        postdata.append("key", this.apiKey);

        this.$store.dispatch("logout", postdata).then((res) => {
          if (res.data.status == "ok") {
            console.log("Logout...");
            var result = new Promise((resolve) => {
              this.$store.commit("AUTH_LOGOUT");
              resolve(true);
            });
            if (result) {
              this.$router.push({ name: "login" }).catch((error) => {
                console.log(error);
              });
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.navbar-holder {
  background-color:#5AA196;
  color: white;
  border-bottom: 1px solid #ddd;
  display: flex;
}
.navbar {
  width: 100%;
  color: #707070;
  // padding: 10px 0;
  .navbar-nav {
    .nav-item .nav-link {
      font-family: Helvetica, Arial, sans-serif;
      color: white;
    }
  }
  .dropdown-menu {
    margin-top: 12px;
    min-width: 220px;
    // padding: 0;
    border-radius: 0;
    border-top-color: #e2e2e2;
    background: #fff;
    .dropdown-item {
      padding: 7px 15px;
    }
    .dropdown-item.active {
      background-color: #fff;
      color: #000;
    }
  }
}

.navbar-light .navbar-brand {
  font-size: 18px;
  color: white;
  svg {
    color: #6d6d6d;
  }
}

.nav-logo {
  max-height: 35px;
}
</style>

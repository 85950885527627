<template>
  <div
    class="row divborder divbg align-items-center"
    v-if="damage != null"
    style="
      margin-top: -10px;
      margin-left: 0px;
      margin-bottom: 8px;
      margin-right: 1px;
      padding: 8px;
      border-top: 1px solid #cbcbcb;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    "
  >
    <div class="col-md-3">
      Skadneummer: {{ damage.damageNr }}<br />
      Händelsedatum: {{ damage.damageDate }}
    </div>
    <div class="col-md-3">
      Kategori: {{ damage.category }}<br />
      Avslutat: {{ damage.endDate == null ? "--" : damage.endDate }}
    </div>
    <div class="col-md-3">
      Rapporterat av: {{ damage.realname }}<br />
      Rapportdatum: {{ damage.reportDate }}
    </div>
    <div class="col-md-3">
      Godkänd skadeanmälan: {{ damage.damageReport == "y" ? "Ja" : "Nej"
      }}<br />
      Fastighet: {{ damage.fastighet }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "damage-header",
  props: ["damage"],
  data() {
    return {
      // client: null,
      isOpen: false,
    };
  },
  computed: {
    ...mapState(["hidden", "baseUrl", "apiKey", "accesslevel"]),
  },

  methods: {},
};
</script>

<style lang="scss">
.divborder {
  border-radius: 5px;
  //border: 1px solid #dde3eb;
}
.divbg {
  background: white;
}
.toolbar {
  img {
    margin: 8px;
  }
  button {
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    //border: 1px solid #dde3eb;
    margin: 4px;
    background-color: white;
  }
}
</style>
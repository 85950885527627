<template>
  <div class="insurances w-100 fourth">
    <navbar />
    <div class="content p-4">
      <h5>Försäkringar</h5>
      <div class="divider"></div>
      <table class="table table-striped" v-if="insurances.length > 0">
        <thead>
          <tr>
            <th scope="col">Försäkrings-ID</th>
            <th scope="col">Kund</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(insurance, key) in insurances" :key="key">
            <td>{{ insurance.insuranceID }}</td>
            <td>
              <router-link
                :to="{
                  name: 'insurance-show',
                  params: { insuranceID: insurance.insuranceID },
                  query: { insuranceID: insurance.insuranceID },
                }"
              >
                {{
                  insurance.category == "co"
                    ? insurance.companyName
                    : insurance.firstName + " " + insurance.lastName
                }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
export default {
  name: "insurances",
  data() {
    return {
      insurances: [],
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  components: {
    Navbar,
  },

  mounted() {
    this.fetchInsurances();
  },

  methods: {
    fetchInsurances() {
      window.axios
        .get(this.baseUrl + "api/get.php?type=insuranceList&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.insurances = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.insurances {
  .divider {
    border-bottom: 1px solid #e8e8e8;
    margin: 10px 0 15px;
  }

  .table {
    tr {
      a {
        color: #4c4c4c;
        font-weight: 600;
        font-size: 0.9rem;
        &:hover {
          color: #0056b3;
        }
      }
    }
  }
}
</style>
<template>
  <div class="damage-details w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-5">
          <div class="content" v-if="damage != null">
            <h4>Skada</h4>
            <div class="card insurance mb-5">
              <div class="card-body">
                <table class="table-borderless">
                  <tr>
                    <th>Skadenummer:</th>
                    <td>{{ damage.damage.damageNr }}</td>
                  </tr>
                  <tr>
                    <th>Händelsedatum:</th>
                    <td>{{ damage.damage.damageDate }}</td>
                  </tr>
                  <tr>
                    <th>Kategori:</th>
                    <td>{{ damage.damage.category }}</td>
                  </tr>
                  <tr>
                    <th>Försäkringsnummer:</th>
                    <td>
                      <router-link
                        :to="{
                          name: 'customer-insurance',
                          params: {
                            customerID: damage.customer.id,
                            insuranceNr: damage.damage.insuranceNr,
                          },
                        }"
                        >{{ damage.damage.insuranceNr }}</router-link
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Rapporterat av:</th>
                    <td>{{ damage.damage.realname }}</td>
                  </tr>
                  <tr>
                    <th>Fastighet:</th>
                    <td>{{ damage.insurance.fastighet }}</td>
                  </tr>
                  <tr>
                    <th>Rapporteringsdatum:</th>
                    <td>{{ damage.damage.reportDate }}</td>
                  </tr>
                  <tr>
                    <th>Ärendet avslutat:</th>
                    <td>
                      {{
                        damage.damage.endDate != null
                          ? damage.damage.endDate
                          : "--"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Prognostiserad kostnad:</th>
                    <td>{{ damage.damage.prognosis }}</td>
                  </tr>
                  <tr>
                    <th>Godkänd skadeanmälan:</th>
                    <td>
                      {{ damage.damage.damageReport == "y" ? "Ja" : "Nej" }}
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <td style="text-align: right">
                      <span class="external">
                        <router-link
                          :to="{
                            name: 'edit-damage',
                            params: { damageID: damage.damage.damageID },
                          }"
                        >
                          <font-awesome-icon icon="edit" />
                        </router-link>
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="event">
              <router-link
                :to="{
                  name: 'customer-show',
                  params: { id: damage.customer.id },
                }"
                >{{
                  damage.customer.category == "co"
                    ? damage.customer.companyName
                    : damage.customer.firstName
                }}
                {{
                  damage.customer.category == "co"
                    ? ""
                    : damage.customer.lastName
                }}
                {{
                  damage.customer.category == "co" &&
                  damage.customer.lastName.length
                    ? "/ " +
                      damage.customer.firstName +
                      " " +
                      damage.customer.lastName
                    : ""
                }}</router-link
              >
              <address class="text-muted">
                {{ damage.customer.street }},
                {{ nicePostcode(damage.customer.postcode) }}
                {{ damage.customer.city }}
              </address>
              <span class="text-muted contact-info"
                >{{ nicePhone(damage.customer.mobilePhone)
                }}{{ nicePhone(damage.customer.homePhone) }}, kundnummer
                {{ damage.customer.customerNr }}</span
              >
            </div>
          </div>
          <div class="row docs-holder">
            <div class="col-md-12">
              <div class="header">
                <h4>Filer</h4>
                <button
                  type="button"
                  class="btn btn-secondary"
                  v-if="!dropzone"
                  @click="addImages()"
                >
                  <font-awesome-icon icon="plus-circle" />&nbsp;Ladda upp
                </button>
                <div class="actions" v-if="dropzone">
                  <button
                    type="button"
                    class="btn mr-3 btnSave"
                    :disabled="files.length == 0"
                    @click="saveImages()"
                  >
                    <span v-if="!loading">
                      <font-awesome-icon icon="share-square" />&nbsp;Spara
                    </span>
                    <span v-if="loading">
                      <pulse-loader
                        :loading="loading"
                        size="15px"
                      ></pulse-loader>
                    </span>
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    :disabled="loading"
                    @click="resetDropzone()"
                  >
                    <font-awesome-icon icon="times-circle" />&nbsp;Avbryt
                  </button>
                </div>
              </div>
              <div
                class="alert alert-success"
                role="alert"
                v-if="alertMassage != null && !dropzone"
              >
                {{ alertMassage }}
              </div>
              <pulse-loader
                :loading="loading && !dropzone"
                size="18px"
              ></pulse-loader>
              <div
                class="table-responsive docs"
                v-if="docs.length > 0 && !dropzone"
              >
                <table class="table">
                  <tbody>
                    <tr v-for="(doc, index) in docs" :key="index">
                      <td>
                        <div class="thumbnail">
                          <img
                            :src="getURL(doc.fileID)"
                            class="img-thumbnail"
                            width="60px"
                            v-if="fileType(doc.mimetype) == 'IMAGE'"
                          />
                          <img
                            :src="require('@/assets/pdf.png')"
                            class="img-thumbnail"
                            width="60px"
                            v-if="fileType(doc.mimetype) == 'PDF'"
                          />
                          <a
                            class="overlay text-center"
                            target="_blank"
                            :href="getURL(doc.fileID)"
                          >
                            <font-awesome-icon icon="share-square" />
                          </a>
                        </div>
                      </td>
                      <td>
                        <span v-if="doc.description != 'null'">{{
                          doc.description
                        }}</span>
                        <span v-if="doc.description == 'null'">&nbsp;</span>
                      </td>
                      <td>{{ doc.ts }}</td>
                      <td>
                        <span class="edit" @click="deleteFile(doc.fileID)">
                          <font-awesome-icon icon="trash-alt" />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="alert alert-primary"
                role="alert"
                v-if="docs.length == 0 && !dropzone && !loading"
              >
                Inga filer uppladdade
              </div>
              <div class="image-zone" v-if="dropzone">
                <div class="table-responsive preview" v-if="files.length > 0">
                  <table class="table">
                    <tbody>
                      <tr v-for="(file, index) in files" :key="index">
                        <td>
                          <img
                            :src="createURL(file.file)"
                            class="img-thumbnail"
                            width="60px"
                            v-if="fileType(file.file.type) == 'IMAGE'"
                          />
                          <img
                            :src="require('@/assets/pdf.png')"
                            class="img-thumbnail"
                            width="60px"
                            v-if="fileType(file.file.type) == 'PDF'"
                          />
                        </td>
                        <td>{{ file.name }}</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Ange beskrivning"
                            v-model="file.description"
                          />
                        </td>
                        <td>{{ file.uploadedAt }}</td>
                        <td>
                          <span class="remove" @click="removeImage(index)">
                            <font-awesome-icon icon="times-circle" />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="field imageDropzone">
                  <small>Filformat som stöds är pdf, jpg och png.</small>
                  <div
                    id="dropzone"
                    :class="['dropzone', dragging ? 'dragover' : '']"
                    @drop="handleImageDrop"
                    @dragstart="dragging = true"
                    @dragover="dragging = true"
                    @dragenter="dragging = true"
                    @dragleave="dragging = false"
                    @dragend="dragging = false"
                  >
                    <h2 class="title">Dra och släpp filen här</h2>
                    <p>eller</p>
                    <div class="custom-file mb-3">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="file"
                        @change="onFileChange"
                        required
                      />
                      <label
                        class="custom-file-label"
                        for="file"
                        data-browse="Välj fil"
                        >Välj fil...</label
                      >
                      <div class="invalid-feedback">
                        Example invalid custom file feedback
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="errorMessage != null"
                >
                  {{ errorMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h4>Anteckningar</h4>
          <div class="form-group">
            <textarea
              class="form-control"
              id="notes"
              rows="5"
              placeholder="Skriv anteckning"
              v-model="note"
              :class="{
                'is-invalid': submitStatus == 'ERROR' && $v.note.$error,
              }"
            ></textarea>
            <div class="invalid-feedback" v-if="!$v.note.required">
              Du måste fylla i anteckning
            </div>
          </div>
          <div class="form-group">
            Aktivera meddelande till kund:
            <input
              type="checkbox"
              v-model="enableCustomer"
              @change="custMsg()"
            />
            <select
              class="form-control"
              name="customerMsg"
              id="customerMsg"
              v-model="customerMsg"
              :disabled="!enableCustomer"
            >
              <option :value="null" selected>Skicka inget till kunden</option>
              <option value="mail">Skicka anteckningen som e-mail</option>
              <option value="sms">Skicka anteckningen som sms</option>
            </select>
          </div>
          <div class="form-group">
            <label for="user">Ska följas upp av:</label>
            <select class="form-control" name="user" id="user" v-model="user">
              <option :value="null" selected>Ingen uppföljning</option>
              <template v-if="users.length > 0">
                <option
                  v-for="(user, index) in users"
                  :key="index"
                  :value="user"
                >
                  {{ user.realname }}
                </option>
              </template>
            </select>
          </div>
          <div class="form-group" v-if="user != null">
            <label for="notification" style="margin-right: 16px"
              >Notifiering:</label
            >
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                name="notification"
                v-model="notification"
                value="mail"
                id="notification-mail"
              />
              <label class="form-check-label" for="notification-mail"
                >e-mail</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                class="form-check-input"
                name="notification"
                v-model="notification"
                value="sms"
                id="notification-sms"
              />
              <label class="form-check-label" for="notification-sms"
                >sms och e-mail</label
              >
            </div>
          </div>
          <div class="form-group">
            <button
              type="button"
              class="btn btn-secondary btn-block"
              @click="addNotes()"
            >
              Spara
            </button>
          </div>
          <div
            class="alert alert-danger mt-2"
            role="alert"
            v-if="error != null"
          >
            {{ error }}
          </div>
          <div
            class="alert alert-success"
            role="alert"
            v-if="submitStatus == 'OK' && successMassage != null"
          >
            {{ successMassage }}
          </div>
          <div class="row">
            <div
              class="events"
              v-if="damage != null && damage.notes.length > 0"
            >
              <div
                :class="
                  message.customerMsg == 'sms'
                    ? customerEvent
                    : message.customerMsg == 'mail'
                    ? mailEvent
                    : normalEvent
                "
                v-for="(message, index) in damage.notes"
                :key="index"
              >
                <pre
                  class="event-text text-muted"
                  style="white-space: pre-wrap"
                  >{{ message.note }}</pre
                >
                <p class="author">
                  <span class="date">{{ convertDate(message.ts) }}</span>
                  <span class="separator">-</span>
                  <span class="name">{{ message.realname }}</span>
                  <span class="separator">-</span>
                  <span class="name">
                    <font-awesome-icon
                      icon="flag"
                      :style="{ color: '#E74C3C' }"
                      v-if="message.status != null && message.status == 'open'"
                    />
                    <font-awesome-icon
                      icon="flag"
                      :style="{ color: '#2ECC71' }"
                      v-if="
                        message.status != null && message.status == 'closed'
                      "
                    />
                    {{ message.assignee != null ? message.assignee : "" }}
                  </span>
                  <b-button
                    style="margin-left: 8px"
                    v-if="message.status == 'open'"
                    variant="success"
                    @click="closeAlert(message.alertID)"
                    >Klar</b-button
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <h4>Kostnader</h4>
          <div class="form-group">
            <!-- <label for="cost-category">Välj kategori</label> -->
            <select
              class="form-control"
              id="cost-category"
              v-model="subCategory"
            >
              <option value>Välj kategori</option>
              <option
                v-for="(sc, index) in subCategories"
                :key="index"
                :value="sc.categoryID"
              >
                {{ sc.category }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <!-- <label for="cost">Pris</label> -->
            <input
              type="number"
              min="0"
              @keyup.13="saveCost()"
              class="form-control"
              id="cost"
              v-model="amount"
              placeholder="Ange belopp"
            />
          </div>
          <div class="form-group">
            <datepicker
              format="yyyy-MM-dd"
              input-class="form-control form-control-lg"
              :bootstrap-styling="true"
              v-model="dueDate"
              :language="sv"
              placeholder="Fakturans förfallodatum"
            ></datepicker>
          </div>

          <button
            type="button"
            class="btn btn-secondary btn-block"
            @click="saveCost()"
          >
            Spara
          </button>
          <div class="table-responsive costs mt-4">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Kategori</th>
                  <th scope="col">Kostnad</th>
                  <th scope="col">Andel</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(c, index) in damageCosts" :key="index">
                  <td>{{ c.category }}</td>
                  <td style="text-align: right">
                    {{ c.cost.toLocaleString("sv") }}
                  </td>
                  <td style="text-align: right">
                    {{ Math.round((c.cost / costSum) * 100) }}%
                  </td>
                  <td>
                    <a href="#" @click="deleteCost(c.costID)">x</a>
                  </td>
                </tr>
                <tr>
                  <td style="font-weight: bold">Summa</td>
                  <td style="font-weight: bold; text-align: right">
                    {{ costSum.toLocaleString("sv") }}
                  </td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
            <select v-model="autoSr" class="form-control" @change="updateSr()">
              <option value="0">Manuell självrisk</option>
              <option value="1200">Grundsjälvrisk</option>
              <option value="3000">Högre självrisk</option>
              <option value="10">10% av PBB</option>
              <option value="20">20% av PBB</option>
              <option value="25">25% av PBB</option>
              <option value="50">50% av PBB</option>
              <option value="75">75% av PBB</option>
              <option value="100">Ett PBB</option>
            </select>
            <input
              class="form-control"
              type="number"
              placeholder="Självrisk"
              v-model="manSr"
              @keyup="updatePayback()"
              :disabled="autoSr > 0"
            />
            <p style="margin-top: 16px">
              <b>Ersättning:</b>
              {{ payback.toLocaleString("sv") }}
            </p>
            <button
              type="button"
              :class="btnClass"
              :disabled="btnDisabled"
              @click="saveSr()"
            >
              {{ btnText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
import Navbar from "@/components/Navbar.vue";
import { required } from "vuelidate/lib/validators";
import { parsePostcode, parsePhone } from "@/_helpers/parser";
var moment = require("moment");
moment.locale("sv");

export default {
  name: "damage-details",
  data() {
    return {
      sv: sv,
      damage: null,
      note: null,
      submitStatus: null,
      subCategories: [],
      users: [],
      user: null,
      notification: "mail",
      successMassage: null,
      dropzone: false,
      dragging: false,
      btnDisabled: true,
      btnText: "Sparad",
      subCategory: "",
      normalEvent: "event mb-3",
      customerEvent: "event mb-3 customer-event",
      mailEvent: "event mb-3 mail-event",
      btnClass: "btn btn-secondary btn-block",
      amount: null,
      extensions: ["png", "jpg", "jpeg", "pdf"],
      url: null,
      files: [],
      costSum: 0,
      damageCosts: [],
      errorMessage: null,
      dueDate: null,
      docs: [],
      alertMassage: null,
      loading: false,
      autoSr: 0,
      manSr: null,
      payback: 0,
      enableCustomer: false,
      customerMsg: null,
      error: null,
    };
  },

  validations: {
    note: { required },
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  components: {
    Navbar,
    PulseLoader,
    Datepicker,
  },

  mounted() {
    this.fetchDamageCosts();
    this.fetchDamage();
    this.fetchUsers();
    this.fetchFiles();

    document.addEventListener("drop", function (e) {
      e.preventDefault();
    });
    document.addEventListener("dragover", function (e) {
      e.preventDefault();
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.files != null && this.files.length > 0) {
      const answer = window.confirm(
        "Du har inte sparat filerna. Vill du radera dem?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else if (this.note != null && this.note.length > 0) {
      const answer = window.confirm(
        "Du har inte sparat anteckningen. Vill du radera den?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else if (this.amount != null && this.amount.length > 0) {
      const answer = window.confirm(
        "Du har inte sparat kostnaden. Vill du radera den?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else if (this.btnDisabled == false) {
      const answer = window.confirm(
        "Du har inte sparat självrisken. Vill du radera den?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    updatePayback() {
      if (this.damage == null) {
        return;
      }
      this.payback = this.costSum - this.manSr;
      if (this.damage.damage.srtyp == 0) {
        if (this.damage.damage.srman == this.manSr) {
          this.btnClass = "btn btn-secondary btn-block";
          this.btnDisabled = true;
          this.btnText = "Sparad";
        } else {
          this.btnClass = "btn btn-danger btn-block";
          this.btnDisabled = false;
          this.btnText = "Spara";
        }
      }
    },
    updateSr() {
      var thePbb = this.damage.damage.pbb;
      if (this.autoSr > 0 && this.autoSr <= 100) {
        this.manSr = Math.round((thePbb * this.autoSr) / 100);
      } else if (this.autoSr > 100) {
        this.manSr = this.autoSr;
      }
      this.updatePayback();
      if (this.damage.damage.srtyp != this.autoSr) {
        this.btnClass = "btn btn-danger btn-block";
        this.btnDisabled = false;
        this.btnText = "Spara";
      } else {
        this.btnClass = "btn btn-secondary btn-block";
        this.btnDisabled = true;
        this.btnText = "Sparad";
      }
    },
    fetchDamage() {
      let damageID = this.$route.params.damageID;
      // console.log(damageID)
      window.window.axios
        .get(
          this.baseUrl +
            "api/getDamage.php?id=" +
            damageID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.damage = response.data;
            if (this.damage.damage.srtyp == null) {
              this.damage.damage.srtyp = 0;
            }
            this.autoSr = this.damage.damage.srtyp;
            if (this.autoSr == 0) {
              if (this.damage.damage.srman == null) {
                this.damage.damage.srman = 0;
              }
              this.manSr = this.damage.damage.srman;
            }
            this.updateSr();
            this.fetchSubCategories(this.damage.damage.categoryID);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchSubCategories() {
      window.axios
        .get(this.baseUrl + "api/get.php?type=subCategories&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.subCategories = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteFile(fileID) {
      if (confirm("Är du säker på att du vill ta bort filen?")) {
        window.axios
          .get(
            this.baseUrl +
              "api/edit.php?type=deleteFile&fileID=" +
              fileID +
              "&key=" +
              this.apiKey
          )
          .then(() => {
            this.fetchFiles();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    fetchDamageCosts() {
      let damageID = this.$route.params.damageID;
      window.axios
        .get(
          this.baseUrl +
            "api/getDamageCosts.php?id=" +
            damageID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.damageCosts = response.data.data;
            this.costSum = 0;
            for (var i = 0; i < this.damageCosts.length; i++) {
              this.costSum += this.damageCosts[i].cost;
            }
            this.updatePayback();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    convertDate(date) {
      return moment(date).format("D MMM YYYY, HH:mm");
    },

    fetchUsers() {
      window.axios
        .get(this.baseUrl + "api/get.php?type=users&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.users = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    custMsg(val) {
      if (!val) {
        this.customerMsg = null;
      }
    },

    addNotes() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        this.successMassage = null;
        this.error = null;

        let customer = this.damage.customer;
        if (this.customerMsg == "mail" && !customer.email) {
          this.submitStatus = "ERROR";
          this.error = "Kunden saknar e-mailadress i systemet.";
          return;
        } else if (this.customerMsg == "sms" && !customer.mobilePhone) {
          this.submitStatus = "ERROR";
          this.error = "Kunden saknar mobiltelefonnummer i systemet.";
          return;
        }

        let userID = this.user != null ? parseInt(this.user.userID) : 0;
        if (
          this.customerMsg != null &&
          (this.customerMsg == "mail" || this.customerMsg == "sms")
        ) {
          var result = confirm(
            'Är du säker på att du vill skicka detta meddelande till kunden? "' +
              this.note +
              '"'
          );
          if (!result) {
            return;
          }
        }
        let damageID = this.$route.params.damageID;

        var postdata = new FormData();
        postdata.append("damageID", damageID);
        postdata.append("note", this.note);
        postdata.append("userID", userID);
        postdata.append("id", customer.id);
        postdata.append("notification", this.notification);
        postdata.append("customerMsg", this.customerMsg);
        postdata.append("key", this.apiKey);
        window.axios
          .post(this.baseUrl + "api/saveNote.php", postdata)
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "PENDING";
              this.note = null;
              this.user = null;
              this.customerMsg = null;
              this.enableCustomer = false;
              this.fetchDamage();
            }
          });
      }
    },

    deleteCost(costID) {
      var formData = new FormData();
      formData.append("id", costID);
      window.axios
        .post(this.baseUrl + "api/deleteCost.php?key=" + this.apiKey, formData)
        .then(() => {
          this.fetchDamageCosts();
        });
    },

    saveCost() {
      let damageID = this.$route.params.damageID;

      if (this.amount < 0) {
        this.amount = null;
        return;
      }

      var formData = new FormData();
      formData.append("damageID", damageID);
      formData.append("categoryID", this.subCategory);
      formData.append(
        "dueDate",
        this.dueDate != null ? moment(this.dueDate).format("YYYY-MM-DD") : ""
      );
      formData.append("amount", this.amount);
      window.axios
        .post(this.baseUrl + "api/damageCost.php?key=" + this.apiKey, formData)
        .then((response) => {
          if (response.data.status == "ok") {
            this.amount = null;
            this.fetchDamageCosts();
          }
        });
    },

    saveSr() {
      let damageID = this.$route.params.damageID;
      var formData = new FormData();
      formData.append("id", damageID);
      formData.append("srtyp", this.autoSr);
      if (this.autoSr == 0) {
        formData.append("srman", this.manSr);
      }
      window.axios
        .post(this.baseUrl + "api/sr.php?key=" + this.apiKey, formData)
        .then((response) => {
          if (response.data.status == "ok") {
            this.fetchDamage();
          }
        });
    },

    handleDragOver() {
      this.dragging = true;
    },

    handleImageDrop(e) {
      this.errorMessage = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (this.isImageValidity(files[i])) {
          let fileName = files[i].name.split(".");
          this.files.push({
            file: files[i],
            name: fileName[0],
            description: null,
          });
        } else {
          this.errorMessage = "En eller några filer är inte tillåtna att välja";
        }
      }
    },

    closeAlert(id) {
      const answer = window.confirm("Är du säker på att du vill klarmarkera?");
      if (answer) {
        window.axios
          .get(
            this.baseUrl + "api/closeAlert.php?key=" + this.apiKey + "&id=" + id
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.fetchDamage();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    nicePostcode(pc) {
      return parsePostcode(pc);
    },

    nicePhone(tfn) {
      return parsePhone(tfn);
    },

    onFileChange(e) {
      this.errorMessage = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      if (this.isImageValidity(files[0])) {
        let fileName = files[0].name.split(".");
        this.files.push({
          file: files[0],
          name: fileName[0],
          description: null,
        });
      } else {
        this.errorMessage = "En eller några filer är inte tillåtna att välja";
      }
    },

    isImageValidity(file) {
      const name = file.name.split(".");
      const extension = name[1];
      const check = this.extensions.includes(extension);

      if (check) {
        return true;
      }

      return false;
    },

    fetchFiles() {
      this.loading = true;
      this.docs = [];
      let damageID = this.$route.params.damageID;
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=files&damageID=" +
            damageID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.docs = response.data.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getURL(fileID) {
      return (
        this.baseUrl +
        "api/getFile.php?fileID=" +
        fileID +
        "&key=" +
        this.apiKey
      );
    },

    addImages() {
      this.dropzone = !this.dropzone;
      this.alertMassage = null;
    },

    createURL(file) {
      let url = URL.createObjectURL(file);

      return url;
    },

    resetDropzone() {
      this.dropzone = !this.dropzone;
      this.files = [];
      this.errorMessage = null;
    },

    removeImage(selectedIndex) {
      this.$delete(this.files, selectedIndex);
    },

    fileType(mimetype) {
      if (
        mimetype == "image/png" ||
        mimetype == "image/jpeg" ||
        mimetype == "image/jpg"
      ) {
        return "IMAGE";
      } else if (mimetype == "application/pdf") {
        return "PDF";
      }
    },

    saveImages() {
      this.loading = true;
      let damageID = this.$route.params.damageID;
      this.files.forEach((file, index) => {
        var formData = new FormData();
        formData.append("file", file.file);
        formData.append("description", file.description);
        formData.append("damageID", damageID);
        window.axios
          .post(this.baseUrl + "api/upload.php?key=" + this.apiKey, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.status == "ok") {
              if (index === this.files.length - 1) {
                this.loading = false;
                this.alertMassage = "Filerna uppladdade";
                this.files = [];
                this.dropzone = !this.dropzone;
                this.fetchFiles();
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
};
</script>

<style lang="scss">
.damage-details {
  .damages {
    display: flex;
    flex-direction: row;
    padding: 20px;
    background: #f1f1f1;
    .card {
      flex: 1;
      // max-width: 500px;
    }
    .insurance {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .customer {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    table {
      th {
        font-size: 14px;
        padding-right: 10px;
      }
    }
  }
  .events {
    margin-top: 16px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .customer-event {
    background: #ffff99 !important;
  }
  .mail-event {
    background: #ccccff !important;
  }

  .event {
    padding: 10px 15px;
    background: #f7f7f7;
    border-radius: 5px;
    .event-text {
      font-size: 16px;
    }
    .author {
      font-size: 12px;
      font-weight: 600;
      color: #797979;
      margin: 0;
      span:not(:last-child) {
        padding-right: 8px;
      }
      svg {
        font-size: 14px;
      }
    }
    .separator {
      color: #000;
    }
  }

  .docs-holder {
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #d7d7dd;
      border-bottom: 1px solid #d7d7dd;
      padding: 15px 10px;
      background: #f1f1f1;
      margin: 20px 0;
      h4 {
        margin: 0;
        padding: 0;
      }
    }
    .docs {
      table {
        tr:hover {
          background: #f1f1f1;
          td .overlay {
            display: block;
          }
        }
        td {
          vertical-align: middle;
          .thumbnail {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 60px;
            height: 60px;
            .overlay {
              display: none;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(0, 0, 0, 0.65);
              z-index: 9999;
              color: #fff;
              cursor: pointer;
              border-radius: 4px;
              svg {
                font-size: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
          .edit {
            cursor: pointer;
            &:hover {
              color: #209cee;
            }
          }
        }
        p {
          margin: 0;
          padding: 0;
        }
        img {
          border: 0;
        }
      }
    }
    .image-zone {
      .preview {
        table {
          tr:hover {
            background: #f1f1f1;
          }
          td {
            vertical-align: middle;
          }
          p {
            margin: 0;
            padding: 0;
          }
          img {
            border: 0;
          }
          svg {
            color: #e74c3c;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
      .imageDropzone {
        margin: 15px 0;
      }

      .dropzone {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px dashed #8e8e8e;
        padding: 35px 0;
        .custom-file {
          width: 400px;
        }
        .title {
          text-transform: uppercase;
          color: #6f6f6f;
          font-size: 22px;
        }
        .file {
          align-items: center;
          justify-content: center;
        }
      }
      .dropzone.dragover {
        border-color: #209cee;
        background: #efefef;
      }
    }
  }

  .costs {
    table {
      th {
        font-size: 15px;
      }
    }
  }

  @media (max-width: 1725.98px) {
    .damages {
      flex-direction: column;
      .card {
        width: 100%;
      }
    }
  }
}
</style>
<template>
  <div class="guidelines">
    <div class="row">
      <div class="col-md-4">
        <template v-if="optionalGuidelines.length > 0">
          <ul class="draggable-item">
            <li
              v-for="(gl, guidelineKey) in optionalGuidelines"
              :key="guidelineKey"
              @click="(status === 'draft') ? selectGuideline(gl) : null"
            >
              {{ gl.name }}
            </li>
          </ul>
        </template>
      </div>
      <div class="col-md-8">
        <div class="guidelines-ls">
          <ul class="gl" v-if="selectedGuidelines.length > 0">
            <li v-for="(sgl, sglKey) in selectedGuidelines" :key="sglKey">
              <p class="title">{{ sgl.name }}</p>
              <span
                class="remove"
                @click="(status === 'draft') ? removeSelectedGuideline(sgl) : null"
                v-if="sgl.optional == 'y' && sgl.selected == 'y'"
              >
                <font-awesome-icon icon="trash-alt" />
              </span>
            </li>
          </ul>
          <div class="alert alert-secondary" v-else>
            <p>Ingen rådgivning hittades</p>
          </div>
          <div class="form-group mt-3">
            <input
              :disabled="status !== 'draft'"
              type="text"
              class="form-control"
              placeholder="Lägg till rådgivning"
              v-model="guideline"
              @keyup.enter="addGuideline($event)"
            />
          </div>
          <button
            type="button"
            class="btn btn-secondary btn-block btnGr" style="font-size:large;"
            :disabled="guideline == null"
            @click="(status === 'draft') ? addGuideline($event) : null"
          >
            <font-awesome-icon icon="plus-circle" />&nbsp;Lägg till rådgivning
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "guideline",
  props: ["insuranceid", "status"],
  data() {
    return {
      dragging: false,
      guideline: null,
    };
  },

  computed: {
    ...mapState([
      "baseUrl",
      "apiKey",
      "selectedGuidelines",
      "optionalGuidelines"
    ]),
  },

  methods: {
    selectGuideline(guideline) {
      const guidelineID = guideline.guidelineID;
      window.axios
        .get(
          this.baseUrl +
            "api/draft.php?type=addGuideline&guidelineID=" +
            guidelineID +
            "&insuranceID=" +
            this.insuranceid +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.$store.dispatch("fetchInsuranceGuidelines", this.insuranceid);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    addGuideline() {
      var formData = new FormData();
      formData.append("guideline", this.guideline);
      window.axios
        .post(
          this.baseUrl +
            "api/draft.php?type=addExtraGuideline&insuranceID=" +
            this.insuranceid +
            "&key=" +
            this.apiKey,
          formData
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.$store.commit("FILTER_GUIDELINES");
            this.$store.dispatch("fetchExtraGuidelines", this.insuranceid);
            this.guideline = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    removeSelectedGuideline(guideline) {
        const guidelineID = guideline.guidelineID;
        const extraGuidelineID = guideline.extraGuidelineID;

        if (guidelineID) {
            this.deleteGuideline(guidelineID)
        } else if (extraGuidelineID) {
            this.deleteExtraGuideline(extraGuidelineID)
        }
    },

    deleteGuideline(guidelineID) {
      window.axios
        .get(
          this.baseUrl +
            "api/draft.php?type=deleteGuideline&guidelineID=" +
            guidelineID +
            "&insuranceID=" +
            this.insuranceid +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.$store.dispatch("fetchInsuranceGuidelines", this.insuranceid);
            this.$store.dispatch("fetchExtraGuidelines", this.insuranceid);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteExtraGuideline(extraGuidelineID) {
      window.axios
        .get(
          this.baseUrl +
            "api/draft.php?type=deleteExtraGuideline&extraGuidelineID=" +
            extraGuidelineID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.$store.commit("FILTER_GUIDELINES");
            this.$store.dispatch("fetchExtraGuidelines", this.insuranceid);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.guidelines {
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      font-size: 15px;
      font-weight: 600;
      background: #f7f7f7;
      list-style: none;
      padding: 20px 25px;
    }
    li:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }
  .draggable-item {
    li:hover {
      border: 2px dashed #f7dc6f;
      cursor: pointer;
      background: #fef9e7;
      padding: 18px 23px;
    }
  }
  .guidelines-ls {
    .gl-dropzone {
      border: 2px dashed #8e8e8e;
      padding: 20px 0;
      background: #f1f1f1;
      text-align: center;
      border-radius: 4px;
      margin-bottom: 15px;
      h3 {
        font-size: 15px;
        font-weight: 600;
        color: #afafaf;
        margin: 0;
        text-transform: uppercase;
      }
    }
    .gl-dropzone.dragging {
      background: #eff9ff;
    }
    .gl {
      li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &:hover {
          background: #f1f1f1;
        }
        .title {
          font-size: 15px;
          margin: 0;
        }
        .remove {
          margin: 0;
          font-size: 13px;
          color: #e74c3c;
          cursor: pointer;
          &:hover {
            color: #a11708;
          }
        }
      }
    }
    .alert p {
      margin: 0;
    }
  }
}
</style>
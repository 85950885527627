<template>
  <div class="backup">
    <ul>
      <li>
        <a target="_blank" :href="baseUrl + 'api/getBackup.php?key=' + apiKey">Anteckningar</a>
      </li>
      <li>
        <a target="_blank" :href="baseUrl + 'api/getBackupInsurance.php?key=' + apiKey"
          >Försäkringar</a
        >
      </li>
      <li>
        <a target="_blank" :href="baseUrl + 'api/getBackupCustomers.php?key=' + apiKey"
          >Kunder</a
        >
      </li>
      <li>
        <a target="_blank" :href="baseUrl + 'api/getBackupDamages.php?key=' + apiKey">Skador</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "backup",
  computed: {
    ...mapState([
      "hidden",
      "authenticated",
      "baseUrl",
      "apiKey",
      "accesslevel",
    ]),
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.auth() && !_vm.isPartner)?_c('nav',{class:{ hidden: _vm.hidden },attrs:{"id":"sidebar"}},[_c('div',{staticClass:"side-header"},[_c('b-navbar-brand',{attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"nav-logo",staticStyle:{"margin-bottom":"3px"},attrs:{"src":require("@/assets/f-logo.svg"),"alt":"","width":"25"}})]),_c('h5',[_vm._v("Fourth 2.1.12")])],1),_c('ul',{staticClass:"list-unstyled"},[(_vm.menus.length > 0)?_vm._l((_vm.menus),function(menu,index){return _c('li',{key:index,class:{ active: menu.collapsed }},[(
            (menu.name == 'users' ||
              menu.name == 'system' ||
              menu.name == 'backup' ||
              menu.name == 'article-admin') &&
            _vm.isAdmin
          )?[_c('router-link',{staticClass:"menu",attrs:{"to":{ name: menu.name }},nativeOn:{"click":function($event){return _vm.activeBar()}}},[_c('span',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":menu.icon}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu.title))]),(menu.badge != null)?_c('span',[_vm._v(" "),_c('b-badge',[_vm._v(_vm._s(_vm.totalAlerts))])],1):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(menu.children.length > 0 && menu.collapsed),expression:"menu.children.length > 0 && menu.collapsed"}],staticClass:"caret-up"},[_c('font-awesome-icon',{style:({ color: 'white' }),attrs:{"icon":"caret-up"}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(menu.children.length > 0 && !menu.collapsed),expression:"menu.children.length > 0 && !menu.collapsed"}],staticClass:"caret-down"},[_c('font-awesome-icon',{attrs:{"icon":"caret-down"}})],1)]),_c('ul',{staticClass:"list-unstyled",attrs:{"id":"pageSubmenu"}},_vm._l((menu.children),function(child,key){return _c('li',{key:key},[_c('router-link',{attrs:{"to":{ name: child.name, params: { userId: 123 } }}},[_vm._v(_vm._s(child.title))])],1)}),0)]:_vm._e(),(
            menu.name != 'users' &&
            menu.name != 'system' &&
            menu.name != 'backup' &&
            menu.name != 'article-admin'
          )?[_c('router-link',{staticClass:"menu",attrs:{"to":{ name: menu.name }},nativeOn:{"click":function($event){return _vm.activeBar()}}},[_c('span',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":menu.icon}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(menu.title))]),(menu.badge != null)?_c('span',[_vm._v(" "),_c('b-badge',[_vm._v(_vm._s(_vm.totalAlerts))])],1):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(menu.children.length > 0 && menu.collapsed),expression:"menu.children.length > 0 && menu.collapsed"}],staticClass:"caret-up"},[_c('font-awesome-icon',{style:({ color: 'white' }),attrs:{"icon":"caret-up"}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(menu.children.length > 0 && !menu.collapsed),expression:"menu.children.length > 0 && !menu.collapsed"}],staticClass:"caret-down"},[_c('font-awesome-icon',{attrs:{"icon":"caret-down"}})],1)]),_c('ul',{staticClass:"list-unstyled",attrs:{"id":"pageSubmenu"}},_vm._l((menu.children),function(child,key){return _c('li',{key:key},[_c('router-link',{attrs:{"to":{ name: child.name, params: { userId: 123 } }}},[_vm._v(_vm._s(child.title))])],1)}),0)]:_vm._e()],2)}):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="customers w100">
        <navbar />
        <div class="content">
            <h3>Kunder</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta eaque, quas aut repellat doloremque, voluptatum quaerat eius libero vero est placeat. Quasi quidem impedit quia modi atque repellendus cum error!</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam saepe officiis id iste, doloremque debitis veniam laudantium totam exercitationem atque, aspernatur non illo quasi possimus eius mollitia vel illum porro.</p>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
export default {
    name: 'cutomers',
    components: {
        Navbar
    }
}
</script>


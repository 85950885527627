<template>
  <div class="profile insuranceList" v-if="insurance.length > 0">
    <div class="table-responsive content whitebox">
      <table class="table">
        <thead>
          <tr>
            <th style="border-top: none">Försäkring</th>
            <th style="border-top: none">Saldo</th>
            <th style="border-top: none">Fastighet</th>
            <th style="border-top: none">&nbsp;</th>
            <th style="border-top: none">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ins, index) in insurance" :key="index">
            <td>
              <a
                href="#"
                @click="showInsurance(ins.insuranceID, ins.insuranceNr)"
              >
                {{
                  ins.insuranceNr > 0
                    ? ins.insuranceNr
                    : "(" + ins.insuranceID + ")"
                }}</a
              >
            </td>
            <td>{{ ins.balance.toLocaleString("sv") }}</td>
            <td>{{ ins.property }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "insurance-list",
  props: ["customer", "insurance"],
  data() {
    return {
      message: null,
      submitStatus: null,
      user: null,
      error: null,
    };
  },
  computed: {
    ...mapState(["hidden", "baseUrl", "apiKey", "accesslevel"]),
  },
  validations: {
    message: { required },
  },

  methods: {
    showInsurance(insuranceID, insuranceNr) {
      this.$emit("update", [insuranceID, insuranceNr]);
    },
  },
};
</script>

<style lang="scss">
a {
  color: blue;
}
a:hover {
  text-decoration: underline;
}

.insuranceList {
  button {
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #dde3eb;
    margin: 4px;
    background-color: white;
  }
}
</style>
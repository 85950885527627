var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"changed-objects w-100"},[_c('navbar'),_c('div',{staticClass:"content p-4"},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer),expression:"customer"}],staticClass:"form-control form-control-lg",attrs:{"type":"text","placeholder":"Kund"},domProps:{"value":(_vm.customer)},on:{"keyup":_vm.onChange,"input":function($event){if($event.target.composing){ return; }_vm.customer=$event.target.value}}})]),_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user),expression:"user"}],staticClass:"form-control form-control-lg",attrs:{"type":"text","placeholder":"Ändrat av"},domProps:{"value":(_vm.user)},on:{"keyup":_vm.onChange,"input":function($event){if($event.target.composing){ return; }_vm.user=$event.target.value}}})]),_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueType),expression:"valueType"}],staticClass:"form-control form-control-lg",attrs:{"type":"text","placeholder":"Fält"},domProps:{"value":(_vm.valueType)},on:{"keyup":_vm.onChange,"input":function($event){if($event.target.composing){ return; }_vm.valueType=$event.target.value}}})]),_c('div',{staticClass:"col"},[_c('b-form-datepicker',{staticClass:"mb-2 form-control-lg",attrs:{"start-weekday":1,"date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              },"locale":"sv","date-disabled-fn":_vm.dateDisabled},on:{"input":_vm.onChange},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.Objects.length > 0)?_c('table',{staticClass:"table table-striped"},[_vm._m(0),_c('tbody',_vm._l((_vm.Objects),function(object,objectKey){return _c('tr',{key:objectKey},[_c('td',[_vm._v(_vm._s(object.ts))]),_c('td',[_c('router-link',{attrs:{"to":{
                    name: 'customer-show',
                    params: { id: object.id },
                  }}},[_vm._v(" "+_vm._s(object.companyName ? object.companyName : object.firstName + " " + object.lastName)+" ")])],1),_c('td',[_vm._v(_vm._s(object.realname))]),_c('td',[_vm._v(_vm._s(object.name))]),_c('td',[_vm._v(_vm._s(object.title))]),_c('td',[_vm._v(_vm._s(object.valueType))]),_c('td',[_vm._v(_vm._s(object.oldValue))]),_c('td',[_vm._v(_vm._s(object.newValue))])])}),0)]):_c('div',{staticClass:"alert alert-secondary",attrs:{"role":"alert"}},[_c('p',{staticClass:"m-0"},[_vm._v("Inga ändringar hitaddes")])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Tidpunkt")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Kund")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Ändrat av")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Produkt")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Objekt")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Fält")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Från")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Till")])])])}]

export { render, staticRenderFns }
<template>
  <div class="auth">
    <div class="brand text-center mb-2">
      <img src="../assets/f-logo.svg" style="padding-bottom: 32px;" alt="Fouth" width="70px" />
    </div>
    <div class="text-center mb-4">
      <h3 style="color: white;">Logga in</h3>
    </div>
    <form class="auth-form" action="#" onsubmit="return false;">
      <div
        class="alert"
        :class="{
          'alert-info': submitStatus == 'INFO',
          'alert-danger': submitStatus == 'ERROR',
        }"
        role="alert"
        v-if="loginAlert != null"
      >
        {{ loginAlert }}
      </div>
      <div
        class="form-group"
        v-if="loginStatus !== 'sms' && loginStatus !== 'pass'"
      >
        <input
          type="text"
          class="form-control form-control-lg"
          id="username"
          ref="username"
          autocomplete="off"
          placeholder="Användarnamn"
          name="username"
          @keydown.9="loginStatus == null ? auth() : passAuth()"
          @keydown.13="loginStatus == null ? auth() : passAuth()"
          v-model="email"
          :class="{ 'is-invalid': submitStatus == 'ERROR' && $v.email.$error }"
        />
        <div class="invalid-feedback" v-if="!$v.email.required">
          Du måste ange e-postadress
        </div>
        <div class="invalid-feedback" v-if="!$v.email.email">
          Ange giltig e-postadress
        </div>
      </div>
      <div class="form-group">
        <button
          type="button"
          class="btn btn-secondary btn-lg btn-block"
          v-if="loginStatus == null"
          @click="auth()"
        >
          Skicka
        </button>
      </div>
      <div class="form-group">
        <input
          v-if="loginStatus == 'pass' || loginStatus == 'sms'"
          placeholder="Lösenord"
          type="password"
          class="form-control form-control-lg"
          id="password"
          ref="password"
          name="password"
          v-model="password"
          @keydown.13="
            loginStatus == null
              ? auth()
              : loginStatus == 'sms'
              ? this.$refs.sms.focus()
              : passAuth()
          "
          :class="{ 'is-invalid': submitStatus == 'ERROR' && passError }"
        />
        <div class="invalid-feedback" v-if="passError">
          Du måste ange lösenord
        </div>
        <input
          placeholder="SMS-kod"
          v-if="loginStatus == 'sms'"
          type="number"
          class="form-control form-control-lg"
          id="sms"
          name="sms"
          @keydown.13="passAuth()"
          v-model="sms"
        />
      </div>
      <button
        type="button"
        class="btn btn-secondary btn-lg btn-block"
        v-if="(loginStatus == 'sms' || loginStatus == 'pass') && !loading"
        @click="passAuth()"
      >
        Logga in
      </button>
      <button
        type="button"
        class="btn btn-secondary btn-lg btn-block"
        v-if="loading"
      >
        <pulse-loader :loading="loading"></pulse-loader>
      </button>
      <!-- <p class="errors text-center" style="color: red;" v-show="error != null">{{ error }}</p> -->
      <div class="alert alert-danger mt-2" role="alert" v-show="error != null">
        {{ error }}
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  name: "login",
  data() {
    return {
      email: null,
      password: null,
      sms: null,
      submitStatus: null,
      loginStatus: null,
      loginAlert: null,
      passError: false,
      loading: false,
    };
  },

  validations: {
    email: { required, email },
    // password: { required }
  },

  computed: {
    ...mapState(["baseUrl", "error"]),
  },

  components: {
    PulseLoader,
  },

  mounted() {
    this.$refs.username.focus();
  },

  methods: {
    login() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        var postdata = new FormData();
        postdata.append("user", this.email);
        postdata.append("pass", this.password);
        this.$store.dispatch("login", postdata).then((res) => {
          if (res.data.status == "ok") {
            this.email = null;
            this.password = null;
            if (
              res.data.accesslevel == "user" ||
              res.data.accesslevel == "office" ||
              res.data.accesslevel == "admin"
            ) {
              this.$router.push({ name: "customers" });
            } else {
              this.$router.push({ name: "partners" });
            }
          }
        });
      }
    },

    auth() {
      this.$v.email.$touch();
      if (this.$v.email.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        var formData = new FormData();
        formData.append("user", this.email);
        window.axios
          .post(this.baseUrl + "api/login.php", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.loginStatus = "pass";
              this.loginAlert = "Ange lösenord för att logga in!";
              this.submitStatus = "INFO";
            } else if (response.data.status == "sms") {
              this.loginStatus = response.data.status;
              this.loginAlert = "Ange lösenord och SMS-kod för att logga in!";
              this.submitStatus = "INFO";
            }
            setTimeout(() => {
              this.$refs.password.focus();
            }, 300);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    passAuth() {
      this.passError = false;
      if (this.password == null) {
        this.passError == true;
        this.submitStatus = "ERROR";
        this.loginAlert = "Du måste ange lösenord";
      } else {
        this.loading = true;
        this.submitStatus = "PENDING";
        var formData = new FormData();
        formData.append("user", this.email);
        formData.append("pass", this.password);
        formData.append("key", this.sms);
        window.axios
          .post(this.baseUrl + "api/login.php", formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$store.commit("AUTH_LOGIN", response.data);
              this.$store.commit("SET_AUTHENTICATION", true);
              this.$store.commit("ERRORS", null);
              this.email = null;
              this.password = null;
              setTimeout(() => {
                this.loading = false;
                if (
                  response.data.accesslevel == "user" ||
                  response.data.accesslevel == "office" ||
                  response.data.accesslevel == "admin"
                ) {
                  this.$router.push({ name: "customers" }).catch(() => {});
                } else {
                  this.$router.push({ name: "partners" }).catch(() => {});
                }
              }, 1000);
            } else {
              this.loading = false;
              this.loginStatus = null;
              this.password = null;
              this.sms = null;
              this.email = null;
              this.loginAlert = "Inloggning misslyckades.";
              this.submitStatus = "ERROR";
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 100 000 * 100
.auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 450px;
  height: 500px;
  background: #5aa196;
  margin-top: 10rem;
  padding: 0 30px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  // box-shadow: 0 0 0 1px rgba(20, 20, 31, 0.05),
  //   0 1px 3px 0 rgba(20, 20, 31, 0.15);
  .auth h3 {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 23px;
  }
  .invalid-feedback {
    background: white;
    padding: 16px;
  }
  .auth-form {
    input::placeholder {
      font-size: 1rem;
    }
    .errors {
      margin-top: 20px;
      padding: 10px;
      border: 1px solid red;
      border-radius: 4px;
      font-size: 14px;
    }
  }
}
</style>
<template>
  <div class="customer-show w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-12 mb-4">
          <h2 class="title">Aktuellt</h2>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Beskrivning</th>
                <th scope="col">Uppladdad</th>
                <th scope="col">Av</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(message, index) in articles" :key="index">
                <td>
                  <a target="_blank" :href="getURL(message.fileID)">{{
                    message.description
                  }}</a>
                </td>
                <td>{{ message.ts }}</td>
                <td>{{ message.realname }}</td>
                <!-- <td>
                  <router-link
                    :to="{ name: 'customer-show', params: { id: message.id } }"
                    >{{
                      message.category == "co"
                        ? message.companyName
                        : message.firstName
                    }}
                    {{
                      message.category == "co" ? "" : message.lastName
                    }}</router-link
                  >
                </td>
                <td>{{ message.note }}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

var moment = require("moment");
moment.locale("sv");

export default {
  name: "news",
  data() {
    return {
      loading: false,
      error: null,
      articles: [],
    };
  },

  components: {
    Navbar,
    PulseLoader,
  },

  computed: {
    ...mapState(["hidden", "baseUrl", "apiKey"]),
  },

  mounted() {
    this.fetchAlerts();
  },

  methods: {
    fetchAlerts() {
      this.loading = true;
      window.axios
        .get(this.baseUrl + "api/get.php?type=articles&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.articles = response.data.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    getURL(fileID) {
      return (
        this.baseUrl +
        "api/getFile.php?fileID=" +
        fileID +
        "&key=" +
        this.apiKey
      );
    },

    convertDate(date) {
      return date.format("D MMM YYYY, HH:mm");
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-show {
  .title {
    font-size: 20px;
    font-weight: 600;
  }
  .alert p {
    margin-bottom: 0;
  }
  .table {
    th {
      font-size: 15px;
    }
    td {
      font-size: 0.96rem;
      a {
        color: #4c4c4c;
        font-weight: 600;
      }
    }
    tr:hover {
      td a {
        color: #3498db;
      }
    }
  }
}
</style>

<template>
  <div class="insurance-lists w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-6">
          <h5>
            <router-link
              :to="{
                name: 'customer-show',
                params: { id: customer == null ? 0 : customer.id },
              }"
              >{{
                customer == null
                  ? ""
                  : customer.category == "pp"
                  ? customer.firstName
                  : customer.companyName
              }}
              {{
                customer == null
                  ? ""
                  : customer.category == "pp"
                  ? customer.lastName
                  : ""
              }}</router-link
            >
          </h5>
        </div>
        <div class="col-md-6 text-right">
          <span style="color: red" v-if="dateDiff">{{ dateDiff }}</span>
          <div class="select-date">
            <div class="form-group">
              <input
                type="date"
                class="form-control"
                v-model="selectedDate"
                @input="refreshInsuranceInfo()"
              />
            </div>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-secondary"
                @click="showLogs = true"
              >
                <font-awesome-icon icon="eye" />&nbsp;Visa historik
              </button>
            </div>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-success"
                @click="addProduct()"
              >
                <font-awesome-icon icon="plus-circle" /> Addera produkt
              </button>
            </div>
          </div>
        </div>
      </div>
      <pulse-loader :loading="loading" class="mt-4 mb-4"></pulse-loader>
      <template v-if="!loading">
        <table
          class="table table-hover"
          v-if="insuranceInfo.length > 0"
          :key="componentKey"
        >
          <thead>
            <tr>
              <th scope="col">Produkt</th>
              <th scope="col">Beteckning</th>
              <th scope="col">Värdering</th>
              <th scope="col">Villkor</th>
              <th scope="col">Riskfaktor</th>
              <th scope="col">Premie</th>
              <th scope="col">Giltig</th>
              <th scope="col">&nbsp;</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="insurance in insuranceInfo">
              <tr :key="insurance.productID">
                <td>
                  <strong>{{ insurance.name }}</strong>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>
                  {{ insurance.startDate }} --
                  {{
                    insurance.endDate == "9999-12-31" ? "" : insurance.endDate
                  }}
                </td>
                <td>&nbsp;</td>
                <td class="actions">
                  <button
                    v-if="insurance.evaluationTypeID == 2 && !insurance.hidden"
                    type="button"
                    class="btn btn-success btn-sm"
                    @click="addObject(insurance)"
                  >
                    <font-awesome-icon icon="plus-circle" />
                  </button>
                  <button
                    v-if="hasSubProducts(insurance) && !insurance.hidden"
                    type="button"
                    class="btn btn-dark btn-sm"
                    @click="addSubProduct(insurance)"
                  >
                    <font-awesome-icon icon="copy" />
                  </button>
                  <button
                    v-if="!insurance.hidden"
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="deleteProduct(insurance)"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </button>
                </td>
              </tr>
              <template v-for="object in insurance.objects">
                <tr :key="object.objectID">
                  <td>
                    <span v-if="!object.editable">{{ object.title }}</span>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.title"
                      v-if="object.editable"
                    />
                  </td>
                  <td>
                    <span v-if="!object.editable">{{ object.property }}</span>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.property"
                      v-if="object.editable"
                    />
                  </td>
                  <td style="text-align: right">
                    <span v-if="!object.editable">{{
                      formatValue(object.objectValue)
                    }}</span>
                    <input
                      type="text"
                      class="form-control"
                      @keyup="fetchPremie(object)"
                      v-model="form.objectValue"
                      v-if="object.editable"
                    />
                  </td>
                  <td>
                    <span v-if="!object.editable">{{
                      getTerm(object.termID)
                    }}</span>
                    <select
                      class="form-control"
                      v-model="form.termID"
                      v-if="object.editable"
                    >
                      <option :value="null" selected disabled>
                        Välj villkor
                      </option>
                      <option
                        v-for="(term, termKey) in terms"
                        :key="termKey"
                        :value="term.termID"
                        :selected="term.termID == form.termID"
                      >
                        {{ term.term }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <span v-if="!object.editable">{{ object.risk }}</span>
                    <input
                      type="number"
                      class="form-control"
                      v-model="form.risk"
                      @keyup="fetchPremie(object)"
                      v-if="object.editable"
                    />
                  </td>
                  <td v-if="!object.editable">
                    {{ object.premie.toLocaleString("sv") }}
                  </td>
                  <td v-if="object.editable">
                    {{ currentEditingPremie.toLocaleString("sv") }}
                  </td>
                  <td>
                    {{ object.startDate }} --
                    {{ object.endDate == "9999-12-31" ? "" : object.endDate }}
                  </td>
                  <td>
                    <input
                      type="date"
                      class="form-control"
                      v-model="form.date"
                      v-if="object.editable"
                    />
                  </td>
                  <td class="actions">
                    <template v-if="!object.hidden">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm"
                        v-if="object.editable"
                        @click="close(object)"
                      >
                        <font-awesome-icon icon="times" class="times" />
                      </button>
                      <button
                        type="button"
                        class="btn btn-success btn-sm"
                        v-if="object.editable"
                        @click="updateObject()"
                      >
                        <font-awesome-icon icon="save" class="success" />
                      </button>
                      <button
                        type="button"
                        class="btn btn-info btn-sm"
                        v-if="!object.editable"
                        @click="editObject(object)"
                      >
                        <font-awesome-icon icon="edit" class="edit" />
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        v-if="!object.editable"
                        @click="removeObject(object)"
                      >
                        <font-awesome-icon icon="trash-alt" class="remove" />
                      </button>
                    </template>
                  </td>
                </tr>
              </template>
              <template v-for="sp in insurance.subProducts">
                <tr :key="sp.iProductID">
                  <td>
                    <strong>{{ sp.name }}</strong>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>
                    {{ sp.startDate }} --
                    {{ sp.endDate == "9999-12-31" ? "" : sp.endDate }}
                  </td>
                  <td>&nbsp;</td>
                  <td class="actions">
                    <button
                      v-if="sp.evaluationTypeID == 2 && !insurance.hidden"
                      type="button"
                      class="btn btn-success btn-sm"
                      @click="addObject(sp)"
                    >
                      <font-awesome-icon icon="plus-circle" />
                    </button>
                    <button
                      v-if="!insurance.hidden"
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="deleteProduct(sp)"
                    >
                      <font-awesome-icon icon="trash-alt" />
                    </button>
                  </td>
                </tr>
                <template v-for="object in sp.objects">
                  <tr :key="object.objectID">
                    <td>
                      <span v-if="!object.editable">{{ object.title }}</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.title"
                        v-if="object.editable"
                      />
                    </td>
                    <td>
                      <span v-if="!object.editable">{{ object.property }}</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.property"
                        v-if="object.editable"
                      />
                    </td>
                    <td style="text-align: right">
                      <span v-if="!object.editable">{{
                        formatValue(object.objectValue)
                      }}</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.objectValue"
                        @keyup="fetchPremie(object)"
                        v-if="object.editable"
                      />
                    </td>
                    <td>
                      <span v-if="!object.editable">{{
                        getTerm(object.termID)
                      }}</span>
                      <select
                        class="form-control"
                        v-model="form.termID"
                        v-if="object.editable"
                      >
                        <option :value="null" selected disabled>
                          Välj villkor {{}}
                        </option>
                        <option
                          v-for="(term, termKey) in terms"
                          :key="termKey"
                          :value="term.termID"
                          :selected="term.termID == form.termID"
                        >
                          {{ term.term }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <span v-if="!object.editable">{{
                        object.objectValue > 999999999 ? "" : object.risk
                      }}</span>
                      <input
                        type="number"
                        class="form-control"
                        @keyup="fetchPremie(object)"
                        v-model="form.risk"
                        v-if="object.editable && sp.unit != null"
                      />
                    </td>
                    <td v-if="!object.editable">
                      {{ object.premie.toLocaleString("sv") }}
                    </td>
                    <td v-if="object.editable">
                      {{ currentEditingPremie.toLocaleString("sv") }}
                    </td>
                    <td>
                      {{ object.startDate }} --
                      {{ object.endDate == "9999-12-31" ? "" : object.endDate }}
                    </td>
                    <td>
                      <input
                        type="date"
                        class="form-control"
                        v-model="form.date"
                        v-if="object.editable"
                      />
                    </td>
                    <td class="actions">
                      <template v-if="!object.hidden">
                        <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          v-if="object.editable"
                          @click="close(object)"
                        >
                          <font-awesome-icon icon="times" class="times" />
                        </button>
                        <button
                          type="button"
                          class="btn btn-success btn-sm"
                          v-if="object.editable"
                          @click="updateObject()"
                        >
                          <font-awesome-icon icon="save" class="success" />
                        </button>
                        <button
                          type="button"
                          class="btn btn-info btn-sm"
                          v-if="!object.editable"
                          @click="editObject(object)"
                        >
                          <font-awesome-icon icon="edit" class="edit" />
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          v-if="!object.editable"
                          @click="removeObject(object)"
                        >
                          <font-awesome-icon icon="trash-alt" class="remove" />
                        </button>
                      </template>
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </template>
      <p>Skapa faktura:</p>
      <div class="row">
        <div class="col-md-2">
          <datepicker
            format="yyyy-MM-dd"
            input-class="form-control"
            :bootstrap-styling="true"
            v-model="startDate"
            :language="sv"
            placeholder="Välj datum"
          ></datepicker>
        </div>
        <div class="col-md-2">
          <datepicker
            format="yyyy-MM-dd"
            input-class="form-control"
            :bootstrap-styling="true"
            v-model="endDate"
            :language="sv"
            placeholder="Välj datum"
          ></datepicker>
        </div>
        <button @click="openPDF()">Skriv ut</button>
      </div>
      <hr />
      <b-container fluid>
        <b-row>
          <h4>Självrisk</h4>
        </b-row>
        <textarea v-model="srText" id="sr" ows="4" cols="32"></textarea>
        <b-row>
          <b-col cols="2">
            <datepicker
              format="yyyy-MM-dd"
              input-class="form-control"
              :bootstrap-styling="true"
              v-model="srDate"
              :language="sv"
              placeholder="Välj datum"
            ></datepicker>
          </b-col>
          <b-col>
            <button id="srSave" @click="saveSr()">Spara</button>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <template>
      <b-modal
        id="modal-1"
        size="xl"
        :title="'Försäkringshistorik'"
        hide-footer
        v-model="showLogs"
      >
        <table class="table table-hover" v-if="insuranceLogs.length > 0">
          <tbody>
            <template v-for="log in insuranceLogs">
              <tr :key="log.productID">
                <td>&nbsp;</td>
                <td>{{ log.name }}</td>
                <td>{{ log.startDate }}</td>
                <td>{{ log.endDate == "9999-12-31" ? "-" : log.endDate }}</td>
                <td>&nbsp;</td>
              </tr>
              <template v-if="log.subProducts.length > 0">
                <tr v-for="sp in log.subProducts" :key="sp.productID">
                  <td>&nbsp;</td>
                  <td>{{ sp.name }}</td>
                  <td>{{ sp.startDate }}</td>
                  <td>{{ sp.endDate == "9999-12-31" ? "-" : sp.endDate }}</td>
                  <td>&nbsp;</td>
                </tr>
              </template>
              <template v-if="log.objects.length > 0">
                <template v-for="obj in log.objects">
                  <tr :key="obj.objectID">
                    <td>&nbsp;</td>
                    <td>Objekt</td>
                    <td>{{ obj.startDate }}</td>
                    <td>
                      {{
                        obj.endDate == "9999-12-31" && obj.endDate == null
                          ? "-"
                          : obj.endDate
                      }}
                    </td>
                    <td>
                      {{
                        obj.premie != null ? "premie " + obj.premie : "&nbsp;"
                      }}
                    </td>
                  </tr>
                  <template v-if="obj.title.length > 0">
                    <tr v-for="title in obj.title" :key="title.id">
                      <td>Titel</td>
                      <td>Titel {{ title.value }}</td>
                      <td>{{ title.startDate }}</td>
                      <td>
                        {{
                          title.endDate == "9999-12-31" ? "-" : title.endDate
                        }}
                      </td>
                      <td>
                        {{
                          title.premie != null
                            ? "premie " + title.premie
                            : "&nbsp;"
                        }}
                      </td>
                    </tr>
                  </template>
                  <template v-if="obj.objectValue.length > 0">
                    <tr v-for="ov in obj.objectValue" :key="ov.id">
                      <td>Värdering</td>
                      <td>{{ ov.value }}</td>
                      <td>{{ ov.startDate }}</td>
                      <td>
                        {{ ov.endDate == "9999-12-31" ? "-" : ov.endDate }}
                      </td>
                      <td>
                        {{
                          ov.premie != null ? "premie " + ov.premie : "&nbsp;"
                        }}
                      </td>
                    </tr>
                  </template>
                  <template v-if="obj.termID.length > 0">
                    <tr v-for="term in obj.termID" :key="term.id">
                      <td>Villkor</td>
                      <td>{{ getTerm(term.value) }}</td>
                      <td>{{ term.startDate }}</td>
                      <td>
                        {{ term.endDate == "9999-12-31" ? "-" : term.endDate }}
                      </td>
                      <td>
                        {{
                          term.premie != null
                            ? "premie " + term.premie
                            : "&nbsp;"
                        }}
                      </td>
                    </tr>
                  </template>
                  <template v-if="obj.risk.length > 0">
                    <tr v-for="risk in obj.risk" :key="risk.id">
                      <td>Riskfaktor</td>
                      <td>{{ risk.value }}</td>
                      <td>{{ risk.startDate }}</td>
                      <td>
                        {{ risk.endDate == "9999-12-31" ? "-" : risk.endDate }}
                      </td>
                      <td>
                        {{
                          risk.premie != null
                            ? "premie " + risk.premie
                            : "&nbsp;"
                        }}
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </b-modal>
    </template>

    <template>
      <b-modal
        id="modal-scrollable"
        size="lg"
        :title="title"
        :hide-footer="true"
        v-model="showModalProduct"
        @hide="reset()"
        scrollable
      >
        <template v-if="addStatus === 'PRODUCT'">
          <div class="form-group">
            <datepicker
              format="yyyy-MM-dd"
              input-class="form-control"
              :bootstrap-styling="true"
              v-model="datum"
              :language="sv"
              placeholder="Välj datum"
              :class="{ 'is-invalid': $v.datum.$error }"
            ></datepicker>
            <div class="invalid-feedback" v-if="!$v.datum.required">
              Du måste välja datum
            </div>
          </div>
          <div class="form-group">
            <select
              class="form-control"
              id="product"
              v-model="product"
              :class="{ 'is-invalid': $v.product.$error }"
            >
              <option :value="null">Välj produkt</option>
              <option
                v-for="(product, productKey) in getProducts"
                :key="productKey"
                :value="product"
              >
                {{ product.name }}
              </option>
            </select>
            <div class="invalid-feedback" v-if="!$v.product.required">
              Du måste välja produkt
            </div>
          </div>
          <button
            type="button"
            class="btn btn-success btn-block btn-lg mb-3 mt-4"
            :disabled="submitStatus === 'PENDING'"
            @click="saveProduct()"
          >
            Spara
          </button>
        </template>
        <template v-if="addStatus === 'SUBPRODUCT'">
          <div class="form-group">
            <datepicker
              format="yyyy-MM-dd"
              input-class="form-control"
              :bootstrap-styling="true"
              v-model="datum"
              :language="sv"
              placeholder="Välj datum"
              :class="{ 'is-invalid': $v.datum.$error }"
            ></datepicker>
            <div class="invalid-feedback" v-if="!$v.datum.required">
              Du måste välja datum
            </div>
          </div>
          <div class="form-group">
            <select
              class="form-control"
              id="product"
              v-model="subproduct"
              :class="{ 'is-invalid': $v.subproduct.$error }"
            >
              <option :value="null">Välj underprodukt</option>
              <option
                v-for="(sp, spKey) in selectedSubProducts"
                :key="spKey"
                :value="sp"
              >
                {{ sp.name }}
              </option>
            </select>
            <div class="invalid-feedback" v-if="!$v.subproduct.required">
              Du måste välja underprodukt
            </div>
          </div>
          <button
            type="button"
            class="btn btn-success btn-block btn-lg mb-3 mt-4"
            :disabled="submitStatus === 'PENDING'"
            @click="saveSubProduct()"
          >
            Spara
          </button>
        </template>
        <template v-if="addStatus === 'OBJECT'">
          <div
            class="object-open"
            v-if="!isEvaluationFormOpen && !isBuildingSelected"
          >
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                v-model="objectName"
                :placeholder="product.unit != null ? 'Ange objektnamn' : 'Namn'"
                :class="{ 'is-invalid': $v.objectName.$error }"
              />
              <div class="invalid-feedback" v-if="!$v.objectName.required">
                Du måste ange namn
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div
                  :class="{
                    'col-md-11': evaluationFormID > 0,
                    'col-md-12': evaluationFormID <= 0,
                  }"
                >
                  <input
                    type="text"
                    class="form-control"
                    v-model="objectValue"
                    :placeholder="
                      product.unit != null ? 'Värde' : 'Personnummer'
                    "
                    :class="{ 'is-invalid': $v.objectValue.$error }"
                  />
                  <div class="invalid-feedback" v-if="!$v.objectValue.required">
                    Du måste ange värde
                  </div>
                </div>
                <div class="col-md-1" v-if="evaluationFormID > 0">
                  <button
                    type="button"
                    class="btn btn-light"
                    @click="openEvaluationForm()"
                  >
                    <font-awesome-icon icon="clipboard" size="lg" />
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="termID">
                <option :value="null" selected disabled>Välj villkor</option>
                <option
                  v-for="(term, termKey) in terms"
                  :key="termKey"
                  :value="term.termID"
                  :selected="term.termID == termID"
                >
                  {{ term.term }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <input
                v-if="product.unit != null"
                type="number"
                class="form-control"
                v-model="riskfactor"
                placeholder="Riskfaktor"
              />
            </div>
            <div class="form-group">
              <datepicker
                format="yyyy-MM-dd"
                input-class="form-control"
                :bootstrap-styling="true"
                v-model="datum"
                :language="sv"
                placeholder="Välj datum"
                :class="{ 'is-invalid': $v.datum.$error }"
              ></datepicker>
              <div class="invalid-feedback" v-if="!$v.datum.required">
                Du måste välja datum
              </div>
            </div>
            <button
              type="button"
              class="btn btn-success btn-block btn-lg mt-4 mb-4"
              :disabled="submitStatus === 'PENDING'"
              @click="saveObject()"
            >
              Spara
            </button>
          </div>
          <template
            v-if="
              buildingType == null &&
              !loading &&
              evaluationFormID == 2 &&
              isBuildingSelected
            "
          >
            <building-types
              :buildings="buildingTypes"
              @select-house="houseTypeSelected"
              @building-selected="selectedHouseType"
            />
          </template>
          <div v-if="isFormAvailable && isEvaluationFormOpen">
            <div
              class="form-group"
              v-for="(ef, efKey) in evaluationForms"
              :key="efKey"
            >
              <label>{{ ef.question }}</label>
              <template v-if="ef.options.length == 0">
                <input
                  type="text"
                  class="form-control"
                  v-model="ef.value"
                  v-if="ef.options.length == 0"
                />
              </template>
              <template v-if="ef.options.length > 0">
                <div
                  class="form-check"
                  v-for="(option, optionKey) in ef.options"
                  :key="optionKey"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    :id="'option' + option.optionID"
                    :name="'option' + ef.formID + efKey"
                    :value="option.points"
                    @change="onOptionChanged(ef, optionKey)"
                  />
                  <label
                    class="form-check-label"
                    :for="'option' + option.optionID"
                    >{{ option.optionName }}</label
                  >
                </div>
              </template>
            </div>
            <div v-if="evaluationFormID == 8">
              <h3>Klasser</h3>
              <ol>
                <li>
                  Isolerade djurstallar exklusive byggnadsinventarier.
                  Verkstadslokaler.
                </li>
                <li>
                  Oisolerade stallar och maskinhallar i bra skick som ska byggas
                  upp i ungefär den storlek och planlösning som fanns före
                  skadan.
                </li>
                <li>
                  Gamla ekonomibyggnader i bra skick men svåra att utnyttja på
                  ett rationellt sätt. Värde sätts enligt rationellt värde, dvs
                  som nuvarande byggnad nyttjas.
                </li>
                <li>
                  Äldre ekonomibyggnader, överskottshus, med sämre underhåll.
                </li>
                <li>Rivningsobjekt.</li>
              </ol>
            </div>

            <button
              :disabled="modalLoading"
              type="button"
              class="btn btn-success btn-block btn-lg mb-2 mt-4"
              @click="saveForms()"
            >
              Spara
            </button>

            <button
              :disabled="modalLoading"
              type="button"
              class="btn btn-secondary btn-block btn-lg mb-4 mt-3"
              @click="closeEvaluationForm()"
            >
              <font-awesome-icon icon="arrow-circle-left" /> Tillbaka
            </button>
          </div>
        </template>
      </b-modal>
    </template>

    <template>
      <b-modal
        id="modal-1"
        size="lg"
        :title="title"
        :hide-footer="true"
        v-model="showModalDelete"
        @hide="reset"
      >
        <div class="form-group">
          <datepicker
            format="yyyy-MM-dd"
            input-class="form-control"
            :bootstrap-styling="true"
            v-model="datum"
            :language="sv"
            placeholder="Välj datum"
            :class="{ 'is-invalid': $v.datum.$error }"
          ></datepicker>
          <div class="invalid-feedback" v-if="!$v.datum.required">
            Du måste välja datum
          </div>
        </div>
        <button
          v-if="deleteStatus === 'PRODUCT'"
          type="button"
          class="btn btn-danger btn-block btn-lg"
          @click="requestDeleteProduct()"
        >
          Radera
        </button>
        <button
          v-if="deleteStatus === 'OBJECT'"
          type="button"
          class="btn btn-danger btn-block btn-lg"
          @click="deleteObject()"
        >
          Radera
        </button>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import houseTypes from "@/assets/building_types.json";
import BuildingTypes from "@/components/BuildingTypes.vue";
export default {
  name: "insurance-show",
  data() {
    return {
      loading: false,
      insuranceID: null,
      object: null,
      form: {
        objectID: null,
        title: null,
        objectValue: null,
        risk: null,
        termID: null,
        premie: null,
        property: null,
      },
      title: null,
      showModal: false,
      componentKey: 0,
      currentEditingPremie: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      showLogs: false,
      insuranceLogs: [],
      showModalProduct: false,
      showModalDelete: false,
      datum: moment().format("YYYY-MM-DD"),
      product: null,
      submitStatus: null,
      deleteStatus: null,
      addStatus: null,
      selectedSubProducts: [],
      subproduct: null,
      sv: sv,
      disabledDates: {
        // to: new Date(Date.now() - 8640000),
        from: new Date(),
      },
      objectName: null,
      objectValue: null,
      riskfactor: 100,
      termID: null,
      isBuildingSelected: false,
      isEvaluationFormOpen: false,
      evaluationFormID: null,
      evaluationForms: [],
      buildingTypes: [],
      buildingType: null,
      totalAreas: 0,
      totalPoints: 0,
      srSaved: null,
      startDate: moment().format("YYYY-MM-DD"),
      srDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY") + "-12-31",
      modalLoading: false,
    };
  },

  validations: {
    datum: { required },
    object: { required },
    product: { required },
    subproduct: { required },
    objectName: { required },
    objectValue: { required },
  },

  computed: {
    ...mapState([
      "baseUrl",
      "apiKey",
      "insuranceInfo",
      "terms",
      "customer",
      "products",
      "dateDiff",
      "standardSr",
      "sr",
      "subproducts",
    ]),
    srText: {
      get() {
        return this.srSaved == null ? this.sr : this.srSaved;
      },
      set(newValue) {
        this.srSaved = newValue;
      },
    },
    getProducts() {
      let insurances = this.insuranceInfo.map((insurance) => {
        return insurance.productID;
      });
      return this.products.filter(
        (product) => !insurances.includes(product.productID)
      );
    },

    isFormAvailable: function () {
      return (
        (this.evaluationForms.length > 0 &&
          this.buildingType != null &&
          !this.loading &&
          this.isEvaluationFormOpen) ||
        this.evaluationFormID != 2
      );
    },
  },

  watch: {
    insuranceLogs: function () {
      this.insuranceLogs.forEach((insurance) => {
        insurance.objects.forEach((obj) => {
          if (!Object.prototype.hasOwnProperty.call(obj, "title")) {
            obj.title = [];
          }
          if (!Object.prototype.hasOwnProperty.call(obj, "objectValue")) {
            obj.objectValue = [];
          }
          if (!Object.prototype.hasOwnProperty.call(obj, "termID")) {
            obj.termID = [];
          }
          if (!Object.prototype.hasOwnProperty.call(obj, "risk")) {
            obj.risk = [];
          }
          obj["title"].forEach(
            (title) => (title.id = Math.random().toString(36).substr(2, 9))
          );
          obj["objectValue"].forEach(
            (ov) => (ov.id = Math.random().toString(36).substr(2, 10))
          );
          obj["termID"].forEach(
            (term) => (term.id = Math.random().toString(36).substr(2, 11))
          );
          obj["risk"].forEach(
            (risk) => (risk.id = Math.random().toString(36).substr(2, 12))
          );
        });
      });
    },
  },

  components: {
    Navbar,
    Datepicker,
    PulseLoader,
    BuildingTypes,
  },

  created() {
    this.$store.dispatch("fetchTerms");
    this.insuranceID = this.$route.query.insuranceID;

    if (this.insuranceID) {
      this.refreshInsuranceInfo();
    }
    this.$store.dispatch("fetchProducts");
    this.$store.dispatch("fetchSubProducts");
  },

  mounted() {
    this.buildingTypes = houseTypes;
  },

  methods: {
    formatValue(value) {
      if (value > 999999999) {
        return (
          value.toString().substring(0, 6) + "-" + value.toString().substring(6)
        );
      } else {
        return value.toLocaleString("sv");
      }
    },
    dateChanged() {
      console.log("Date has been changed!!");
    },
    openPDF() {
      window.open(
        this.baseUrl +
          "print/bg.php?insuranceNr=" +
          (this.insuranceInfo[0].insuranceNr != null
            ? this.insuranceInfo[0].insuranceNr
            : this.insuranceID) +
          "&startDate=" +
          moment(this.startDate).format("YYYY-MM-DD") +
          "&endDate=" +
          moment(this.endDate).format("YYYY-MM-DD") +
          "&key=" +
          this.apiKey
      );
    },

    editObject(object) {
      this.disableEditable(object);
      this.object = object;
      this.currentEditingPremie = object.premie;
      this.form = {
        objectID: object.objectID,
        title: object.title,
        objectValue: object.objectValue,
        risk: object.risk,
        termID: object.termID,
        premie: object.premie,
        property: object.property,
        editable: true,
        date: this.selectedDate,
      };
      this.componentKey += 1;
    },

    disableEditable(object) {
      this.insuranceInfo.forEach((insurance) => {
        insurance.hidden = true;
        insurance.objects.forEach((obj) => {
          if (obj == object) {
            obj.editable = true;
            obj.hidden = false;
          } else {
            obj.editable = false;
            obj.hidden = true;
          }
        });

        insurance.subProducts.map((sp) => {
          sp.hidden = true;
          sp.objects.map((spObject) => {
            if (spObject == object) {
              spObject.editable = true;
              spObject.hidden = false;
            } else {
              spObject.editable = false;
              spObject.hidden = true;
            }
          });
        });
      });
    },

    removeObject(object) {
      this.deleteStatus = "OBJECT";
      const objectName = object.title ? object.title : "Objekt";
      this.title = 'Vill du verkligen radera "' + objectName + '"?';
      this.object = object;
      this.datum = moment().format("YYYY-MM-DD");
      this.showModalDelete = true;
    },

    deleteObject() {
      this.$v.object.$touch();
      this.$v.datum.$touch();
      if (this.$v.object.$invalid || this.$v.datum.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        let objectID = this.object.objectID;
        let datum = moment(this.datum).format("YYYY-MM-DD");
        window.axios
          .get(
            this.baseUrl +
              "api/edit.php?type=deleteObject&objectID=" +
              objectID +
              "&date=" +
              datum +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "OK";
              this.showModalDelete = false;
              this.datum = moment().format("YYYY-MM-DD");
              this.object = null;
              this.title = null;
              this.deleteStatus = null;
              this.refreshInsuranceInfo();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    updateObject() {
      if (this.form.date == null) {
        this.$swal(
          "Meddelande",
          "Försäkringen för objektet avslutas detta datum",
          "error"
        );
      } else {
        let objectID = this.object.objectID;
        let date = this.form.date;
        let insuranceID = this.insuranceID;
        let title = null;
        let objectValue = null;
        let termID = null;
        let risk = null;
        let property = null;
        if (this.object.title != this.form.title) {
          title = encodeURIComponent(this.form.title);
        }

        if (this.object.objectValue != this.form.objectValue) {
          objectValue = this.form.objectValue;
        }

        if (this.object.termID != this.form.termID) {
          termID = this.form.termID;
        }

        if (this.object.risk != this.form.risk) {
          risk = this.form.risk;
        }

        if (this.object.property != this.form.property) {
          property = this.form.property;
        }
        window.axios
          .get(
            this.baseUrl +
              "api/edit.php?type=editObject&date=" +
              date +
              "&objectID=" +
              objectID +
              "&insuranceID=" +
              insuranceID +
              (title != null ? "&title=" + title : "") +
              (objectValue != null ? "&objectValue=" + objectValue : "") +
              (termID != null ? "&termID=" + termID : "") +
              (risk != null ? "&risk=" + risk : "") +
              (property != null ? "&property=" + property : "") +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });

        setTimeout(() => {
          this.refreshInsuranceInfo();
          this.componentKey += 1;
        }, 100);
      }
    },

    saveSr() {
      window.axios
        .get(
          this.baseUrl +
            "api/edit.php?type=editObject&date=" +
            moment(this.srDate).format("YYYY-MM-DD") +
            "&insuranceID=" +
            this.insuranceID +
            "&sr=" +
            encodeURIComponent(this.srText) +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          console.log(response.data);
          this.refreshInsuranceInfo();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    close(object) {
      this.insuranceInfo.forEach((insurance) => {
        insurance.hidden = false;
        insurance.objects.forEach((obj) => (obj.hidden = false));
        insurance.subProducts.map((sp) => {
          sp.hidden = false;
          sp.objects.map((ob) => (ob.hidden = false));
        });
      });
      object.editable = false;
      this.componentKey += 1;
    },

    getTerm(termID) {
      let term = this.terms.find((term) => term.termID == termID);
      return term ? term.term : "(tom)";
    },

    refreshInsuranceInfo() {
      this.loading = true;
      let selectedDate = moment(this.selectedDate).format("YYYY-MM-DD");
      this.$store
        .dispatch("fetchInsuranceInfo", {
          insuranceID: this.insuranceID,
          selectedDate: selectedDate,
        })
        .then(() => (this.loading = false));
      this.fetchInsuranceLogs();
    },

    fetchPremie(object) {
      console.log(object);
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=premie&productID=" +
            object.productID +
            "&iProductID=" +
            object.iProductID +
            "&risk=" +
            this.form.risk +
            "&objectValue=" +
            this.form.objectValue +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.currentEditingPremie = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchInsuranceLogs() {
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=insuranceLog&insuranceID=" +
            this.insuranceID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.insuranceLogs = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    generateUniqueID() {
      return Date.now();
    },

    addProduct() {
      this.addStatus = "PRODUCT";
      this.title = "Lägg till produkt";
      this.showModalProduct = true;
    },

    saveProduct() {
      this.$v.product.$touch();
      if (this.$v.product.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        let datum = moment(this.datum).format("YYYY-MM-DD");
        window.axios
          .get(
            this.baseUrl +
              "api/edit.php?type=newProduct&insuranceID=" +
              this.insuranceID +
              "&date=" +
              datum +
              "&productID=" +
              this.product.productID +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "OK";
              this.datum = moment().format("YYYY-MM-DD");
              this.product = null;
              this.showModalProduct = false;
              this.$v.$reset();
              this.refreshInsuranceInfo();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    deleteProduct(product) {
      console.log(product);

      this.title = 'Vill du verkligen radera "' + product.name + '"?';
      this.deleteStatus = "PRODUCT";
      this.product = product;
      this.showModalDelete = true;
    },

    requestDeleteProduct() {
      this.$v.product.$touch();
      this.$v.datum.$touch();
      if (this.$v.product.$invalid || this.$v.datum.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        const iProductID = this.product.iProductID;
        let datum = moment(this.datum).format("YYYY-MM-DD");
        window.axios
          .get(
            this.baseUrl +
              "api/edit.php?type=deleteProduct&date=" +
              datum +
              "&iProductID=" +
              iProductID +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "OK";
              this.refreshInsuranceInfo();
              this.title = null;
              this.datum = moment().format("YYYY-MM-DD");
              this.product = null;
              (this.deleteStatus = null), (this.showModalDelete = false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    filterSubProducts(product) {
      let subs = product.subProducts.map((sp) => {
        return sp.productID;
      });
      let subproducts = this.subproducts.filter(
        (sp) =>
          product.productID === sp.parentID && !subs.includes(sp.productID)
      );

      return subproducts;
    },

    hasSubProducts(product) {
      return this.filterSubProducts(product).length > 0;
    },

    addSubProduct(product) {
      this.selectedSubProducts = [];
      this.addStatus = "SUBPRODUCT";
      this.title = "Lägg underprodukt på " + product.name;
      this.product = product;
      this.showModalProduct = true;

      this.selectedSubProducts = this.filterSubProducts(product);
    },

    saveSubProduct() {
      this.$v.datum.$touch();
      this.$v.subproduct.$touch();
      if (this.$v.datum.$$invalid || this.$v.subproduct.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        const productID = this.subproduct.productID;
        let datum = moment(this.datum).format("YYYY-MM-DD");
        window.axios
          .get(
            this.baseUrl +
              "api/edit.php?type=newProduct&insuranceID=" +
              this.insuranceID +
              "&date=" +
              datum +
              "&productID=" +
              productID +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "OK";
              this.addStatus = null;
              this.title = null;
              this.datum = moment().format("YYYY-MM-DD");
              this.product = null;
              this.subproduct = null;
              this.showModalProduct = false;
              this.$v.$reset();
              this.refreshInsuranceInfo();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    addObject(product) {
      let prod = this.products.find(
        (p) =>
          p.productID == product.productID || p.parentID == product.productID
      );
      this.termID = prod !== undefined ? parseInt(prod.termID) : null;

      this.evaluationFormID =
        product.evaluationFormID > 0 ? product.evaluationFormID : null;
      this.addStatus = "OBJECT";
      this.title = "Nytt objekt för " + product.name;
      this.product = product;
      this.showModalProduct = true;

      if (this.evaluationFormID !== null) {
        this.fetchEvaluationForms();
      }
    },

    saveObject() {
      this.$v.objectName.$touch();
      this.$v.objectValue.$touch();
      this.$v.datum.$touch();
      if (
        this.$v.objectName.$invalid ||
        this.$v.objectValue.$invalid ||
        this.$v.datum.$invalid
      ) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        const iProductID = this.product.iProductID;
        const title = this.objectName;
        const objectValue = this.objectValue;
        const termID = this.termID;
        const risk = this.riskfactor;
        const datum = moment(this.datum).format("YYYY-MM-DD");

        window.axios
          .get(
            this.baseUrl +
              "api/edit.php?type=newObject&title=" +
              title +
              "&objectValue=" +
              objectValue +
              "&termID=" +
              termID +
              "&risk=" +
              risk +
              "&iProductID=" +
              iProductID +
              "&date=" +
              datum +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "OK";
              this.datum = moment().format("YYYY-MM-DD");
              this.objectName = null;
              this.objectValue = null;
              this.termID = null;
              this.riskfactor = 100;
              // Varför sätta till null? Får felmeddelande.
              //              this.product = null;
              this.showModalProduct = false;
              this.refreshInsuranceInfo();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    reset() {
      this.product = null;
      this.object = null;
      this.addStatus = null;
      this.deleteStatus = null;
      this.isBuildingSelected = false;
      this.isEvaluationFormOpen = false;
      this.$v.$reset();
    },

    openEvaluationForm() {
      this.isEvaluationFormOpen = true;
      this.isBuildingSelected = true;
      // this.buildingType = null
    },

    closeEvaluationForm() {
      this.isEvaluationFormOpen = false;
      this.isBuildingSelected = false;
      this.buildingType = null;
    },

    fetchEvaluationForms() {
      this.evaluationForms = [];
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=evaluationForm&formID=" +
            this.evaluationFormID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.evaluationForms = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onOptionChanged(ef, optionKey) {
      const selectedIndex = this.evaluationForms.indexOf(ef);
      if (ef.options.length > 0) {
        this.evaluationForms[selectedIndex].options.map((option, index) => {
          if (index == optionKey) {
            option.selected = true;
          } else {
            option.selected = false;
          }
        });
      }
    },

    houseTypeSelected(building) {
      this.buildingTypes.map((bt) => {
        if (bt == building) {
          bt.selected = true;
        } else {
          bt.selected = false;
        }
      });
    },

    selectedHouseType() {
      this.buildingType = this.buildingTypes.find((bt) => bt.selected);
    },

    calculateTotalPoints() {
      this.totalPoints = 0;
      if (this.evaluationForms.length > 0) {
        this.evaluationForms.map((efs) => {
          if (efs.options.length > 0) {
            efs.options.map((option) => {
              if (option.selected) {
                this.totalPoints += option.points;
              }
            });
          } else if (efs.questionID == 11) {
            this.totalAreas = parseInt(efs.value);
          } else {
            var value =
              efs.value != null && efs.value != "" ? parseInt(efs.value) : 0;
            this.totalPoints += efs.questionPoints * value;
          }
        });
      }
    },

    saveForms() {
      this.modalLoading = true;
      this.calculateTotalPoints();
      this.saveEvaluationForm().then((response) => {
        this.modalLoading = true;
        if (response.data.status == "ok") {
          var res = response.data.data;
          // Update object value
          this.objectValue = res.value;
          this.modalLoading = false;
          this.closeEvaluationForm();
        } else {
          this.modalLoading = false;
        }
      });
    },

    saveEvaluationForm() {
      var points = this.totalPoints;
      var area = this.totalAreas;
      var houseType = this.buildingType.id;
      return new Promise((resolve, reject) => {
        window.axios
          .get(
            this.baseUrl +
              "api/get.php?type=value&points=" +
              points +
              "&houseType=" +
              houseType +
              "&area=" +
              area +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            this.loading = false;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
</script>

<style lang="scss">
.insurance-lists {
  .content {
    .select-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .form-group:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .divider {
    border-bottom: 1px solid #e8e8e8;
    margin: 10px 0 15px;
  }

  .table {
    line-height: 2.1;
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .btn {
        margin-left: 7px;
      }
    }
  }
}
#srSave {
  padding: 5px;
  margin-left: -25px;
}
</style>
<template>
  <nav id="sidebar" :class="{ hidden: hidden }" v-if="auth() && !isPartner">
    <div class="side-header">
      <b-navbar-brand :to="{ name: 'home' }">
        <img class="nav-logo" src="@/assets/f-logo.svg" alt width="25" style="margin-bottom:3px;" />
      </b-navbar-brand>
      <h5>Fourth 2.1.12</h5>
    </div>
    <ul class="list-unstyled">
      <template v-if="menus.length > 0">
        <li
          v-for="(menu, index) in menus"
          :key="index"
          :class="{ active: menu.collapsed }"
        >
          <template
            v-if="
              (menu.name == 'users' ||
                menu.name == 'system' ||
                menu.name == 'backup' ||
                menu.name == 'article-admin') &&
              isAdmin
            "
          >
            <router-link
              class="menu"
              :to="{ name: menu.name }"
              @click.native="activeBar()"
            >
              <span class="menu-icon">
                <font-awesome-icon :icon="menu.icon" />
              </span>
              <span class="menu-text">{{ menu.title }}</span>
              <span v-if="menu.badge != null">
                &nbsp;
                <b-badge>{{ totalAlerts }}</b-badge>
              </span>
              <span
                class="caret-up"
                v-show="menu.children.length > 0 && menu.collapsed"
              >
                <font-awesome-icon
                  icon="caret-up"
                  :style="{ color: 'white' }"
                />
              </span>
              <span
                class="caret-down"
                v-show="menu.children.length > 0 && !menu.collapsed"
              >
                <font-awesome-icon icon="caret-down" />
              </span>
            </router-link>
            <ul class="list-unstyled" id="pageSubmenu">
              <li v-for="(child, key) in menu.children" :key="key">
                <router-link
                  :to="{ name: child.name, params: { userId: 123 } }"
                  >{{ child.title }}</router-link
                >
              </li>
            </ul>
          </template>
          <template
            v-if="
              menu.name != 'users' &&
              menu.name != 'system' &&
              menu.name != 'backup' &&
              menu.name != 'article-admin'
            "
          >
            <router-link
              class="menu"
              :to="{ name: menu.name }"
              @click.native="activeBar()"
            >
              <span class="menu-icon">
                <font-awesome-icon :icon="menu.icon" />
              </span>
              <span class="menu-text">{{ menu.title }}</span>
              <span v-if="menu.badge != null">
                &nbsp;
                <b-badge>{{ totalAlerts }}</b-badge>
              </span>
              <span
                class="caret-up"
                v-show="menu.children.length > 0 && menu.collapsed"
              >
                <font-awesome-icon
                  icon="caret-up"
                  :style="{ color: 'white' }"
                />
              </span>
              <span
                class="caret-down"
                v-show="menu.children.length > 0 && !menu.collapsed"
              >
                <font-awesome-icon icon="caret-down" />
              </span>
            </router-link>
            <ul class="list-unstyled" id="pageSubmenu">
              <li v-for="(child, key) in menu.children" :key="key">
                <router-link
                  :to="{ name: child.name, params: { userId: 123 } }"
                  >{{ child.title }}</router-link
                >
              </li>
            </ul>
          </template>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { isAuthenticated } from '@/_helpers/auth'
export default {
  data() {
    return {
      newAlerts: 0,
      totalAlerts: 0,
      menus: [
        {
          path: "/",
          title: "Hem",
          name: "home",
          icon: "home",
          collapsed: true,
          children: [],
        },
        {
          path: "/kunder",
          title: "Kunder",
          name: "customers",
          icon: "users",
          collapsed: true,
          children: [],
        },
        {
          path: "/notes",
          title: "Anteckningar",
          name: "message",
          icon: "clipboard",
          collapsed: true,
          children: [],
        },
        {
          path: "/alerts",
          title: "Uppföljningar",
          name: "alerts",
          icon: "share-alt",
          badge: true,
          collapsed: true,
          children: [],
        },
        {
          path: "/news",
          title: "Aktuellt",
          name: "news",
          icon: "flag",
          badge: true,
          collapsed: true,
          children: [],
        },
        {
          path: "/draft/insurances",
          title: "Utkast försäkringar",
          name: "insurances-draft",
          icon: "tree",
          collapsed: true,
          children: [],
        },
        {
          path: "/ekonomi",
          title: "Ekonomi",
          name: "economy",
          icon: "life-ring",
          collapsed: true,
          children: [
            {
              path: "/ekonomi/betalning",
              title: "Betalning",
              name: "economy-payment",
              icon: null,
              collapsed: true,
              children: [],
            },
            {
              path: "/ekonomi/konto",
              title: "Konto",
              name: "economy-account",
              icon: null,
              collapsed: true,
              children: [],
            },
            {
              path: "/ekonomi/premie",
              title: "Premie",
              name: "economy-premie",
              icon: null,
              collapsed: true,
              children: [],
            },
            {
              path: "/ekonomi/saldo",
              title: "Saldo",
              name: "economy-balance",
              icon: null,
              collapsed: true,
              children: [],
            },
          ],
        },
        {
          path: "/skadehantering",
          title: "Skadehantering",
          name: "damage-management",
          icon: "house-damage",
          collapsed: true,
          children: [
            {
              path: "/skaderapport",
              title: "Rapporter",
              name: "damage-report",
              icon: null,
              collapsed: true,
              children: [],
            },
          ],
        },
        {
          path: "/products",
          title: "Produkter",
          name: "products",
          icon: "lightbulb",
          collapsed: true,
          children: [],
        },
        /*
        {
          path: "/reports",
          title: "Rapporter",
          name: "reports",
          icon: "chart-line",
          collapsed: true,
          children: [],
        },
        */
        {
          path: "/changelog",
          title: "Ändringslogg",
          name: "changeslog",
          icon: "file-invoice",
          collapsed: true,
          children: [
            {
              path: "/deleted/customers",
              title: "Raderade kunder",
              name: "deletedCustomers",
              icon: null,
              collapsed: true,
              children: [],
            },
            {
              path: "/changed/objects",
              title: "Ändrade objekt",
              name: "ChangedObjects",
              icon: null,
              collapsed: true,
              children: [],
            },
            {
              path: "/changed/insurances",
              title: "Ändrade försäkringar",
              name: "ChangedInsurances",
              icon: null,
              collapsed: true,
              children: []
            },
            // {
            //   path: "/deleted/insurance",
            //   title: "Raderade försäkringar",
            //   name: "deletedInsurance",
            //   icon: null,
            //   collapsed: true,
            //   children: [],
            // },
            {
              path: "/deleted/damages",
              title: "Raderade skador",
              name: "deletedDamages",
              icon: null,
              collapsed: true,
              children: [],
            },
            {
              path: "/changed/damages",
              title: "Ändrade skador",
              name: "changed-damages",
              icon: null,
              collapsed: true,
              children: [],
            },
          ],
        },
        {
          path: "/settings",
          title: "Inställningar",
          name: "settings",
          icon: "sliders-h",
          collapsed: true,
          children: [],
        },
        {
          path: "/articleAdmin",
          title: "Artiklar",
          name: "article-admin",
          icon: "file-pdf",
          collapsed: true,
          children: [],
        },
        {
          path: "/system",
          title: "Systeminställningar",
          name: "system",
          icon: "desktop",
          collapsed: true,
          children: [],
        },
        {
          path: "/users",
          title: "Användare",
          name: "users",
          icon: "address-card",
          collapsed: true,
          children: [],
        },
        {
          path: "/batch",
          title: "Utskrifter",
          name: "batch",
          icon: "file-pdf",
          collapsed: true,
          children: [],
        },
        {
          path: "/backup",
          title: "Backup",
          icon: "database",
          name: "backup",
          collapsed: true,
          children: [],
        },
      ],
    };
  },

  computed: {
    ...mapState([
      "hidden",
      "authenticated",
      "baseUrl",
      "apiKey",
      "accesslevel",
    ]),

    ...mapGetters(["isAdmin", "isUser", "isPartner"]),
  },

  mounted() {
    this.activeBar();
    if (isAuthenticated) {
      this.checkAlerts();
    }
  },

  methods: {
    auth() {
      return this.authenticated && this.$route.name !== "login" ? true : false;
    },

    checkAlerts() {
      window.axios
        .get(this.baseUrl + "api/getAlerts.php?key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.newAlerts = response.data.newAlerts;
            this.totalAlerts = response.data.totalAlerts;
            setTimeout(this.checkAlerts, 60000);
          } else {
            setTimeout(this.checkAlerts, 60000);
            this.$router.push({ name: "login" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    itemClicked(selectedIndex) {
      this.menus.forEach((menu, index) => {
        if (index == selectedIndex) {
          menu.collapsed = !menu.collapsed;
        } else {
          menu.collapsed = true;
        }
      });
      this.activeBar();
    },

    activeBar() {
      let routeName = this.$route.name;
      this.selectMenu(routeName);
    },

    selectMenu(name) {
      this.menus.forEach((menu) => {
        if (menu.name == name) {
          menu.collapsed = true;
        } else {
          menu.collapsed = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#sidebar {
  background: #fafafa;
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
  border-right: 1px solid #ddd;
  transition-duration: 600ms;
  transition-timing-function: ease-in-out;

  .side-header {
    background-color: #5AA196;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding: 0.35rem;
    border-bottom: 1px solid #ddd;
    margin-bottom: 25px;
    padding: 0.5rem 1rem;

    h5 {
      margin: 0;
      padding: 0;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }

  ul {
    color: #2c3e50;
    .components {
      padding: 20px 0;
      border-bottom: 1px solid #47748b;
    }
    li {
      a {
        padding: 10px 10px 10px 30px;
        font-size: 1.1em;
        display: block;
        position: relative;
        color: #5a5a5a;
        font-weight: 500;
        letter-spacing: 0.5px;
        &:hover {
          color: #17a2b8;
          // background: #fff;
        }
        .menu-icon {
          display: inline-block;
          width: 1.25em;
          margin-right: 0.8em;
          text-align: center;
          font-size: 1.125rem;
          color: #a6abbd;
        }
        .menu-text {
          font-size: 15px;
        }
        .caret-up,
        .caret-down {
          position: absolute;
          right: 20px;
          opacity: 0.8;
        }
      }
      ul {
        display: none;
        background: #f1f1f1;
        li {
          a {
            font-size: 0.85em !important;
            padding-left: 72px !important;
            font-weight: 600;
          }
          &:hover {
            // background: #2C3E50;
            a {
              // color: #fff;
              font-weight: 600;
            }
          }
        }
        li.active {
          a {
            font-weight: 600;
          }
        }
      }
    }
    li.active {
      background: #5AA196;
      .menu-icon,
      .menu-text {
        color: #fff;
      }
      ul {
        display: block;
      }
    }
  }

  a[data-toggle="collapse"] {
    position: relative;
  }

  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}
#sidebar.hidden {
  margin-left: -250px;
}
</style>
<template>
  <div
    class="row divborder divbg align-items-center"
    style="
      margin-left: 0px;
      margin-bottom: 8px;
      margin-right: 1px;
      padding: 8px;
    "
  >
    <div class="col-md-4">
      <h3>
        <router-link
          :to="{ name: 'customer-show', params: { id: customer.id } }"
        >
          {{
            customer.category == "co"
              ? customer.companyName
              : customer.firstName
          }}
          {{ customer.category == "co" ? "" : customer.lastName }}
          {{
            customer.category == "co" && customer.lastName.length
              ? "/ " + customer.firstName + " " + customer.lastName
              : ""
          }}</router-link
        >
      </h3>
    </div>
    <div class="col-md-2">
      <span>
        {{ customer.street }}, {{ nicePostcode(customer.postcode) }}
        {{ customer.city }}<br />
        {{ customer.mobilePhone ? nicePhone(customer.mobilePhone) + ", " : ""
        }}{{
          customer.homePhone ? nicePhone(customer.homePhone) + ", " : ""
        }}
        kundnummer {{ customer.customerNr }}</span
      >
    </div>
    <div class="col-md-6 toolbar text-right">
      <button
        class="btn"
        type="button"
        @click="editCustomer()"
        title="Redigera kunduppgifter"
      >
        <img :src="require('@/assets/images/user-edit.svg')" />
      </button>
      <button
        class="btn btn-default"
        title="Radera kund"
        type="button"
        @click="deleteCustomer()"
        v-if="accesslevel == 'admin'"
      >
        <img :src="require('@/assets/images/delete.svg')" />
      </button>
      <button
        class="btn btn-default"
        type="button"
        @click="toggleMessage()"
        title="Skicka kundmeddelande"
      >
        <img :src="require('@/assets/images/message.svg')" />
      </button>
      <button
        class="btn btn-default"
        type="button"
        @click="createInsurance()"
        title="Ny försäkring"
      >
        <img :src="require('@/assets/images/newdoc.svg')" />
      </button>
      <span
        style="color: #39796e; font-size: 22px; font-weight: 600"
        class="vertical-divider"
        v-if="damageID != null"
      >
        <button
          class="btn btn-default"
          title="Anmäl skada"
          type="button"
          @click="editDamage()"
        >
          <img :src="require('@/assets/images/skada.svg')" />
        </button>
      </span>
      <span
        style="color: #39796e; font-size: 22px; font-weight: 600"
        class="vertical-divider"
        v-if="insuranceNr != null"
      >
        {{ insuranceNr }}
        <button
          title="Faktura"
          class="btn btn-default"
          type="button"
          @click="printInvoice(insuranceNr)"
        >
          <img :src="require('@/assets/images/faktura.svg')" />
        </button>
        <button
          class="btn btn-default"
          title="Självrisk"
          type="button"
          @click="$emit('sjalvrisk')"
        >
          <img :src="require('@/assets/images/sjalvrisk.svg')" />
        </button>
        <button
          class="btn btn-default"
          title="Anmäl skada"
          type="button"
          @click="damageManagement(insuranceNr)"
        >
          <img :src="require('@/assets/images/skada.svg')" />
        </button>
        <button
          class="btn btn-default"
          title="Transaktioner"
          type="button"
          @click="transactions()"
        >
          <img :src="require('@/assets/images/economy.svg')" />
        </button>
        <button
          class="btn btn-default"
          type="button"
          @click="editInsurance()"
          v-if="!editing"
          title="Redigera försäkring"
        >
          <img :src="require('@/assets/images/edit-insurance.svg')" />
        </button>
        <button
          class="btn btn-default"
          v-if="editing"
          type="button"
          @click="closeEdit()"
          title="Stäng redigeringläge"
        >
          <img :src="require('@/assets/images/close.svg')" />
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { parsePostcode, parsePhone } from "@/_helpers/parser";
import { mapState } from "vuex";

export default {
  name: "customer-header",
  props: ["customer", "insuranceNr", "editing", "damageID"],
  data() {
    return {
      isOpen: false,
      enableCustomer: false,
    };
  },
  computed: {
    ...mapState(["hidden", "baseUrl", "apiKey", "accesslevel"]),
  },

  methods: {
    editCustomer() {
      this.$router
        .push({
          name: "customer-edit",
          params: { id: this.customer.id },
        })
        .catch(() => {});
    },

    editDamage() {
      this.$router.push({
        name: "edit-damage",
        params: { damageID: this.damageID }
      })
    },

    deleteCustomer() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Vill du verkligen radera kunden?",
          input: "textarea",
          inputPlaceholder: "Anledning",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Radera",
          cancelButtonText: "Avbryt",
          showLoaderOnConfirm: true,
          preConfirm: (message) => {
            if (message === "" || message === null) {
              this.$swal.showValidationMessage("Du måste ange anledning");
            } else {
              var postdata = new FormData();
              postdata.append("id", this.customer.id);

              return window.axios
                .post(
                  this.baseUrl + "api/deleteCustomer.php?key=" + this.apiKey,
                  postdata
                )
                .then((response) => {
                  if (response.data.status == "ok") {
                    this.saveNote("Orsak radering av kund: " + message);
                    this.$router.push({ name: "customers" });
                  } else {
                    throw new Error(response.statusText);
                  }
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(
                    `Det gick inte att radera kunden: ${error}`
                  );
                });
            }
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire("Klart!", "Kunden är raderad.", "success");
          }
        });
    },

    createInsurance() {
      window.axios
        .post(
          this.baseUrl +
            "api/draft.php?type=create&customerID=" +
            this.customer.id +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            console.log(response.data);
            let insuranceID = response.data.data.insuranceID;
            this.$router
              .push({
                name: "create-insurance",
                query: {
                  insuranceID: insuranceID,
                  customerID: this.customer.id,
                },
              })
              .catch(() => {});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    transactions() {
      this.$router
        .push({
          name: "economy-account",
          query: {
            insuranceNr: this.insuranceNr,
          },
        })
        .catch(() => {});
    },

    selectedCustomer() {
      this.isOpen = !this.isOpen;
    },

    toggleMessage() {
      this.enableCustomer = !this.enableCustomer;
      this.$emit("toggleCustomer", this.enableCustomer);
    },

    nicePostcode(pc) {
      return parsePostcode(pc);
    },

    nicePhone(tfn) {
      return parsePhone(tfn);
    },
    editInsurance() {
      this.$emit("editInsurance", true);
    },
    closeEdit() {
      this.$emit("close", true);
    },
    printInvoice(insuranceNr) {
      this.$emit("invoice", insuranceNr);
      // window.open(
      //   this.baseUrl +
      //     "print/bg.php?insuranceNr=" +
      //     insuranceNr +
      //     "&key=" +
      //     this.apiKey
      // );
    },
    damageManagement(insuranceNr) {
      let id = this.customer.id;
      this.$router.push({
        name: "create-damage",
        params: { insuranceID: insuranceNr, customerID: id },
      });
    },
    saveNote(message) {
      var postdata = new FormData();
      postdata.append("id", this.$route.params.id);
      postdata.append("note", message);
      window.axios
        .post(this.baseUrl + "api/saveNote.php?key=" + this.apiKey, postdata)
        .then((response) => {
          if (response.data.status == "ok") {
            console.log(response);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.vertical-divider {
  border-left: 2px solid #5d5d5d;
}
.divborder {
  border-radius: 5px;
  //border: 1px solid #dde3eb;
}
.divbg {
  background: white;
}
.toolbar {
  img {
    margin: 8px;
    height: 40px;
  }
  button {
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    //border: 1px solid #dde3eb;
    margin: 4px;
    background-color: white;
  }
}
</style>
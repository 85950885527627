import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import {
	Role
} from '@/_helpers/role'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		baseUrl: process.env.VUE_APP_URL,
		authenticated: JSON.parse(localStorage.getItem('_fsfe_apiKey')) ? true : false,
		hidden: false,
		apiKey: JSON.parse(localStorage.getItem('_fsfe_apiKey')) || null,
		realname: JSON.parse(localStorage.getItem('_fsfe_realname')) || null,
		accesslevel: JSON.parse(localStorage.getItem('_fsfe_accesslevel')) || null,
		error: null,
		customer: null,
		customers: [],
		categories: [],
		dateDiff: '',
		notes: [],
		products: [],
		category: null,
		standards: [],
		standard: null,
		evaluationTypes: [],
		guidelines: [],
		selectedProducts: [],
		subproducts: [],
		insurances: [],
		insuranceInfo: [],
		terms: [],
		insuranceGuidelines: [],
		selectedGuidelines: [],
		optionalGuidelines: [],
		sr: '',
		standardSr: '',
	},

	mutations: {
		['HIDE_SIDEBAR'](state, payload) {
			state.hidden = payload
		},

		AUTH_LOGIN(state, payload) {
			state.authenticated = true
			state.apiKey = payload.key
			state.realname = payload.realname
			state.accesslevel = payload.accesslevel
		},

		SET_AUTHENTICATION(state, payload = false) {
			state.authenticated = payload;
		},

		AUTH_LOGOUT(state) {
			state.authenticated = false
			state.realname = null
			state.accesslevel = null
		},

		ERRORS(state, payload) {
			state.error = payload
		},

		FETCH_CUSTOMERS(state, payload) {
			state.customers = payload
		},

		FETCH_NOTES(state, payload) {
			state.notes = payload
		},

		FETCH_CATEGORIES(state, payload) {
			state.categories = payload
		},

		FETCH_PRODUCTS(state, payload) {
			state.products = payload
		},

		CATEGORIES(state, payload) {
			state.categories = payload
		},

		CATEGORY(state, payload) {
			state.category = payload
		},

		STANDARDS(state, payload) {
			state.standards = payload
		},

		ADD_STANDARD(state, payload) {
			state.standards.push(payload)
		},

		STANDARD(state, payload) {
			state.standard = payload
		},

		FETCH_EVALUATIONTYPES(state, payload) {
			state.evaluationTypes = payload
		},

		FETCH_GUIDELINES(state, payload) {
			state.guidlines = payload
		},

		EDITABLE_MODE(state, payload) {
			state.editable = payload;
		},

		FETCH_SELECTED_PRODUCTS(state, payload) {
			if (payload.length > 0) {
				payload.map((pl) => {
					pl.children = []
					pl.files = []
					pl.draggable = false
					state.subproducts.map((product) => {
						if (pl.productID == product.parentID) {
							product.selected = false
							product.draggable = true
							pl.children.push(product);
						}
					});
				});
			}
			state.selectedProducts = payload
		},

		FETCH_SUBPRODUCTS(state, payload) {
			state.subproducts = payload
		},

		FETCH_INSURANCE_INFO(state, payload) {
			state.insuranceInfo = []
			payload.data.forEach((product) => {
				product.objects.forEach((object) => {
					if (!object.premie) {
						object.premie = 0
					}
				})
				if (product.subProducts.length > 0) {
					product.subProducts.forEach((sp) => {
						sp.objects.forEach((object) => {
							if (!object.premie) {
								object.premie = 0
							}
						})
					})
				}
			})
			state.insuranceInfo = payload.data
			state.customer = payload.customer
			state.dateDiff = payload.originalDate != payload.date ? payload.date : ''
			state.standardSr = payload.standardSr
			state.sr = payload.sr
		},

		FETCH_ALL_OBJECTS(state, payload) {
			state.insurances = []
			var sr = ''
			payload.forEach((product) => {
				if (sr.length == 0 && product.sr != undefined) {
					sr = product.sr
				}
				product.objects.forEach((object) => {
					if (!object.premie) {
						object.premie = 0
					}
				})
				state.insurances.push({
					productID: product.productID,
					name: product.name,
					draftProductID: product.draftProductID,
					objects: product.objects,
					evaluationTypeID: product.evaluationTypeID,
					evaluationFormID: product.evaluationFormID,
					flatFee: product.flatFee,
					dragging: false,
					sr: sr,
					unit: product.unit
				})
				if (product.subProducts.length > 0) {
					product.subProducts.forEach((sp) => {
						sp.objects.forEach((object) => {
							if (!object.premie) {
								object.premie = 0
							}
						})
						state.insurances.push({
							productID: sp.productID,
							parentID: product.productID,
							name: sp.name,
							draftProductID: sp.draftProductID,
							objects: sp.objects,
							flatFee: sp.flatFee,
							premie: sp.premie,
							evaluationTypeID: sp.evaluationTypeID,
							evaluationFormID: sp.evaluationFormID,
							dragging: false,
						});
					});
				}
			})
			state.insurances.map((io) => io.objects.unshift())
			// console.log(state.insurances)
		},

		INSURANCE_ON_DROP(state, payload) {
			const selectedIndex = state.insurances.indexOf(payload);
			state.insurances[selectedIndex].dragging = true;
		},

		INSURANCE_ON_LEAVING(state, payload) {
			const selectedIndex = state.insurances.indexOf(payload);
			state.insurances[selectedIndex].dragging = false;
		},

		FETCH_TERMS(state, payload) {
			state.terms = payload
		},

		FETCH_INSURANCE_GUIDELINES(state, payload) {
			state.insuranceGuidelines = payload
		},

		FILTER_GUIDELINES(state) {
			state.selectedGuidelines = []
			state.optionalGuidelines = []
			state.selectedProducts.forEach((sp) => {
				state.insuranceGuidelines.forEach((gl) => {
					if (sp.productID == gl.productID && (gl.optional == 'n' || gl.selected == 'y')) {
						state.selectedGuidelines.push(gl)
					} else if (sp.productID == gl.productID && gl.optional == 'y') {
						state.optionalGuidelines.push(gl)
					}
				})
			})
		},

		FETCH_EXTRA_GUIDELINES(state, payload) {
			payload.forEach((egl) => {
				state.selectedGuidelines.push({
					extraGuidelineID: egl.extraGuidelineID,
					insuranceID: egl.insuranceID,
					name: egl.name,
					optional: 'y',
					selected: 'y'
				})
			})
		},

		ADD_PREMIE_VALUE(state) {
			state.insurances.forEach((insurance) => {
				insurance.objects.forEach((object) => {
					if (!object.premie) {
						object.premie = 0
					}
				})
			})
		}
	},

	getters: {
		isHidden(state) {
			return state.hidden
		},

		getApiKey(state) {
			return state.apiKey
		},

		getAuthentication(state) {
			return state.authenticated
		},

		isAdmin(state) {
			return state.accesslevel == Role.Admin
		},

		isUser(state) {
			return state.accesslevel == Role.User
		},

		isPartner(state) {
			return state.accesslevel == Role.LF || state.accesslevel == Role.Anticimex
		},

		getCategories(state) {
			return state.category
		},
	},

	actions: {
		initialiseStore({
			state,
			commit,
			dispatch
		}) {
			// commit('STANDARDS', standards)
			if (state.apiKey) {
				axios.get(state.baseUrl + 'api/validate.php?key=' + state.apiKey)
					.then((response) => {
						if (response.data.status == 'ok') {
							state.authenticated = true
							dispatch('fetchEvaluationTypes')
						} else {
							commit('AUTH_LOGOUT')
						}
					})
			} else {
				commit('AUTH_LOGOUT')
			}
		},

		hideSidebar({
			commit
		}, data) {
			commit('HIDE_SIDEBAR', data)
		},

		validateAuth({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				axios.post(state.baseUrl + 'api/validate.php')
					.then((response) => {
						if (response.data.status == 'ok') {
							// commit('AUTH_LOGIN', response.data)
							resolve(response)
						} else {
							commit('ERRORS', response.data.error)
						}
					})
					.catch((error) => {
						commit('AUTH_LOGOUT')
						reject(error)
					})
			})
		},

		login({
			commit,
			state
		}, formData) {
			return new Promise((resolve, reject) => {
				axios.post(state.baseUrl + 'api/auth.php', formData)
					.then((response) => {
						if (response.data.status == 'ok') {
							commit('AUTH_LOGIN', response.data)
							commit('ERRORS', null)
							resolve(response)
						} else {
							commit('ERRORS', response.data.error)
						}
					})
					.catch((error) => {
						commit('AUTH_LOGOUT')
						reject(error)
					})
			})
		},

		logout({
			commit,
			state
		}, formData) {
			return new Promise((resolve, reject) => {
				axios.post(state.baseUrl + 'api/logout.php', formData)
					.then((response) => {
						commit('AUTH_LOGOUT')
						resolve(response)
					})
					.catch((error) => {
						commit('AUTH_LOGOUT')
						reject(error)
					})
			})
		},

		getCategory({
			commit,
			state
		}, categoryID) {
			var category = state.categories.find((category) => category.id == categoryID)
			commit('CATEGORY', category)
		},

		standard({
			commit,
			state
		}, standardID) {
			var standard = state.standards.find((standard) => standard.id == standardID)
			commit('STANDARD', standard)
		},

		fetchCustomers({
			state,
			commit,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/getCustomers.php?search=' + encodeURIComponent(data.keyword) + '&limit=' + encodeURIComponent(data.limit) + '&key=' + encodeURIComponent(state.apiKey))
					.then(response => {
						if (response.data.status == "ok") {
							commit('FETCH_CUSTOMERS', response.data.customers)
							resolve(response)
						} else {
							dispatch('logout')
							reject(response.data.error)
						}
					})
					.catch((error) => {
						reject(error)
					})
			})
		},

		fetchNotes({
			state,
			commit,
			dispatch
		}, data) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/get.php?type=notes&search=' + encodeURIComponent(data.keyword) + '&user=' + encodeURIComponent(data.user) + '&note=' + encodeURIComponent(data.note) + '&limit=' + encodeURIComponent(data.limit) + '&key=' + encodeURIComponent(state.apiKey))
					.then(response => {
						if (response.data.status == 'ok') {
							commit('FETCH_NOTES', response.data.data)
							resolve(response)
						} else {
							dispatch('logout')
							reject(response.data.error)
						}
					})
					.catch((error) => {
						reject(error)
					})
			})
		},

		fetchEvaluationTypes({
			state,
			commit
		}) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/get.php?type=evaluationTypes&key=' + state.apiKey)
					.then((response) => {
						if (response.data.status == 'ok') {
							commit('FETCH_EVALUATIONTYPES', response.data.data)
							resolve(response)
						} else {
							commit('ERRORS', response.data.error)
							reject(response.data.error)
						}
					})
					.catch((error) => {
						console.log(error)
					})
			})
		},

		fetchCategories({
			state,
			commit
		}) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/getCategories.php?key=' + state.apiKey)
					.then((response) => {
						if (response.data.status == 'ok') {
							commit('FETCH_CATEGORIES', response.data.data)
							resolve(response.data)
						}
					})
					.catch((error) => {
						commit('ERRORS', error)
						reject(error)
					})
			})
		},

		fetchProducts({
			state,
			commit
		}) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/get.php?type=products&key=' + state.apiKey)
					.then((response) => {
						var products = []
						if (response.data.data.length > 0) {
							response.data.data.map((data) => {
								data.selected = false
								data.products = []
								products.push(data)
							})
						}
						commit('FETCH_PRODUCTS', response.data.data)
						resolve(response.data)
					})
					.catch((error) => {
						commit('ERRORS', error)
						reject(error)
					})
			})
		},

		fetchGuideLines({
			state,
			commit
		}, productID) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/get.php?type=guidelines&productID=' + productID + '&key=' + state.apiKey)
					.then((response) => {
						if (response.data.status == 'ok') {
							commit('FETCH_GUIDELINES', response.data)
							resolve(response.data)
						}
					})
					.catch((error) => {
						commit('ERRORS', error)
						reject(error)
					})
			})
		},

		fetchSelectedProducts({
			state,
			commit
		}, insuranceID) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/get.php?type=draftProducts&insuranceID=' + encodeURIComponent(insuranceID) + '&key=' + encodeURIComponent(state.apiKey))
					.then((response) => {
						if (response.data.status == 'ok') {
							commit('FETCH_SELECTED_PRODUCTS', response.data.data)
							resolve(response.data.data)
						}
					})
					.catch((error) => {
						commit('ERRORS', error)
						reject(error)
					})
			})
		},

		fetchSubProducts({
			state,
			commit
		}) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/get.php?type=subProducts&key=' + encodeURIComponent(state.apiKey))
					.then((response) => {
						if (response.data.status == 'ok') {
							commit('FETCH_SUBPRODUCTS', response.data.data)
							resolve(response.data.data)
						}
					})
					.catch((error) => {
						commit('ERRORS', error)
						reject(error)
					})
			})
		},

		fetchInsuranceInfo({
			state,
			commit
		}, data) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/get.php?type=insuranceInfo&insuranceID=' + encodeURIComponent(data.insuranceID) + '&date=' + encodeURIComponent(data.selectedDate) + '&key=' + encodeURIComponent(state.apiKey))
					.then((response) => {
						if (response.data.status == 'ok') {
							commit('FETCH_INSURANCE_INFO', response.data)
							resolve(response.data)
						}
					})
					.catch((error) => {
						commit('ERRORS', error)
						reject(error)
					})
			})
		},

		fetchAllObjects({
			state,
			commit
		}, insuranceID) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/get.php?type=draftObjects&insuranceID=' + encodeURIComponent(insuranceID) + '&key=' + encodeURIComponent(state.apiKey))
					.then((response) => {
						if (response.data.status == 'ok') {
							commit('FETCH_ALL_OBJECTS', response.data.data)
							resolve(response.data.data)
						}
					})
					.catch((error) => {
						commit('ERRORS', error)
						reject(error)
					})
			})
		},

		fetchTerms({
			state,
			commit
		}) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/get.php?type=draftTerms&key=' + encodeURIComponent(state.apiKey))
					.then((response) => {
						if (response.data.status == 'ok') {
							commit('FETCH_TERMS', response.data.data)
							resolve(response.data.data)
						}
					})
					.catch((error) => {
						commit('ERRORS', error)
						reject(error)
					})
			})
		},

		fetchInsuranceGuidelines({
			state,
			commit
		}, insuranceID) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/get.php?type=guidelines&insuranceID=' + encodeURIComponent(insuranceID) + '&key=' + encodeURIComponent(state.apiKey))
					.then((response) => {
						if (response.data.status == 'ok') {
							commit('FETCH_INSURANCE_GUIDELINES', response.data.data)
							commit('FILTER_GUIDELINES')
							resolve(response.data.data)
						}
					})
					.catch((error) => {
						commit('ERRORS', error)
						reject(error)
					})
			})
		},

		fetchExtraGuidelines({
			state,
			commit
		}, insuranceID) {
			return new Promise((resolve, reject) => {
				axios.get(state.baseUrl + 'api/get.php?type=extraGuidelines&insuranceID=' + insuranceID + '&key=' + state.apiKey)
					.then((response) => {
						if (response.data.status == 'ok') {
							commit('FETCH_EXTRA_GUIDELINES', response.data.data)
							resolve(response.data.data)
						}
					})
					.catch((error) => {
						commit('ERRORS', error)
						reject(error)
					})
			})
		}

	},

	modules: {}
})

store.subscribe((mutation) => {
	if (mutation.type == 'AUTH_LOGIN') {
		localStorage.setItem('_fsfe_apiKey', JSON.stringify(mutation.payload.key))
		localStorage.setItem('_fsfe_realname', JSON.stringify(mutation.payload.realname))
		localStorage.setItem('_fsfe_accesslevel', JSON.stringify(mutation.payload.accesslevel))
		localStorage.setItem('_fsfe_editable', JSON.stringify(mutation.payload.editable))
	}

	if (mutation.type == 'AUTH_LOGOUT') {
		localStorage.removeItem('_fsfe_apiKey')
		localStorage.removeItem('_fsfe_realname')
		localStorage.removeItem('_fsfe_accesslevel')
		localStorage.removeItem('_fsfe_editable')
	}
})

export default store;
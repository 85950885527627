<template>
  <div class="economy w-100">
    <navbar />
    <div class="content p-4">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <select
                class="form-control"
                id="selectForm"
                v-model="selectedDays"
                @change="checkMarkedBalances()"
              >
                <option :value="null">Markera flera</option>
                <option value="allDays">Alla</option>
                <option value="30">Alla äldre än 30 dagar</option>
                <option value="40">Alla äldre än 40 dagar</option>
              </select>
            </div>
            <div class="col">
            <form
              method="post"
              :action="baseUrl + 'print/pa_batch.php?key=' + apiKey"
            >
              <input
                type="hidden"
                name="insuranceNr"
                v-model="insuranceNr"
              />
              <button
                type="submit"
                class="btn btn-success"
                :disabled="selectedDates.length == 0"
                @click="prepareInsurance()"
              >
                Utskrift
              </button>
            </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Välj</th>
                <th>Datum</th>
                <th>Kund</th>
                <th>Försäkring</th>
                <th>Belopp</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="balances != null && balances.length > 0">
              <tr v-for="(r, index) in balances" :key="index">
                <td>
                  <div class="form-group">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :checked="r.selected"
                      @change="checkSingleBalance(r)"
                    />
                  </div>
                </td>
                <td>{{ r.fdate }}</td>
                <td>
                  <router-link
                    :to="{ name: 'customer-show', params: { id: r.id } }"
                  >
                    {{
                      r.companyName != null
                        ? r.companyName
                        : r.firstName + " " + r.lastName
                    }}</router-link
                  >
                </td>
                <td>
                  {{
                    r.insuranceNr != null
                      ? r.insuranceNr
                      : "(" + r.insuranceID + ")"
                  }}
                </td>
                <td>{{ r.amount.toLocaleString("sv") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "economy",
  components: {
    Navbar,
  },
  computed: {
    ...mapState(["hidden", "baseUrl", "apiKey", "accesslevel"]),
  },

  data() {
    return {
      balances: [],
      selectedDays: null,
      selectedDates: [],
      insuranceNr: '120830'
    };
  },

  mounted() {
    this.getBalance();
  },

  methods: {
    getBalance() {
      window.axios
        .get(this.baseUrl + "api/get.php?type=allBalance&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.balances = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checkMarkedBalances() {
      if (this.selectedDays == "allDays") {
        this.checkAll();
      } else if (this.selectedDays == "30") {
        this.checkOlder(30);
      } else if (this.selectedDays == "40") {
        this.checkOlder(40);
      } else {
        this.uncheckAll();
      }
    },

    checkSingleBalance(balance) {
      if (balance.selected) {
        balance.selected = false;
        let selectedIndex = this.selectedDates.indexOf(balance.insuranceNr);
        console.log(selectedIndex);
        this.selectedDates.splice(selectedIndex, 1);
      } else {
        balance.selected = true;
        this.selectedDates.push(balance.insuranceNr);
      }
    },

    prepareInsurance() {
      this.insuranceNr = this.selectedDates.join(",");
      return true;
    },

    checkAll() {
      this.selectedDates = [];
      this.balances.forEach((balance) => {
        balance.selected = true;
        this.selectedDates.push(balance.insuranceNr);
      });
    },

    checkOlder(days) {
      this.selectedDates = [];
      let today = moment();
      this.balances.forEach((balance) => {
        let diff = today.diff(moment(balance.fdate), "days");
        if (Math.abs(diff) > days) {
          balance.selected = true;
          this.selectedDates.push(balance.insuranceNr);
        } else {
          balance.selected = false;
        }
      });
    },

    uncheckAll() {
      this.selectedDates = [];
      this.balances.forEach((balance) => {
        balance.selected = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.economy {
  .card {
    background: #f1f1f1;
    margin-bottom: 20px;
  }
  .table {
    td {
      .form-group {
        padding-left: 25px;
      }
      .btn {
        font-weight: 600;
      }
    }
    .reminder {
      text-align: right;
    }
  }
}
</style>
<template>
    <div class="standard w-100">
        <navbar />
        <div class="content p-4">
            <div class="row">
				<div class="col-md-10">
					<h3>Frågor</h3>
				</div>
			</div>
            <div class="row">
                <div class="col-md-5">
                    <table class="table table-striped" v-if="questions.length > 0">
                        <thead>
                            <tr>
                                <th scope="col">Fråga</th>
                                <th scope="col">Sorteringsordning</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(question, index) in questions" :key="index">
                                <td>{{ question.question }}</td>
                                <td>{{ question.orderID }}</td>
                                <td>
                                    <span class="edit" @click="editQuestion(question)">
                                        <font-awesome-icon icon="edit" />
                                    </span>
                                    <span class="external" @click="showOptions(question)">
                                        <font-awesome-icon icon="external-link-alt" />
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <template v-if="questions.length == 0">
                        <div class="alert alert-secondary" role="alert">
                            Den här värderingsformulär har inga frågor
                        </div>
                    </template>
                </div>
                <div class="vl"></div>
                <div class="col-md-6">
                    <form v-on:submit.prevent="saveQuestions()">
                        <h4 class="text-left">Ny fråga</h4>
                        <div class="form-group">
                            <input 
                                type="text" 
                                class="form-control" 
                                id="question" 
                                placeholder="Ange fråga"
                                v-model="question"
                                :class="{ 'is-invalid': submitStatus == 'ERROR' && $v.question.$error }">
                            <div class="invalid-feedback" v-if="!$v.question.required">Ange fråga</div>
                        </div>
                        <div class="form-group">
                            <input 
                                type="number" 
                                min="0"
                                class="form-control" 
                                id="order" 
                                placeholder="Sorteringsordning" 
                                v-model="order">
                            <!-- <div class="invalid-feedback" v-if="!$v.question.required">Ange fråga</div>
                            <div class="invalid-feedback" v-if="!$v.email.email">Ange giltig e-postadress</div> -->
                        </div>
                        <div class="form-group">
                            <input 
                                type="number"
                                min="0"
                                class="form-control"
                                id="point"
                                placeholder="Ange poäng"
                                v-model="point">
                        </div>
                        <div class="form-group" v-if="editable">
                            <button type="submit" class="btn btn-secondary btn-block">Uppdatera</button>
                            <button class="btn btn-warning btn-block" @click="clearFormFields()">Rensa formulär</button>
                        </div>
                        <button class="btn btn-secondary btn-block" v-if="!editable">Spara</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Navbar from "@/components/Navbar.vue";
import { required, minLength } from 'vuelidate/lib/validators'
export default {
    name: 'standard',
    data() {
        return {
            selectedIndex: null,
            selectedStandard: null,
            newstandard: {
                name: null,
                quantity: 0,
                selected: false,
                rates: []
            },
            formID: null,
            questions: [],
            questionID: null,
            question: null,
            order: null,
            point: 0,
            submitStatus: null,
            editable: false
        }
    },

    computed: {
        ...mapState(['baseUrl', 'apiKey', 'standard'])
    },

    validations: {
        question: { required },
        order: { required, minLength: minLength(0) }
    },

    components: {
        Navbar
    },

    mounted() {
        this.formID = this.$route.params.formID
        this.fetchQuestions()

        // var standardID = this.$route.params.standardID;
        // this.$store.dispatch('standard', standardID)
    },

    methods: {
        clearFormFields() {
            this.editable = false
            this.questionID = null
            this.question = null
            this.order = null
        },

        editQuestion(question) {
            this.editable = true
            this.questionID = parseInt(question.questionID)
            this.question = question.question
            this.order = parseInt(question.orderID)
        },

        showOptions(question) {
            // this.selectedIndex = index
            // this.selectedStandard = this.standard.standards[index]
            // Betygsätta

            this.$router.push({ name: 'question', params: { questionID: question.questionID }})
        },

        createStandard() {
            this.selectedIndex = null
        },

        fetchQuestions() {
            window.axios.get(this.baseUrl +'api/get.php?type=questions&formID='+ this.formID +'&key='+ this.apiKey)
            .then((response) => {
                if (response.data.status == 'ok') {
                    this.questions = response.data.data
                }
            })
        },

        saveQuestions() {
            this.$v.$touch()
			if (this.$v.$invalid) {
				this.submitStatus = 'ERROR'
			} else {
                let orderID = (this.order != null) ? this.order : 0

                var formData = new FormData()
                formData.append('question', this.question)
                formData.append('orderID', orderID)
                formData.append('formID', this.formID)
                formData.append('questionID', this.questionID)
                formData.append('questionPoints', this.point)

                if (this.editable && this.questionID != null) {
                    formData.append('id', this.questionID)
                }

                window.axios.post(this.baseUrl +'api/questions.php?key='+ this.apiKey, formData)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        this.fetchQuestions()
                        this.editable = false
                        this.questionID = null
                        this.question = null
                        this.order = null
                        this.point = 0
                        this.submitStatus = null
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.standard {
    .table {
        th {
            font-size: .9rem;
        }
        td {
            font-size: .96rem;
            .edit, .external {
                color: #4c4c4c;
                font-weight: 600;
                font-size: .9rem;
                padding-left: 10px;
            }

        }
        tr:hover {
            td {
                cursor: pointer;
                .edit {
                    color: #F1C40F;
                }
                .external {
                    color: #3498DB;
                }
            }
        }
    }
    .vl {
        border-left: 1px solid #ddd;
    }
}
</style>

<template>
  <div class="row">
    <div class="col-md-12">
      <div v-if="insurance != null && insurance.length > 0">
        <div class="table-responsive content whitebox">
          <table class="table table-striped">
            <thead>
              <tr>
                <th style="border-top: none">Produkt</th>
                <th style="border-top: none; text-align: right">Värde</th>
                <th style="border-top: none; text-align: right">Avgift</th>
                <th style="border-top: none; text-align: right">Premie</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="ins in insurance">
                <tr :key="'prod' + ins.productID">
                  <td>
                    <strong>{{ ins.name }}</strong>
                  </td>
                  <td style="text-align: right">
                    {{
                      ins.objectValue == null
                        ? ""
                        : formatValue(ins.objectValue)
                    }}
                  </td>
                  <td style="text-align: right">
                    {{
                      ins.flatFee == null
                        ? ""
                        : ins.flatFee.toLocaleString("sv")
                    }}
                  </td>
                  <td style="text-align: right">
                    {{
                      ins.premie == null ? "" : ins.premie.toLocaleString("sv")
                    }}
                  </td>
                </tr>
                <tr
                  v-for="object in ins.objects"
                  :key="'obj' + object.objectID"
                >
                  <td style="padding-left: 24px">
                    {{ object.title }}
                    {{
                      object.property != null && object.property.length > 0
                        ? "(" + object.property + ")"
                        : ""
                    }}
                  </td>
                  <td style="text-align: right">
                    {{
                      object.objectValue == null
                        ? ""
                        : formatValue(object.objectValue)
                    }}
                  </td>
                  <td style="text-align: right">
                    {{
                      object.flatFee == null
                        ? ""
                        : object.flatFee.toLocaleString("sv")
                    }}
                  </td>
                  <td style="text-align: right">
                    {{
                      object.premie == null
                        ? ""
                        : object.premie.toLocaleString("sv")
                    }}
                  </td>
                </tr>
                <template v-for="sp in ins.subProducts">
                  <tr :key="'sp' + sp.productID">
                    <td style="padding-left: 24px">
                      <strong>{{ sp.name }}</strong>
                    </td>
                    <td style="text-align: right">
                      {{
                        sp.objectValue == null
                          ? ""
                          : formatValue(sp.objectValue)
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        sp.flatFee == null
                          ? ""
                          : sp.flatFee.toLocaleString("sv")
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        sp.premie == null ? "" : sp.premie.toLocaleString("sv")
                      }}
                    </td>
                  </tr>
                  <tr
                    v-for="(object, index) in sp.objects"
                    :key="'spobj' + sp.productID + index"
                  >
                    <td style="padding-left: 48px">{{ object.title }}</td>
                    <td style="text-align: right">
                      {{
                        object.objectValue == null
                          ? ""
                          : formatValue(object.objectValue)
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        object.flatFee == null
                          ? ""
                          : object.flatFee.toLocaleString("sv")
                      }}
                    </td>
                    <td style="text-align: right">
                      {{
                        object.premie == null
                          ? ""
                          : object.premie.toLocaleString("sv")
                      }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>

<script>
import { parsePostcode, parsePhone } from "@/_helpers/parser";
import { mapState } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { required } from "vuelidate/lib/validators";
var moment = require("moment");
moment.locale("sv");

export default {
  name: "customer-insurance",
  data() {
    return {
      loading: false,
      customer: null,
      insurance: null,
      insuranceNr: null,
      message: null,
      messages: [],
      error: null,
      submitStatus: null,
      users: [],
      user: null,
      ombudsnr: "",
      popup: null,
    };
  },

  props: ["insuranceID", "customerID"],

  validations: {
    message: { required },
  },

  components: {
    PulseLoader,
  },

  computed: {
    ...mapState(["hidden", "baseUrl", "apiKey"]),
  },

  mounted() {
    this.fetchCustomer();
  },

  methods: {
    fetchCustomer() {
      this.loading = true;
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=insuranceInfo&customerID=" +
            this.customerID +
            "&insuranceID=" +
            this.insuranceID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.customer = response.data.customer;
            this.insurance = response.data.data;
            this.insuranceID = response.data.insuranceID;
            this.loading = false;
            if (this.insurance.length > 0) {
              this.insuranceNr = this.insurance[0].insuranceNr;
              this.ombudsnr = this.insurance[0].ombudsnr;
            }
          } else {
            this.$router.push({ name: "login" });
          }
        });
    },

    convertDate(date) {
      return date.format("D MMM YYYY, HH:mm");
    },

    nicePostcode(pc) {
      return parsePostcode(pc);
    },

    nicePhone(tfn) {
      return parsePhone(tfn);
    },
    formatValue(value) {
      if (value > 999999999) {
        return (
          value.toString().substring(0, 6) + "-" + value.toString().substring(6)
        );
      } else {
        return value.toLocaleString("sv");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap;
}
.customer-show {
  button {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f1f1f1;
    padding: 10px 15px;
    border-radius: 5px;
    .content {
      margin-left: 10px;
      .name {
        font-weight: 600;
      }
      address,
      .contact-info {
        font-size: 14px;
        margin: 0;
      }
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
  .events {
    .event {
      padding: 10px 15px;
      background: #f7f7f7;
      border-radius: 5px;
      .event-text {
        // font-weight: 600;
        font-size: 16px;
        // letter-spacing: 1px;
      }
      .author {
        font-size: 12px;
        font-weight: 600;
        color: #797979;
        // letter-spacing: 0.05rem;
        margin: 0;
        span:not(:last-child) {
          padding-right: 8px;
        }
        svg {
          font-size: 14px;
        }
      }
      .separator {
        color: #000;
      }
    }
  }
}
</style>

<template>
  <div class="options">
    <div class="row">
      <div class="col-md-4">
        <template v-if="selectedProducts.length > 0">
          <div class="card" v-for="(sp, parentKey) in selectedProducts" :key="parentKey">
            <div class="card-body" v-if="sp.children && sp.children.length > 0">
              <span class="title">{{ sp.name }}</span>
            </div>
            <div class="content">
              <ul v-if="sp.children && sp.children.length > 0">
                <li v-for="(p, productKey) in sp.children" :key="productKey" @click="(status === 'draft') ? handleAddProduct(p) : null"
>
                  <span>{{ p.name }} {{ isSubProductSelected(sp, p)}}</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
      <div class="col-md-8">
        <template v-if="selectedProducts.length > 0">
          <div class="productlist" v-for="(sp, spKey) in selectedProducts" :key="spKey">
            <div class="card list" @click="(status === 'draft') ? deleteSelectedProduct(sp) : null">
              <div class="card-body">
                <span class="parent">{{ sp.name }}</span>
              </div>
            </div>
            <template v-for="(p, pKey) in sp.subProducts">
              <div class="card sub" :key="pKey" @click="(status === 'draft') ? deleteSelectedProduct(p) : null">
                <div class="card-sub">
                  <span>{{ p.name }}</span>
                </div>
              </div>
            </template>
          </div>
        </template>
        <div class="alert alert-primary" role="alert" v-else>
          <p>Valda produkter visas här</p>
        </div>
        <!-- {{ selectedProducts }} -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "options",
  props: ["insuranceid","status"],
  data() {
    return {};
  },

  computed: {
    ...mapState(["baseUrl", "apiKey", "selectedProducts"]),
  },

  methods: {
    handleAddProduct(product) {
      var formData = new FormData();
      formData.append("insuranceID", this.insuranceID);
      formData.append("productID", product.productID);
      window.axios
        .get(
          this.baseUrl +
            "api/draft.php?type=addProduct&insuranceID=" +
            this.insuranceid +
            "&productID=" +
            product.productID +
            "&key=" +
            this.apiKey,
          formData
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.$store.dispatch("fetchSelectedProducts", this.insuranceid).then(() => {
                this.$store.dispatch("fetchAllObjects", this.insuranceid);
                this.$store.commit('FILTER_GUIDELINES')
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteSelectedProduct(product) {
      window.axios
        .get(
          this.baseUrl +
            "api/draft.php?type=deleteProduct&draftProductID=" +
            product.draftProductID +
            "&key=" +
            this.apiKey
        )
        .then(() => {
            this.$store.dispatch("fetchSelectedProducts", this.insuranceid).then(() => {
                this.$store.dispatch("fetchAllObjects", this.insuranceid);
                this.$store.commit('FILTER_GUIDELINES')
            });
        });
    },

    isSubProductSelected(sp) {
      sp.children.forEach((child) => {
        sp.subProducts.forEach((sPro) => {
          if (sPro.productID == child.productID) {
            child.selected = true;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.options {
  .card {
    border: 0;
    border-radius: 0;
    .card-body {
      font-weight: 600;
      background: #f1f1f1;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
    .content {
            cursor: pointer;
    }
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      padding: 15px 20px;
      &:hover {
        background: #eff7ff;
      }
      .plus {
        color: #99a3a4;
      }
      .checked {
        color: #16a085;
      }
    }
  }
  .productlist {
    .card-body {
      background: #fff;
      border-top: 1px solid #ddd;
      border-bottom: 0;
      cursor: pointer;
    }
    .sub {
      margin-left: 36px;
      border-top: 1px solid #ddd;
      padding-top: 12px;
      padding-bottom: 12px;
      cursor: pointer;
    }
    .sub:last-child {
      border-bottom: 0;
    }
  }
}
</style>
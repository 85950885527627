<template>
  <div class="main w-100">
    <navbar />
    <div class="container partners">
      <div class="header">
        <div class="logo">
          <img src="@/assets/akerboharad.png" alt="" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="form-row">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Försäkringsnummer"
                    @keyup.13="fetchCustomer()"
                    v-model="insuranceNumber"
                  />
                </div>
                <div class="col">
                  <b-form-datepicker
                    v-model="selectedDate"
                    class="mb-2"
                    placeholder="Välj datum"
                    :start-weekday="1"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    :date-disabled-fn="dateDisabled"
                    locale="sv"
                  >
                  </b-form-datepicker>
                </div>
                <div class="col">
                  <button
                    class="btn btn-success btn-block"
                    @click="fetchCustomer()"
                  >
                    Sök
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p style="width: 100%; text-align: center; padding: 16px">
          Vid oklarheter, maila
          <a href="mailto:info@akerboharad.se">info@akerboharad.se</a>.
        </p>
        <div class="col-md-12 mt-4" v-if="customer != null && searched">
          <h5 class="title">Kunduppgifter</h5>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Förnamn</th>
                <th scope="col">Efternamn</th>
                <th scope="col">Företag</th>
                <th scope="col">Adress</th>
                <th scope="col">PostNr</th>
                <th scope="col">Ort</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ customer.firstName ? customer.firstName : "-" }}</td>
                <td>{{ customer.lastName ? customer.lastName : "-" }}</td>
                <td>
                  {{
                    customer.companyName != null ? customer.companyName : "-"
                  }}
                </td>
                <td>{{ customer.street }}</td>
                <td>{{ customer.postcode }}</td>
                <td>{{ customer.city }}</td>
              </tr>
            </tbody>
          </table>
          <div class="insurance mt-4 mb-5">
            <h5 class="title">Försäkringar</h5>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Namn</th>
                  <th scope="col">Produkt</th>
                  <th scope="col">Areal</th>
                  <th scope="col">Försäkrad</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="ins in insurance">
                  <tr :key="ins.productID">
                    <td>{{ ins.name }}</td>
                    <td colspan="3"></td>
                  </tr>
                  <template v-for="object in ins.objects">
                    <tr :key="object.objectID">
                      <td>
                        {{ object.title }}
                        {{
                          object.property != null && object.property.length > 0
                            ? "(" + object.property + ")"
                            : ""
                        }}
                      </td>
                      <td>{{ ins.name }}</td>
                      <td>
                        {{
                          object.objectValue == null
                            ? ""
                            : formatValue(object.objectValue) + " ha"
                        }}
                      </td>
                      <td colspan="2"></td>
                    </tr>
                  </template>
                  <template v-for="sp in ins.subProducts">
                    <tr :key="sp.productID">
                      <td>{{ sp.name }}</td>
                      <td>{{ ins.name }}</td>
                      <td colspan="2"></td>
                    </tr>
                    <template v-for="(obj, objKey) in sp.objects">
                      <tr :key="objKey + ' ' + sp.productID">
                        <td>{{ obj.title }}</td>
                        <td>{{ sp.name }}</td>
                        <td>
                          {{
                            obj.objectValue == null ||
                            obj.objectValue > 99999999
                              ? ""
                              : formatValue(obj.objectValue) + " ha"
                          }}
                        </td>
                        <td>
                          {{
                            obj.objectValue != null &&
                            obj.objectValue > 99999999
                              ? formatValue(obj.objectValue)
                              : ""
                          }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="insurance mt-4 mb-5" v-if="customer != null && searched">
          <h5 class="title">Rådgivning</h5>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Rådgivning</th>
                <th scope="col">Produkt</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(gl, index) in guidelines" :key="'gl' + index">
                <td>{{ gl.guideline }}</td>
                <td>{{ gl.product }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-12 mt-3" v-if="searched && customer == null">
        <div class="alert alert-secondary" role="alert">
          <p class="m-0">Ingen hittades</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "partners",
  data() {
    return {
      selectedDate: moment().format("YYYY-MM-DD"),
      startDate: "2020-01-01",
      insuranceNumber: null,
      customer: null,
      searched: false,
      insurance: null,
      guidelines: [],
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey", "accesslevel"]),

    // Anticimex ska du bara visa försäkringarna med name "Villa förstarisk", "Villa fullvärde", "Fritidshus förstarisk" och "Fritidshus fullvärde"
    // Products: 5-"Villa förstarisk", 6-"Villa fullvärde", 2-"Fritidshus förstarisk", 3-"Fritidshus fullvärde"
    anticimexInsurances() {
      return this.insurance.filter((ins) => ins);
    },

    // Länsförsäkringar ska du bara visa försäkringarna med name "Hemförsäkring", "Tur & Retur - utökat reseskydd", "Fritidshus förstarisk", "Fritidshus fullvärde", "Lantbruksförsäkring", "SkogsBas", "SkogsMer", "SkogsMax"
    // Products: 4-"Hemförsäkring", 2-"Fritidshus förstarisk", 3-"Fritidshus fullvärde", 17-"Lantbruksförsäkring"
    // Sup products: "Tur & Retur - utökat reseskydd", 18-"SkogsBas", 19-"SkogsMer", 20-"SkogsMax"
    lfInsurances() {
      return this.insurance.filter((ins) => ins.productID == 17);
    },
  },

  components: {
    Navbar,
  },

  methods: {
    onContext(ctx) {
      // this.currentWeek = moment(ctx.selectedYMD).isoWeek();
      console.log(ctx);
    },

    dateDisabled(ymd, date) {
      return moment(date).isBefore(this.startDate);
    },

    fetchGuidelines() {
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=partnerGuidelines&insuranceNr=" +
            this.insuranceNumber +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.guidelines = response.data.data;
          }
        });
    },

    // api:et getCustomers.php?search=123456
    fetchCustomer() {
      console.log(this.selectedDate);
      this.searched = false;
      let insuranceNumber =
        this.insuranceNumber != null && this.insuranceNumber != ""
          ? this.insuranceNumber.length
          : 0;
      if (
        this.insuranceNumber != null &&
        this.insuranceNumber != "" &&
        insuranceNumber >= 6
      ) {
        window.axios
          .get(
            this.baseUrl +
              "api/getCustomers.php?search=" +
              this.insuranceNumber +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            this.searched = true;
            if (
              response.data.status == "ok" &&
              response.data.customers.length > 0
            ) {
              this.customer = response.data.customers[0];
              this.insuranceInfo();
              this.fetchGuidelines();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    // get.php?type=insuranceInfo&insuranceID=&date=
    insuranceInfo() {
      if (this.customer) {
        let insuranceNr = this.customer.insuranceNr;
        window.axios
          .get(
            this.baseUrl +
              "api/get.php?type=partnerInsuranceInfo&insuranceID=" +
              insuranceNr +
              "&date=" +
              this.selectedDate +
              "&key=" +
              this.apiKey
          )
          .then((response) => {
            console.log(response);
            if (response.data.status == "ok") {
              this.insurance = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    formatValue(value) {
      if (value > 999999999) {
        return (
          value.toString().substring(0, 6) + "-" + value.toString().substring(6)
        );
      } else {
        return value.toLocaleString("sv");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.partners {
  .header {
    text-align: center;
    padding: 50px 0;
    h3 {
      text-transform: uppercase;
      font-weight: 600;
      margin-top: 30px;
    }
  }
  .card {
    background: #f1f1f1;
    button {
      font-weight: 600;
    }
  }
  .title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
  }
}
</style>
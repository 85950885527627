<template>
  <div class="sjalvrisk mb-3">
    <div class="boxheadline">
      <h5>Självrisk</h5>
    </div>
    <div class="content p-3">
      <div class="row">
        <div :class="{'col-md-12': !large, 'col-md-7': large}">
          <div class="alert alert-success" role="alert" v-if="message !== null">
            {{ message }}
          </div>
          <textarea
            class="form-control"
            placeholder="Skriv meddelande"
            v-model="srText"
            id="sr"
            rows="4"
          ></textarea>
        </div>
        <div class="col-md-12 mt-3">
          <div class="row">
            <div :class="{'col-md-8': !large, 'col-md-4': large}">
              <b-form-datepicker
                v-model="srDate"
                placeholder="Välj datum"
                :start-weekday="1"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="sv"
              >
              </b-form-datepicker>
            </div>
            <div class="col-md-3" :class="{'col-md-4': !large, 'col-md-3': large}">
              <button
                type="button"
                class="btn btn-block btnLargeGr"
                @click="saveSr()"
              >
                Spara
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from 'vuex';
export default {
  props: ['message', 'large'],
  data() {
    return {
      srSaved: null,
      srDate: moment().format("YYYY-MM-DD"),
    };
  },

  computed: {
    ...mapState(["sr", "insuranceInfo"]),
    srText: {
      get() {
        return this.srSaved == null ? this.sr : this.srSaved;
      },
      set(newValue) {
        this.srSaved = newValue;
      },
    },
  },

  methods: {
    saveSr() {
      this.$emit('onClick', {srText: this.srText, srDate: this.srDate})
    }
  }
};
</script>

<style lang="scss" scoped>
.sjalvrisk {
  background: #fff;
}
</style>
<template>
    <div class="categories">
        <div class="row">
            <div class="col-md-8">
                <h5>Kategori </h5>
            </div>
        </div>
        <template v-if="categories.length > 0">
            <div class="card" v-for="(category, index) in categories" :key="index" :class="{ 'last': index == categories.length -1 }">
                <div class="card-body">
                    <div class="card-content">
                        <b-avatar variant="info" :src="imageAssets(category.iconfile)"></b-avatar>
                        <router-link :to="'/products/category/' + category.categoryID">{{ category.name }}</router-link>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="categories.length == 0">
            <div class="alert alert-secondary" role="alert">Inga kategorier</div>
        </template>

        <div class="modal">
			<b-modal id="modal-1" size="lg" title="Ny kategori"  v-model="showModal">
				<div class="alert alert-success" role="alert" v-if="submitStatus == 'OK' && successMessage != null">
					{{ successMessage }}
				</div>
				<div class="form-group">
					<input
						type="text"
						class="form-control"
						id="street"
						placeholder="Namn"
						v-model.trim="$v.categoryName.$model"
						:class="{ 'is-invalid': submitStatus == 'ERROR' && !$v.categoryName.required }"
					/>
					<div class="invalid-feedback" v-if="!$v.categoryName.required">Ange namn</div>
				</div>
				<div class="form-group">
					<input
						type="text"
						class="form-control"
						id="iconfile"
						placeholder="URL för ikon"
						v-model="categoryIconfile"
					/>
				</div>
				<template v-slot:modal-footer="{ cancel }">
					<b-button variant="secondary" @click="cancel()">Avbryt</b-button>
					<b-button variant="success" @click="performOperation()" :disabled="submitStatus === 'PENDING'">Spara</b-button>
				</template>
			</b-modal>
		</div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
export default {
    name: 'categories',
    data() {
        return {
			showModal: false,
			categoryID: null,
			categoryName: null,
			categoryIconfile: null,
			operation: null,
			submitStatus: null,
			successMessage: null
        }
	},
	
	computed: {
		...mapState(['baseUrl', 'apiKey', 'categories'])
	},

    validations: {
		categoryName: {
			required
		}
	},

	mounted() {
		this.$store.dispatch('fetchSubProducts')
	},

    methods: {
        imageAssets(url) {
			if(url == null || url == '') {
				return require('@/assets/noimage.png')
			} else {
				return require('@/assets/' + url)
			}
		},
		
		performOperation() {
			if(this.operation == 'CREATE') {
				this.saveCategory()
			} else if(this.operation == 'UPDATE') {
				this.updateCategory()
			}
		},
        
        addCategory() {
			this.operation = 'CREATE'
			this.categoryID = null
			this.categoryName = null
			this.categoryIconfile = null
			this.showModal = !this.showModal
			this.successMessage = null
		},

		saveCategory() {
			this.$v.$touch()
			if (this.$v.$invalid) {
				this.submitStatus = 'ERROR'
			} else {
				var formData = new FormData()
				formData.append('name', this.categoryName)
				formData.append('iconfile', this.categoryIconfile)
				axios.post(this.baseUrl + 'api/category.php?key=' + this.apiKey, formData)
					.then((response) => {
						console.log(response.data)
					})
					.catch((error) => {
						console.log(error)
					})
				this.categoryName = null
				this.submitStatus = 'OK'
				this.showModal = false
			}
		},

		editCategory(category) {
			this.operation = 'UPDATE'
			this.categoryID = category.categoryID
			this.categoryName = category.name
			this.categoryIconfile = category.iconfile
			this.showModal = !this.showModal
			this.successMessage = null
		},

		updateCategory() {
			this.$v.$touch
			if(this.$v.$invalid) {
				this.submitStatus = 'ERROR'
			} else {
				var formData = new FormData()
				formData.append('id', this.categoryID)
				formData.append('name', this.categoryName)
				formData.append('iconfile', this.categoryIconfile)
				axios.post(this.baseUrl + 'api/category.php?key=' + this.apiKey, formData)
					.then((response) => {
						if(response.data.status == 'ok') {
							this.$store.dispatch('fetchCategories')
							this.categoryID = null
							this.categoryName = null
							this.submitStatus = 'OK'
							this.successMessage = 'Kategorin har uppdaterats'
						}
					})
					.catch((error) => {
						console.log(error)
					})
			}
		}
    }
}
</script>

<style lang="scss" scoped>
.categories {
    button {
        font-weight: 600;
    }
	.card {
		border-radius: 0;
        &:not(:last-child) {
            border-bottom: 0;
        }
		&:hover {
			background: #f1f1f1;
		}
		.card-body {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.card-content {
				display: flex;
				align-items: center;
				.b-avatar {
					min-width: 8%;
				}
				a {
					font-size: 15px;
					font-weight: 600;
					color: #636363;
					max-width: 92%;
					// margin-left: 10px;
					padding-left: 10px;
					&:hover {
						color: #17a2b8;
					}
				}
			}
			.actions {
				width: 150px;
				text-align: right;
				span {
					cursor: pointer;
					svg {
						margin-left: 15px	;
					}
				}
				span.delete {
					color: #E74C3C;
				}
			}
		}
	}
    .card.last {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);;
    }
}
</style>

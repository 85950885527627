<template>
  <div class="staff w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-8">
          <h3>Inställningar</h3>
          <h6>Byt lösenord</h6>
          <div
            class="alert alert-success"
            role="alert"
            v-if="submitStatus == 'OK' && successMessage != null"
          >
            {{ successMessage }}
          </div>
          <div
            class="alert alert-danger"
            role="alert"
            v-if="submitStatus == 'ERROR' && errorMessage != null"
          >
            {{ errorMessage }}
          </div>
          <div class="form-group">
            <input
              type="password"
              class="form-control"
              id="password"
              placeholder="Nuvarande lösenord"
              v-model="$v.password.$model"
              :class="{
                'is-invalid': submitStatus == 'ERROR' && !$v.password.required,
              }"
            />
            <div class="invalid-feedback" v-if="!$v.password.required">
              Ange nuvarande lösenord
            </div>
          </div>
          <div class="form-group">
            <input
              type="password"
              class="form-control"
              id="new-password"
              placeholder="Nytt lösenord"
              v-model="$v.newPassword.$model"
              :class="{
                'is-invalid':
                  submitStatus == 'ERROR' && !$v.newPassword.required,
              }"
            />
            <div class="invalid-feedback" v-if="!$v.newPassword.required">
              Ange nytt lösenord
            </div>
          </div>
          <div class="form-group">
            <input
              @keydown.13="changePassword"
              type="password"
              class="form-control"
              id="confirm-password"
              placeholder="Bekräfta nytt lösenord"
              v-model="$v.confirmPassword.$model"
              :class="{
                'is-invalid':
                  submitStatus == 'ERROR' && !$v.confirmPassword.required,
              }"
            />
            <div class="invalid-feedback" v-if="!$v.confirmPassword.required">
              Bekräfta lösenord
            </div>
          </div>
          <button
            type="button"
            class="btn btn-secondary btn-lg btn-block btnSmGr"
            @click="changePassword"
          >
            Spara
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "settings",
  data() {
    return {
      password: null,
      newPassword: null,
      confirmPassword: null,
      submitStatus: null,
      successMessage: null,
      errorMessage: null,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  validations: {
    password: {
      required,
    },

    newPassword: {
      required,
    },

    confirmPassword: {
      required,
    },
  },

  components: {
    Navbar,
  },

  methods: {
    changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        var formData = new FormData();
        formData.append("pass", this.password);
        formData.append("newpass", this.newPassword);
        formData.append("newpass2", this.confirmPassword);
        window.axios
          .post(this.baseUrl + "api/settings.php?key=" + this.apiKey, formData)
          .then((response) => {
            if (response.data.status == "ok") {
              this.password = null;
              this.newPassword = null;
              this.confirmPassword = null;
              this.submitStatus = "OK";
              this.successMessage = "Lösenord uppdaterat";
            } else {
              this.submitStatus = "ERROR";
              this.successMessage = null;
              this.errorMessage = response.data.error;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("logout");
            this.$router.push({ name: "login" });
          });
      }
    },
  },
};
</script>
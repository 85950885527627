<template>
    <div class="standards">
        <div class="row">
            <div class="col-md-8">
                <h5>Värderingsformulär</h5>
            </div>
            <div class="col-md-4 text-right">
                <button class="btn btn-info mb-2 btn-sm btnLargeGr" @click="addStandard()">
                    <font-awesome-icon icon="plus-circle" /> Nytt formulär
                </button>
            </div>
        </div>
        <template v-if="standards.length > 0">
            <div class="card" v-for="(standard, index) in standards" :key="index" :class="{ 'last': index == standards.length - 1 }">
                <div class="card-body">
                    <router-link :to="'/standard/' + + standard.formID">{{ standard.formName }}</router-link>
                    <span class="edit" @click="editStandard(standard)">
                        <font-awesome-icon icon="edit" />
                    </span>
                </div>
            </div>
        </template>
        <template v-if="standards.length == 0">
            <div class="alert alert-secondary" role="alert">
                Ingen värderingsformulär hittades
            </div>
        </template>

        <div class="modal">
			<b-modal id="modal-1" size="lg" title="Lägg formulär"  v-model="showModal">
				<div class="form-group">
					<input
						type="text"
						class="form-control"
						id="street"
						placeholder="Formulärnamn"
						v-model.trim="$v.formName.$model"
						:class="{ 'is-invalid': submitStatus == 'ERROR' && !$v.formName.required }"
					/>
					<div class="invalid-feedback" v-if="!$v.formName.required">Ange kategori namn</div>
				</div>
				<template v-slot:modal-footer="{ cancel }">
					<b-button variant="secondary" @click="cancel()">Avbryt</b-button>
					<b-button variant="success" @click="saveStandard()" :disabled="submitStatus === 'PENDING'">
                        <span v-if="!editable">Spara</span>
                        <span v-if="editable">Updatera</span>
                    </b-button>
				</template>
			</b-modal>
		</div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default {
    name: 'standards',
    props: {
        standards: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showModal: false,
			formName: null,
            submitStatus: null,
            editable: false,
            formID: null
        }
    },

    computed: {
        ...mapState(['baseUrl', 'apiKey'])
    },

    validations: {
		formName: {
			required
		}
    },
    
    mounted() {
        this.fetchStandards()
    },

    methods: {
        addStandard() {
            this.editable = false
            this.formID = null
            this.formName = null
            this.showModal = true
        },

        editStandard(standard) {
            this.editable = true
            this.formID = parseInt(standard.formID)
            this.formName = standard.formName
            this.showModal = true
        },

        fetchStandards() {
            window.axios.get(this.baseUrl +'api/get.php?type=forms&key='+ this.apiKey)
            .then((response) => {
                if(response.data.status == 'ok') {
                    this.$store.commit('STANDARDS', response.data.data)
                } else {
                    this.$store.dispatch('logout')
					location.reload(true)
                }
            })
            .catch((error) => {
                console.log(error)
            })
        },

        saveStandard() {
            this.$v.$touch()
			if (this.$v.$invalid) {
				this.submitStatus = 'ERROR'
			} else {
                var formData = new FormData()
                formData.append('formName', this.formName)

                if(this.editable && this.formID != null) {
                    formData.append('id', this.formID)
                }
                
                window.axios.post(this.baseUrl +'api/forms.php?key='+ this.apiKey, formData)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        this.fetchStandards()
                        this.editable = false
                        this.formID = null
                        this.formName = null
                        this.submitStatus = 'OK'
                        this.showModal = false
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
			}
        }
    }
}
</script>

<style lang="scss" scoped>
.standards {
    button {
        font-weight: 600;
    }
    .card {
        border-radius: 0;
        &:hover {
            background: #f1f1f1;
        }
        &:not(:last-child) {
            border-bottom: 0;
        }
        .card-body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
                margin: 0;
                padding: 0;
                font-size: 15px;
                font-weight: 600;
                color: #636363;
                &:hover {
                    color: #17a2b8;
                }
            }
            .edit {
                color: #4c4c4c;
                font-weight: 600;
                font-size: .9rem;
                padding-left: 10px;

                &:hover {
                    color: #F1C40F;
                    cursor: pointer;
                }
            }
        }
    }
    .card.last {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);;
    }
}
</style>

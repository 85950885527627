<template>
  <div class="changed-insurances w-100">
    <navbar />
    <div class="content p-4">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Kund"
                v-model="customer"
                @keyup="onChange"
              />
            </div>
            <div class="col">
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Ändrat av"
                v-model="user"
                @keyup="onChange"
              />
            </div>
            <div class="col">
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Fält"
                v-model="valueType"
                @keyup="onChange"
              />
            </div>
            <div class="col">
              <b-form-datepicker
                v-model="selectedDate"
                class="mb-2 form-control-lg"
                :start-weekday="1"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="sv"
                :date-disabled-fn="dateDisabled"
                @input="onChange"
              >
              </b-form-datepicker>
            </div>
          </div>
        </div>
      </div>
			<div class="row">
				<div class="col-md-12">
					<table class="table table-striped" v-if="insurances.length > 0">
						<thead>
							<tr>
								<th scope="col">Tidpunkt</th>
								<th scope="col">Kund</th>
								<th scope="col">Ändrat av</th>
								<th scope="col">Produkt</th>
								<th scope="col">Fält</th>
								<th scope="col">Från</th>
								<th scope="col">Till</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(insurance, insuranceKey) in insurances" :key="insuranceKey">
								<td>{{ insurance.ts }}</td>
								<td>
									<router-link
                    :to="{
                      name: 'customer-show',
                      params: { id: insurance.id },
                    }"
                  >
                    {{
                      insurance.companyName
                        ? insurance.companyName
                        : insurance.firstName + " " + object.lastName
                    }}
                  </router-link>
								</td>
								<td>{{ insurance.realname }}</td>
								<td>{{ insurance.name }}</td>
								<td>{{ insurance.valueType }}</td>
								<td>{{ insurance.oldValue }}</td>
								<td>{{ insurance.newValue }}</td>
							</tr>
						</tbody>
					</table>
					<div class="alert alert-secondary" role="alert" v-else>
            <p class="m-0">Ingen försäkring hitaddes</p>
          </div>
				</div>
			</div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import moment from "moment";
import { mapState } from 'vuex';
export default {
  name: "ChangedInsurances",
  data() {
    return {
      insurances: [],
			customer: '',
			user: '',
			valueType: '',
      selectedDate: moment().format("YYYY-MM-DD"),
    };
  },

	computed: {
		...mapState(['baseUrl', 'apiKey'])
	},

  components: {
    Navbar,
  },

	mounted() {
		let query = this.$route.query;
    this.customer = query.customer ? query.customer : "";
    this.user = query.user ? query.user : "";
    this.valueType = query.value ? query.value : "";
    this.selectedDate = query.date ? query.date : moment().format("YYYY-MM-DD");

		this.fetchChangedInsurances();
	},

  methods: {
    dateDisabled(ymd, date) {
      let toDay = moment();
      return moment(date).isAfter(toDay);
    },

		onChange() {
			this.$router.replace({
        query: {
          customer: this.customer,
          user: this.user,
          value: this.valueType,
          date: this.selectedDate,
        },
      });
			this.fetchChangedInsurances();
		},

    // API:et api/getProductChangelog.php?search=&user=&valueType=&date=
    fetchChangedInsurances() {
			let selectedDate = (this.customer != '') ? '' : this.selectedDate;
			window.axios.get(this.baseUrl+'api/getProductChangelog.php?search='+encodeURIComponent(this.customer)+'&user='+encodeURIComponent(this.user)+'&valueType='+encodeURIComponent(this.valueType)+'&date='+encodeURIComponent(selectedDate)+'&key='+this.apiKey)
			.then((response) => {
				console.log(response)
				if (response.data.status == 'ok') {
					this.insurances = response.data.data;
				}
			})
			.catch((error) => {
				console.log(error)
			})
		},
  },
};
</script>

<style lang="scss" scoped>
.changed-insurances {
	.card {
    background: #f1f1f1;
  }
  .table {
    td {
      font-size: 0.96rem;
      a {
        color: #1A5276;
        font-weight: 600;
        font-size: 0.9rem;
        &:hover {
          color: #7F8C8D;
        }
      }
    }
  }
}
</style>
<template>
  <div class="damages p-3 mt-3" v-if="damages.length > 0">
    <div class="table-responsive content">
      <table class="table">
        <thead>
          <tr>
            <th style="border-top: none">Skadenr</th>
            <th style="border-top: none">Försäkring</th>
            <th style="border-top: none">Datum</th>
            <th style="border-top: none">Kategori</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(dmg, index) in damages" :key="index">
            <td>
              <button type="button" class="btn btn-linK" @click="$emit('navigate', dmg)">
                {{ dmg.damageNr }}
              </button>
            </td>
            <td>
              <button type="button" class="btn btn-linK" @click="$emit('show', dmg)">
                {{ dmg.insuranceNr }}
              </button>
            </td>
            <td>{{ dmg.damageDate }}</td>
            <td>{{ dmg.category }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    damages: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.damages {
    background: #fff;
  .btn {
    color: #5AA196;
    font-weight: 600;
    &:hover {
        color: inherit;
    }
    &:focus {
        outline: 0;
        box-shadow: none;
    }
  }
}
</style>
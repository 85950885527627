<template>
	<div id="app">
		<sidebar />
		<router-view/>
	</div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
export default {
	components: {
		Sidebar
	},
}
</script>

<style lang="scss">
// @import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import './assets/style.scss';
@import './assets/style-createInsurance.scss';
</style>

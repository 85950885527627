<template>
  <div class="insurance-mail">
    <div class="container">
      <div class="row">
        <div class="col-md-8 insurance">
          <div class="object">
            <table class="table table-borderless none">
              <thead>
                <tr>
                  <th>Försäkringsobjekt</th>
                  <th>Försäkringbelopp</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="premium in premiums">
                  <tr
                    :key="premium.draftProductID"
                    v-if="premium.parentID == null"
                  >
                    <td>{{ premium.name }}</td>
                    <td>&nbsp;</td>
                  </tr>
                  <template v-if="premium.objects.length > 0">
                    <tr
                      class="product-child"
                      v-for="object in premium.objects"
                      :key="object.objectID"
                    >
                      <td class="productName" v-if="object.title != null">
                        {{ object.title }} {{ getTerm(object.termID) }}
                      </td>
                      <td class="productName" v-else>(tömt)</td>
                      <td v-if="object.premie">
                        {{ formatValue(object.objectValue) }} SEK
                      </td>
                      <td v-else>0 SEK</td>
                    </tr>
                  </template>
                  <template v-if="premium.subProducts.length > 0">
                    <template v-for="product in premium.subProducts">
                      <tr class="product-child" :key="product.productID">
                        <td class="productName">{{ product.name }}</td>
                        <td>&nbsp;</td>
                      </tr>
                      <template v-for="obj in product.objects">
                        <tr class="object-child" :key="obj.objectID">
                          <td class="objectTitle">
                            {{ obj.title }} {{ getTerm(obj.termID) }}
                          </td>
                          <td>
                            {{ formatValue(obj.objectValue) }}
                            {{ obj.objectValue > 999999999 ? "" : "SEK" }}
                          </td>
                        </tr>
                      </template>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <div class="terms mt-3" v-if="status !== 'draft'">
            <h4>Villkor</h4>
            <ul>
              <li v-for="(obj, objKey) in objects" :key="objKey">
                <div class="form-row">
                  <div class="col">
                    <p class="title">
                      {{
                        obj.title == null || obj.title == ""
                          ? "(Inget namn)"
                          : obj.title
                      }}
                    </p>
                  </div>
                  <div class="col">
                    <p class="product-name">
                      {{ obj.productName }} {{ obj.dragging }}
                    </p>
                  </div>
                  <div class="col">
                    <template v-if="terms.length > 0">
                      <p v-for="(term, index) in terms" :key="index">
                        <span v-if="term.termID == obj.termID">{{
                          term.term
                        }}</span>
                      </p>
                    </template>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 premie">
          <div class="row">
            <div class="col-md-12">
              <div class="payment">
                <h5>Premie och betalning</h5>
                <table class="table table-borderless none">
                  <tbody>
                    <tr>
                      <td>Helårspremie</td>
                      <td>{{ totalPremie.toLocaleString() }} SEK</td>
                    </tr>
                    <tr>
                      <td>Återbäring</td>
                      <td>0 SEK</td>
                    </tr>
                    <tr>
                      <td>Kundbonus</td>
                      <td>0 SEK</td>
                    </tr>
                    <tr>
                      <td>Att betala</td>
                      <td>{{ totalPremie.toLocaleString() }} SEK</td>
                    </tr>
                  </tbody>
                </table>
                <div class="info">
                  <p></p>
                </div>
              </div>
            </div>
            <p class="divider">&nbsp;</p>
            <div class="col-md-12 sales">
              <div class="sales-info">
                <h5>Självrisker</h5>
                <p>
                  {{
                    insurances != null && insurances.length > 0
                      ? insurances[0].sr
                      : ""
                  }}
                </p>
              </div>
            </div>
            <div class="col-md-12">
              <p class="divider">&nbsp;</p>
              <p>
                <strong>Status: </strong>
                <span class="badge badge-secondary" v-if="status === 'draft'"
                  >Utkast</span
                >
                <span class="badge badge-info" v-if="status === 'proposal'"
                  >Offert</span
                >
                <span class="badge badge-success" v-if="status === 'approved'"
                  >Godkänd</span
                >
              </p>
              <button
                v-if="status === 'proposal'"
                type="button"
                class="btn btn-success btn-block"
                @click="approve()"
              >
                <font-awesome-icon icon="check-double" /> Godkänn försäkring
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "insurance-mail",
  props: ["insuranceid", "premiums", "status"],
  data() {
    return {
      approvalDate: moment().format("YYYY-MM-DD"),
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey", "insurances", "terms"]),

    objects() {
      var objs = [];
      if (this.insurances.length > 0) {
        this.insurances.map((insurance) => {
          insurance.objects.map((obj) => {
            obj.productName = insurance.name;
            obj.productID = insurance.productID;
            objs.push(obj);
          });
        });
      }
      return objs;
    },

    totalDraftPremium: function () {
      var sum = 0;
      for (let i = 0; i < this.premiums.length; i++) {
        let p = this.premiums[i];
        if (p.premie != null) {
          sum += p.premie;
        } else if (p.flatFee != null) {
          sum += p.flatFee;
        }
      }
      return sum;
    },

    totalPremie: function () {
      var sum = 0;
      for (var i = 0; i < this.premiums.length; i++) {
        var p = this.premiums[i];
        if (p.premie != null) {
          sum += p.premie;
        } else if (p.flatFee != null) {
          sum += p.flatFee;
        }
        for (var j = 0; j < p.objects.length; j++) {
          var q = p.objects[j];
          if (q.premie != null) {
            sum += q.premie * (q.risk / 100);
          } else if (q.flatFee != null) {
            sum += q.flatFee;
          }
        }
        for (let x = 0; x < p.subProducts.length; x++) {
          var product = p.subProducts[x];
          if (product.premie != null) {
            sum += product.premie;
          } else if (product.flatFee != null) {
            sum += product.flatFee;
          }
          for (let y = 0; y < product.objects.length; y++) {
            var object = product.objects[y];
            if (object.premie != null) {
              sum += object.premie * (object.risk / 100);
            } else if (object.flatFee != null) {
              sum += object.flatFee;
            }
          }
        }
      }
      return sum;
    },
  },

  methods: {
    formatValue(value) {
      if (value > 999999999) {
        return (
          value.toString().substring(0, 6) + "-" + value.toString().substring(6)
        );
      } else {
        return value.toLocaleString("sv");
      }
    },
    getTerm(termID) {
      let term = this.terms.find((term) => term.termID == termID);
      return term ? "(" + term.term + ")" : "(tom)";
    },

    approve() {
      window.axios
        .get(
          this.baseUrl +
            "api/draft.php?type=confirm&insuranceID=" +
            this.insuranceid +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.$emit("refresh", true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-mail {
  .insurance {
    .object {
      border: 1px solid #808b96;
      padding: 10px;
      table.table-borderless {
        th {
          color: #000;
        }
        td {
          padding: 5px;
        }
        tr .productName {
          padding-left: 30px;
        }
        .parent,
        .child {
          width: 100%;
          padding-left: 100px;
          li {
            border: 0;
            .content {
              padding-left: 80px;
            }
            .empty {
              width: 105px;
            }
          }
        }
        .parent .has-child {
          display: flex;
        }
        .child {
          li {
            display: flex;
            justify-content: space-between;
          }
        }
        .product-child {
          margin-left: 30px;
          .object-child {
            margin-left: 30px;
          }
        }
      }
    }
    .terms {
      border: 1px solid #808b96;
      h4 {
        border-bottom: 1px solid #808b96;
        padding: 15px;
      }

      ul {
        margin: 0 15px;
        li {
          padding: 10px 15px;
          p {
            margin: 0;
          }
          &:hover {
            background: #f1f1f1;
            p {
              font-weight: 600;
              color: #333;
            }
          }
        }
      }
    }
  }
  .premie {
    .payment {
      border: 1px solid #808b96;
      padding: 10px;
      table {
        td {
          padding: 5px;
        }
      }
    }
    .divider {
      margin: 5px 0;
    }
    .sales {
      margin-top: 10px;
      .sales-info {
        border: 1px solid #808b96;
        padding: 10px;
      }
    }
  }
}
</style>
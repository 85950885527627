var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"validity"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',[_vm._v("Försäkringsobjekt")]),_c('table',{staticClass:"table table-borderless none"},[_c('thead',[_vm._m(0),_c('tr',{staticClass:"select-all"},[_c('th'),_c('th'),_c('th',[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"Välj alla  ","start-weekday":1,"date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  },"locale":"sv"},on:{"input":function($event){return _vm.selectAllStartDates()}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('th',[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"Välj alla","start-weekday":1,"date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  },"locale":"sv","disabled":!_vm.startDate},on:{"input":function($event){return _vm.selectAllEndDates()}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)])]),_c('tbody',[_vm._l((_vm.premiums),function(premium){return [(premium.parentID == null)?_c('tr',{key:premium.draftProductID},[_c('td',[_vm._v(_vm._s(premium.name))]),_c('td'),_c('td',[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"Start datum","start-weekday":1,"date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  },"locale":"sv"},on:{"input":function($event){return _vm.validateProduct(premium)}},model:{value:(premium.startDate),callback:function ($$v) {_vm.$set(premium, "startDate", $$v)},expression:"premium.startDate"}})],1),_c('td',[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"Tills vidare","start-weekday":1,"date-format-options":{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  },"locale":"sv","disabled":!premium.startDate},on:{"input":function($event){return _vm.validateProduct(premium)}},model:{value:(premium.endDate),callback:function ($$v) {_vm.$set(premium, "endDate", $$v)},expression:"premium.endDate"}})],1)]):_vm._e(),(premium.objects.length > 0)?_vm._l((premium.objects),function(object){return _c('tr',{key:object.objectID,staticClass:"product-child"},[(object.title != null)?_c('td',{staticClass:"productName"},[_vm._v(" "+_vm._s(object.title)+" "+_vm._s(_vm.getTerm(object.termID))+" ")]):_c('td',{staticClass:"productName"},[_vm._v("(tömt)")]),(object.premie)?_c('td',[_vm._v(" "+_vm._s(object.objectValue.toLocaleString())+" SEK ")]):_c('td',[_vm._v("0 SEK")]),_c('td',[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"Start datum","start-weekday":1,"date-format-options":{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    },"locale":"sv"},on:{"input":function($event){return _vm.validateObject(object)}},model:{value:(object.startDate),callback:function ($$v) {_vm.$set(object, "startDate", $$v)},expression:"object.startDate"}})],1),_c('td',[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"Tills vidare","start-weekday":1,"date-format-options":{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    },"locale":"sv","disabled":!object.startDate},on:{"input":function($event){return _vm.validateObject(object)}},model:{value:(object.endDate),callback:function ($$v) {_vm.$set(object, "endDate", $$v)},expression:"object.endDate"}})],1)])}):_vm._e(),(premium.subProducts.length > 0)?[_vm._l((premium.subProducts),function(product){return [_c('tr',{key:product.productID,staticClass:"product-child"},[_c('td',{staticClass:"productName"},[_vm._v(_vm._s(product.name))]),_c('td'),_c('td',[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"Start datum","start-weekday":1,"date-format-options":{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      },"locale":"sv"},on:{"input":function($event){return _vm.validateProduct(product)}},model:{value:(product.startDate),callback:function ($$v) {_vm.$set(product, "startDate", $$v)},expression:"product.startDate"}})],1),_c('td',[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"Tills vidare","start-weekday":1,"date-format-options":{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      },"locale":"sv","disabled":!product.startDate},on:{"input":function($event){return _vm.validateProduct(product)}},model:{value:(product.endDate),callback:function ($$v) {_vm.$set(product, "endDate", $$v)},expression:"product.endDate"}})],1)]),_vm._l((product.objects),function(obj){return [_c('tr',{key:obj.objectID,staticClass:"object-child"},[_c('td',{staticClass:"objectTitle"},[_vm._v(" "+_vm._s(obj.title)+" "+_vm._s(_vm.getTerm(obj.termID))+" ")]),_c('td',[_vm._v(_vm._s(obj.objectValue.toLocaleString())+" SEK")]),_c('td',[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"Start datum","start-weekday":1,"date-format-options":{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        },"locale":"sv"},on:{"input":function($event){return _vm.validateObject(obj)}},model:{value:(obj.startDate),callback:function ($$v) {_vm.$set(obj, "startDate", $$v)},expression:"obj.startDate"}})],1),_c('td',[_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"placeholder":"Tills vidare","start-weekday":1,"date-format-options":{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        },"locale":"sv","disabled":!obj.startDate},on:{"input":function($event){return _vm.validateObject(obj)}},model:{value:(obj.endDate),callback:function ($$v) {_vm.$set(obj, "endDate", $$v)},expression:"obj.endDate"}})],1)])]})]})]:_vm._e()]})],2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("Försäkringsobjekt")]),_c('th',[_vm._v("Försäkringbelopp")]),_c('th',[_vm._v("Start datum")]),_c('th',[_vm._v("Slut datum")])])}]

export { render, staticRenderFns }
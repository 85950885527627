<template>
  <div class="staff w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-8">
          <h3>Systeminställningar</h3>
          <h6>Automatisk radering av gamla kunduppgifter efter {{ months }} månader</h6>
          <vue-slider
            v-model="months"
            :min="13"
            :max="60"
            :interval="1"
            :adsorb="true"
            :marks="marks"
          />
          <button
            style="margin-top: 32px;"
            type="button"
            class="btn btn-secondary"
            @click="saveSettings"
          >Spara</button>
        </div>
      </div>
      <div class="row" style="margin-top: 16px;">
        <div class="col-md-8">
          <h3>Prisbasbelopp</h3>
          <ul v-if="pbb.length > 0">
            <li
              v-for="(p, index) in pbb"
              :key="index"
            >{{ p.start }} -- {{ p.slut }}: {{ p.pbb.toLocaleString('sv') }}
            <a href="#" @click="deletePbb(p.start)">x</a></li>
          </ul>
          <h6>Nytt prisbasbelopp</h6>
          <input type="date" name="start" v-model="start" /> --
          <input type="date" name="slut" v-model="slut" />
          <br />
          <input type="number" name="pbbval" v-model="pbbval" placeholder="Prisbasbelopp" />
          <br />
          <button @click="savePbb">Spara</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { mapState } from "vuex";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
export default {
  name: "system",
  data() {
    return {
      autodelete: "0",
      months: 13,
      marks: [13, 20, 30, 40, 50, 60],
      pbb: [],
      start: null,
      slut: null,
      pbbval: null,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  components: {
    Navbar,
    VueSlider,
  },

  mounted() {
    this.getSettings();
  },

  methods: {
    savePbb() {
      var postdata = new FormData();
      postdata.append("start", this.start);
      postdata.append("slut", this.slut);
      postdata.append("pbb", this.pbbval);
      window.axios
        .post(this.baseUrl + "api/pbb.php?key=" + this.apiKey, postdata)
        .then(() => {
          this.start = null;
          this.slut = null;
          this.pbbval = null;
          this.getSettings();
        });
    },
    deletePbb(id) {
      var postdata = new FormData();
      postdata.append("id", id)
      window.axios.post(this.baseUrl + "api/pbb.php?key=" + this.apiKey, postdata).then(() => {
        this.getSettings()
      })
    },
    getSettings() {
      window.axios
        .get(
          this.baseUrl + "api/get.php?type=systemSettings&key=" + this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok" && response.data.data != null) {
            this.autodelete = response.data.data[0].autoDelete;
            this.months = this.autodelete / 30;
          }
        });
      window.axios
        .get(this.baseUrl + "api/get.php?type=pbb&key=" + this.apiKey)
        .then((response) => {
          if (response.data.status == "ok") {
            this.pbb = response.data.data;
          }
        });
    },
    saveSettings() {
      var formData = new FormData();
      formData.append("autoDelete", this.months * 30);
      window.axios
        .post(
          this.baseUrl + "api/systemsettings.php?key=" + this.apiKey,
          formData
        )
        .then((response) => {
          if (response.data.status != "ok") {
            this.submitStatus = "ERROR";
            this.successMessage = null;
            this.errorMessage = response.data.error;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
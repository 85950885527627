<template>
    <div class="total-sales">
        <div class="card">
            <div class="card-body">
                <div class="float-right btn-group">
                    <span class="ellipsis">
                        <font-awesome-icon icon="ellipsis-v" />
                    </span>
                </div>
                <h4 class="header-title mb-2">Total sales</h4>
                <pie-chart />
            </div>
        </div>
    </div>
</template>

<script>
import PieChart from '@/components/charts/PieChart.vue'

export default {
    components: {
        PieChart
    }
}
</script>

<style lang="scss" scoped>
.total-sales {
    .card {
        .ellipsis {
            font-size: 15px;
            line-height: 0;
            color: #676767;
        }
        .header-title {
            margin-bottom: .5rem;
            text-transform: uppercase;
            letter-spacing: .02em;
            font-size: .9rem;
            margin-top: 0
        }
    }
}
</style>
<template>
  <div class="premie">
    <div class="col-md-6">
      <table class="table table-striped" v-if="insurances.length > 0">
        <thead>
          <tr>
            <th scope="col">Produkt/objekt</th>
            <th scope="col" style="text-align: right">Värdering</th>
            <th scope="col" style="text-align: right">Premie</th>
            <th scope="col">Riskfaktor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, productKey) in insurances" :key="productKey">
            <td>
              <p>
                <strong>{{ product.name }}</strong>
              </p>

              <p
                v-for="(object, key) in product.objects"
                :key="key"
                style="padding-left: 24px"
              >
                {{ object.title }}
              </p>
            </td>
            <td style="text-align: right">
              <p><br /></p>
              <p v-for="(object, key) in product.objects" :key="key">
                {{
                  object.objectValue != null
                    ? formatValue(object.objectValue)
                    : 0
                }}
              </p>
            </td>
            <td style="text-align: right">
              <p>
                {{ product.premie > 0 ? product.premie.toLocaleString() : product.flatFee.toLocaleString() }}
              </p>
              <p v-for="(object, key) in product.objects" :key="key">
                <span v-if="object.premie">{{
                  (object.premie * (object.risk / 100)).toLocaleString()
                }}</span>
              </p>
            </td>
            <td>
              <p><br /></p>
              <template v-if="product.evaluationFormID >= 0">
                <input
                  :disabled="status !== 'draft'"
                  type="numeric"
                  class="form-control"
                  v-for="(object, key) in product.objects"
                  :key="key"
                  v-model="object.risk"
                  :name="object.objectID"
                  @change="status === 'draft' ? onChangeRiskfactor : null"
                />
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="card"
        style="border: 3px solid grey"
        v-if="insurances.length > 0"
      >
        <div class="card-body">
          <strong>Total premie: </strong> {{ totalPremie.toLocaleString() }}
        </div>
      </div>
      <div class="alert alert-secondary" v-if="insurances.length == 0">
        Ingen produkt eller objekt hittades
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "premie",
  props: ["insuranceid", "status"],
  data() {
    return {
      riskfactor: 100,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey", "insurances"]),
    totalPremie: function () {
      var sum = 0;
      for (var i = 0; i < this.insurances.length; i++) {
        var p = this.insurances[i];
        if (p.premie != null) {
          sum += p.premie;
        } else if (p.flatFee != null) {
          sum += p.flatFee;
        }
        for (var j = 0; j < p.objects.length; j++) {
          var q = p.objects[j];
          if (q.premie != null) {
            sum += q.premie * (q.risk / 100);
          } else if (q.flatFee != null) {
            sum += q.flatFee;
          }
        }
      }
      return sum;
    },
  },

  methods: {
    formatValue(value) {
      if (value > 999999999) {
        return (
          value.toString().substring(0, 6) + "-" + value.toString().substring(6)
        );
      } else {
        return value.toLocaleString("sv");
      }
    },
    onChangeRiskfactor(event) {
      console.log(event);
      window.axios
        .get(
          this.baseUrl +
            "api/draft.php?type=changeRisk&objectID=" +
            event.target.name +
            "&risk=" +
            event.target.value +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.$store.dispatch("fetchAllObjects", this.insuranceid);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<template>
    <div class="question w-100">
        <navbar />
        <div class="content p-4">
            <div class="row">
				<div class="col-md-10">
					<h3>Fråga alternativ</h3>
					<p class="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum unde fuga et vitae minus praesentium tempore dolor officiis. Quis harum vero necessitatibus possimus facilis adipisci temporibus ut mollitia cum ab.</p>
				</div>
			</div>
            <div class="row">
                <div class="col-md-6">
                    <table class="table table-striped" v-if="options.length > 0">
                        <thead>
                            <tr>
                                <th scope="col">Alternativ</th>
                                <th scope="col">Antal poäng</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(option, index) in options" :key="index">
                                <td>{{ option.optionName }}</td>
                                <td>{{ option.points }}</td>
                                <td>
                                    <span class="edit" @click="editOption(option)">
                                        <font-awesome-icon icon="edit" />
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <template v-if="options.length == 0">
                        <div class="alert alert-secondary" role="alert">
                            Den här frågan har inga alternativ
                        </div>
                    </template>
                </div>
                <div class="vl"></div>
                <div class="col-md-5">
                    <h4>Lägg alternativ</h4>
                    <form v-on:submit.prevent="saveOptions()">
                        <div class="form-group">
                            <input 
                                type="text" 
                                class="form-control" 
                                id="option"
                                placeholder="Fråga alternativ"
                                v-model="option"
                                :class="{ 'is-invalid': submitStatus == 'ERROR' && $v.option.$error }">
                            <div class="invalid-feedback" v-if="!$v.option.required">Du måste ange alternativ</div>
                        </div>
                        <div class="form-group">
                            <input 
                                type="text" 
                                class="form-control" 
                                id="points"
                                placeholder="Antal poäng"
                                v-model="points"
                                :class="{ 'is-invalid': submitStatus == 'ERROR' && $v.points.$error }">
                            <div class="invalid-feedback" v-if="!$v.points.required">Ange antal poäng som alternativet är värt</div>
                        </div>
                        <div class="form-group" v-if="editable">
                            <button type="submit" class="btn btn-secondary btn-block">Uppdatera</button>
                            <button class="btn btn-warning btn-block" @click="clearForm()">Rensa formulär</button>
                        </div>
                        <button class="btn btn-secondary btn-block" v-if="!editable">Spara</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Navbar from "@/components/Navbar.vue";
import { required, minLength } from 'vuelidate/lib/validators'
export default {
    name: 'question',
    data() {
        return {
            questionID: null,
            options: [],
            option: null,
            points: null,
            submitStatus: null,
            editable: false,
            optionID: null,
        }
    },

    computed: {
        ...mapState(['baseUrl', 'apiKey'])
    },

    validations: {
        option: { required },
        points: { required, minLength: minLength(0) }
    },

    components: {
        Navbar
    },

    mounted() {
        let questionID = this.$route.params.questionID
        this.questionID = parseInt(questionID)

        this.fetchOptions()
    },

    methods: {
        clearForm() {
            this.editable = false
            this.optionID = null
            this.option = null
            this.points = null
        },

        editOption(option) {
            this.editable = true
            this.optionID = parseInt(option.optionID)
            this.option = option.optionName
            this.points = parseInt(option.points)
        },

        fetchOptions() {
            window.axios.get(this.baseUrl +'api/get.php?type=options&questionID='+this.questionID+'&key='+ this.apiKey)
            .then((response) => {
                if (response.data.status == 'ok') {
                    this.options = response.data.data
                }
            })
            .catch((error) => {
                console.log(error)
            })
        },

        saveOptions() {
            this.$v.$touch()
			if (this.$v.$invalid) {
				this.submitStatus = 'ERROR'
			} else {
                let successMessage = 'Ny fråga alternativ har sparat'

                var formData = new FormData()
                formData.append('optionName', this.option)
                formData.append('points', this.points)
                formData.append('questionID', this.questionID)

                if (this.editable && this.optionID != null) {
                    formData.append('id', this.optionID)
                    successMessage = 'Fråga alternativ har uppdaterat'
                }

                window.axios.post(this.baseUrl +'api/options.php?key='+ this.apiKey, formData)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        this.fetchOptions()
                        this.editable = false
                        this.optionID = null
                        this.option = null
                        this.points = null
                        this.submitStatus = null

                        this.$swal('Meddelande', successMessage, 'success');
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.question {
    .table {
        th {
            font-size: .9rem;
        }
        td {
            font-size: .96rem;
            a {
                color: #4c4c4c;
                font-weight: 600;
                font-size: .9rem;
            }
            .edit {
                color: #4c4c4c;
                font-weight: 600;
                font-size: .9rem;
                padding-left: 10px;
            }
        }
        tr:hover {
            td  {
                cursor: pointer;
                .edit {
                    color: #F1C40F;
                }
            }
        }
    }
    .vl {
        border-left: 1px solid #ddd;
    }
}
</style>

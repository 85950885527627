<template>
  <div class="validity">
    <div class="row">
      <div class="col">
        <h3>Försäkringsobjekt</h3>
        <table class="table table-borderless none">
          <thead>
            <tr>
              <th>Försäkringsobjekt</th>
              <th>Försäkringbelopp</th>
              <th>Start datum</th>
              <th>Slut datum</th>
            </tr>
            <tr class="select-all">
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>
                  <b-form-datepicker
                    v-model="startDate"
                    placeholder="Välj alla  "
                    class="mb-2"
                    :start-weekday="1"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="sv"
                    @input="selectAllStartDates()"
                  >
                  </b-form-datepicker>
              </th>
              <th>
                  <b-form-datepicker
                    v-model="endDate"
                    placeholder="Välj alla"
                    class="mb-2"
                    :start-weekday="1"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="sv"
                    :disabled="!startDate"
                    @input="selectAllEndDates()"
                  >
                  </b-form-datepicker>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- {{ insurances }} -->
            <template v-for="premium in premiums">
              <tr :key="premium.draftProductID" v-if="premium.parentID == null">
                <td>{{ premium.name }}</td>
                <td>&nbsp;</td>
                <td>
                  <b-form-datepicker
                    v-model="premium.startDate"
                    placeholder="Start datum"
                    class="mb-2"
                    :start-weekday="1"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="sv"
                    @input="validateProduct(premium)"
                  >
                  </b-form-datepicker>
                </td>
                <td>
                  <b-form-datepicker
                    v-model="premium.endDate"
                    placeholder="Tills vidare"
                    class="mb-2"
                    :start-weekday="1"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    locale="sv"
                    :disabled="!premium.startDate"
                    @input="validateProduct(premium)"
                  >
                  </b-form-datepicker>
                </td>
              </tr>
              <template v-if="premium.objects.length > 0">
                <tr
                  class="product-child"
                  v-for="object in premium.objects"
                  :key="object.objectID"
                >
                  <td class="productName" v-if="object.title != null">
                    {{ object.title }} {{ getTerm(object.termID) }}
                  </td>
                  <td class="productName" v-else>(tömt)</td>
                  <td v-if="object.premie">
                    {{ object.objectValue.toLocaleString() }} SEK
                  </td>
                  <td v-else>0 SEK</td>
                  <td>
                    <b-form-datepicker
                    v-model="object.startDate"
                      placeholder="Start datum"
                      class="mb-2"
                      :start-weekday="1"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      locale="sv"
                      @input="validateObject(object)"
                    >
                    </b-form-datepicker>
                  </td>
                  <td>
                    <b-form-datepicker
                      v-model="object.endDate"
                      placeholder="Tills vidare"
                      class="mb-2"
                      :start-weekday="1"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      locale="sv"
                      :disabled="!object.startDate"
                      @input="validateObject(object)"
                    >
                    </b-form-datepicker>
                  </td>
                </tr>
              </template>
              <template v-if="premium.subProducts.length > 0">
                <template v-for="product in premium.subProducts">
                  <tr class="product-child" :key="product.productID">
                    <td class="productName">{{ product.name }}</td>
                    <td>&nbsp;</td>
                    <td>
                      <b-form-datepicker
                        v-model="product.startDate"
                        placeholder="Start datum"
                        class="mb-2"
                        :start-weekday="1"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        locale="sv"
                        @input="validateProduct(product)"
                      >
                      </b-form-datepicker>
                    </td>
                    <td>
                      <b-form-datepicker
                        v-model="product.endDate"
                        placeholder="Tills vidare"
                        class="mb-2"
                        :start-weekday="1"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        locale="sv"
                        :disabled="!product.startDate"
                        @input="validateProduct(product)"
                      >
                      </b-form-datepicker>
                    </td>
                  </tr>
                  <template v-for="obj in product.objects">
                    <tr class="object-child" :key="obj.objectID">
                      <td class="objectTitle">
                        {{ obj.title }} {{ getTerm(obj.termID) }}
                      </td>
                      <td>{{ obj.objectValue.toLocaleString() }} SEK</td>
                      <td>
                        <b-form-datepicker
                          v-model="obj.startDate"
                          placeholder="Start datum"
                          class="mb-2"
                          :start-weekday="1"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                          locale="sv"
                          @input="validateObject(obj)"
                        >
                        </b-form-datepicker>
                      </td>
                      <td>
                        <b-form-datepicker
                          v-model="obj.endDate"
                          placeholder="Tills vidare"
                          class="mb-2"
                          :start-weekday="1"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                          }"
                          locale="sv"
                          :disabled="!obj.startDate"
                          @input="validateObject(obj)"
                        >
                        </b-form-datepicker>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "validity",
  props: ["insuranceid", "premiums"],
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey", "terms"]),
  },

  methods: {
    getTerm(termID) {
      let term = this.terms.find((term) => term.termID == termID);
      return term ? "(" + term.term + ")" : "(tom)";
    },

    // api/draft.php?type=setProductDate&startDate=&endDate=&draftProductID=
    validateProduct(product) {
        console.log(product)
        let startDate = (product.startDate) ? product.startDate : '';
        let endDate = (product.endDate) ? product.endDate : '';

        // console.log('Product startDate: ' + startDate);
        // console.log('Product endDate: ' + endDate);

        window.axios.get(this.baseUrl+'api/draft.php?type=setProductDate&startDate='+startDate+'&endDate='+endDate+'&draftProductID='+product.draftProductID+'&key='+this.apiKey)
        .then((response) => {
            if (response.data.status == 'ok') {
                console.log(response.data)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    },

    // api/draft.php?type=setObjectDate&startDate=&endDate=&objectID=
    validateObject(object) {
        console.log(object)
        let startDate = (object.startDate) ? object.startDate : '';
        let endDate = (object.endDate) ? object.endDate : '';

        // console.log('Object startDate: ' + startDate)
        // console.log('object endDate' + endDate)

        window.axios.get(this.baseUrl+'api/draft.php?type=setObjectDate&startDate='+startDate+'&endDate='+endDate+'&objectID='+object.objectID+'&key='+this.apiKey)
        .then((response) => {
            if (response.data.status == 'ok') {
                console.log(response.data)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    },

    selectAllStartDates() {
        this.premiums.forEach((premium) => {
            premium.startDate = this.startDate;
            this.validateProduct(premium);
            if (premium.objects.length > 0) {
                premium.objects.map((po) => {
                    po.startDate = this.startDate;
                    this.validateObject(po);
                })
            }
            if (premium.subProducts.length > 0) {
                premium.subProducts.map((sp) => {
                    sp.startDate = this.startDate;
                    this.validateProduct(sp);
                    sp.objects.map((spObj) => {
                        spObj.startDate = this.startDate;
                        this.validateObject(spObj);
                    })
                })
            }
        })
    },

    selectAllEndDates() {
        this.premiums.forEach((premium) => {
            premium.endDate = this.endDate;
            this.validateProduct(premium);
            if (premium.objects.length > 0) {
                premium.objects.map((po) => {
                    po.endDate = this.endDate;
                    this.validateObject(po);
                })
            }
            if (premium.subProducts.length > 0) {
                premium.subProducts.map((sp) => {
                    sp.endDate = this.endDate;
                    this.validateProduct(sp);
                    sp.objects.map((spObj) => {
                        spObj.endDate = this.endDate;
                        this.validateObject(spObj);
                    })
                })
            }
        })
    },

    selectAllEndDate() {
        console.log(this.endDate)
    }
  },
};
</script>

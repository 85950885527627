<template>
  <div class="damage-management w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <label>Skadedatum fr o m</label>
                  <datepicker
                    format="yyyy-MM-dd"
                    input-class="form-control form-control-lg"
                    :bootstrap-styling="true"
                    v-model="startDate"
                    :language="sv"
                    :disabledDates="disabledDates"
                    @selected="setStart"
                    placeholder="Startdatum"
                  ></datepicker>
                </div>
                <div class="col">
                  <label>Skadedatum t o m</label>
                  <datepicker
                    format="yyyy-MM-dd"
                    input-class="form-control form-control-lg"
                    :bootstrap-styling="true"
                    v-model="endDate"
                    @selected="setEnd"
                    :language="sv"
                    :disabledDates="disabledDates"
                    placeholder="Slutdatum"
                  ></datepicker>
                </div>
                <div class="col">
                  <label>Filter</label>
                  <select
                    class="form-control form-control-lg"
                    name="damageType"
                    id="damageType"
                    v-model="damageType"
                    @change="fetchDamages()"
                  >
                    <option value="0" selected>Alla skador</option>
                    <option value="1">Endast pågående</option>
                    <option value="2">Endast avslutade</option>
                  </select>
                </div>
              </div>
              <div class="row" v-if="damageType == 2">
                <div class="col">
                  <label>Avslutat fr o m</label>
                  <datepicker
                    format="yyyy-MM-dd"
                    input-class="form-control form-control-lg"
                    :bootstrap-styling="true"
                    v-model="endStartDate"
                    :language="sv"
                    :disabledDates="disabledDates"
                    @selected="setEndStart"
                    placeholder="Startdatum"
                  ></datepicker>
                </div>
                <div class="col">
                  <label>Avslutat t o m</label>
                  <datepicker
                    format="yyyy-MM-dd"
                    input-class="form-control form-control-lg"
                    :bootstrap-styling="true"
                    v-model="endEndDate"
                    :language="sv"
                    :disabledDates="disabledDates"
                    @selected="setEndEnd"
                    placeholder="Slutdatum"
                  ></datepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>
          <a
            target="_blank"
            :href="
              baseUrl +
              'api/getDamageReport.php?startDate=' +
              moment(startDate).format('YYYY-MM-DD') +
              '&endDate=' +
              moment(endDate).format('YYYY-MM-DD') +
              '&damageType=' +
              damageType +
              '&key=' +
              apiKey
            "
            >Expertera till CSV</a
          >
        </p>
        <div class="col-md-12">
          <div class="table-responsive" v-if="damages.length > 0">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Skadenummer</th>
                  <th scope="col">Skadedatum</th>
                  <th scope="col">Avslutsdatum</th>
                  <th scope="col">Försäkringsnr</th>
                  <th scope="col">Kategori</th>
                  <th scope="col">Utbetald kostnad</th>
                  <th scope="col">Prognoskostnad</th>
                  <th scope="col">Differens</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(damage, index) in damages" :key="index">
                  <td>
                    <router-link
                      :to="{
                        name: 'damage-details',
                        params: { damageID: damage.damageID },
                      }"
                    >
                      {{ damage.damageNr ? damage.damageNr : "(saknas)" }}
                    </router-link>
                  </td>
                  <td>{{ damage.damageDate }}</td>
                  <td>{{ damage.endDate }}</td>
                  <td>{{ damage.insuranceNr }}</td>
                  <td>{{ damage.category }}</td>
                  <td>
                    {{
                      damage.cost == null ? "" : (damage.cost - getSr(damage.pbb, damage.srtyp, damage.srman)).toLocaleString()
                    }}
                  </td>
                  <td>
                    {{
                      damage.prognosis == null
                        ? ""
                        : damage.prognosis.toLocaleString()
                    }}
                  </td>
                  <td>
                    {{
                      damage.diff == null ? "" : (damage.diff - getSr(damage.pbb, damage.srtyp, damage.srman)).toLocaleString()
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="alert alert-secondary"
            role="alert"
            v-if="damages.length == 0"
          >
            Ingen skada hittades
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
import Datepicker from "vuejs-datepicker";
import { sv } from "vuejs-datepicker/dist/locale";
import moment from "moment";

export default {
  name: "damage-report",
  data() {
    return {
      sv: sv,
      moment: moment,
      keyword: "",
      damages: [],
      startDate: "2020-01-01",
      endDate: moment().format("YYYY-MM-DD"),
      endStartDate: null,
      endEndDate: null,
      user: "",
      damageType: 0,
      disabledDates: {
        from: new Date(),
      },

      category: "",
      categories: [],
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  components: {
    Navbar,
    Datepicker,
  },

  mounted() {
    this.fetchDamages();
  },

  methods: {
    getSr(pbb, srtyp, srman) {
      let sr = 0;
      if (srman != null && srman > 0) {
        sr = srman;
      } else if (srtyp != null && srtyp > 100) {
        sr = srtyp;
      } else if (srtyp != null && srtyp > 0) {
        sr = (srtyp / 100) * pbb;
      }
      return sr;
    },
    setStart(value) {
      this.startDate = value;
      this.fetchDamages();
    },
    setEnd(value) {
      this.endDate = value;
      this.fetchDamages();
    },
    setEndStart(value) {
      this.endStartDate = value;
      this.fetchDamages();
    },
    setEndEnd(value) {
      this.endEndDate = value;
      this.fetchDamages();
    },
    fetchDamages() {
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=damageReport&startDate=" +
            moment(this.startDate).format("YYYY-MM-DD") +
            "&endDate=" +
            moment(this.endDate).format("YYYY-MM-DD") +
            "&endStartDate=" +
            (this.endStartDate != null ? moment(this.endStartDate).format("YYYY-MM-DD") : '') +
            "&endEndDate=" +
            (this.endEndDate != null ? moment(this.endEndDate).format("YYYY-MM-DD") : '') +
            "&damageType=" +
            this.damageType +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          this.damages = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.damage-management {
  .card {
    background: #f1f1f1;
  }
  .table {
    th {
      font-size: 15px;
    }
    td {
      font-size: 0.96rem;
      a {
        color: #4c4c4c;
        font-weight: 600;
        font-size: 0.9rem;
      }
      .external {
        // cursor: pointer;
        margin-right: 20px;
        a {
          color: #3498db;
        }
      }
      .edit {
        // cursor: pointer;
        margin-right: 20px;
        a {
          color: #f1c40f;
        }
      }
      .trash {
        // cursor: pointer;
        a {
          color: #e74c3c;
        }
      }
    }
    tr:hover {
      td .insuranceNr,
      td .customerName {
        color: #3498db;
      }
    }
  }
}
</style>
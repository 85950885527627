<template>
  <div class="messagecustomer">
    <div class="boxheadline" v-if="enableCustomer">
      <h5>Meddelande till kund</h5>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="form-group ml-2 mt-3">
          <textarea
            v-focus
            class="form-control"
            id="message"
            rows="4"
            :placeholder="
              enableCustomer ? 'Skriv meddelande...' : 'Ny anteckning...'
            "
            v-model="message"
            :class="{
              'is-invalid': submitStatus == 'ERROR' && !$v.message.required,
            }"
          ></textarea>
          <div class="invalid-feedback" v-if="!$v.message.required">
            Du måste fylla i en anteckning.
          </div>
        </div>
        <div
          class="form-group bg-white p-2 rounded"
        >
          <label for="user">Ska följas upp av:</label>
          <select
            class="form-control form-green"
            name="user"
            id="user"
            v-model="user"
          >
            <option :value="null" selected>Ingen uppföljning</option>
            <template v-if="users.length > 0">
              <option v-for="(user, index) in users" :key="index" :value="user">
                {{ user.realname }}
              </option>
            </template>
          </select>
        </div>
        <div
          class="form-group px-3 pt-3 bg-white"
          v-if="user != null"
        >
          <label for="notification" style="margin-right: 16px"
            >Notifiering:</label
          >
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              name="notification"
              v-model="notification"
              value="mail"
              id="notification-mail"
            />
            <label class="form-check-label" for="notification-mail"
              >e-mail</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              name="notification"
              v-model="notification"
              value="sms"
              id="notification-sms"
            />
            <label class="form-check-label" for="notification-sms"
              >sms och e-mail</label
            >
          </div>
        </div>

        <div
          class="form-group form-green ml-2"
          v-if="enableCustomer"
        >
          <select
            class="form-control"
            name="customerMsg"
            id="customerMsg"
            v-model="customerMsg"
            :disabled="!enableCustomer"
          >
            <option :value="null" selected>Skicka inget till kunden</option>
            <option value="mail">Skicka anteckningen som e-mail</option>
            <option value="sms">Skicka anteckningen som sms</option>
          </select>
        </div>
        <div class="alert alert-danger mt-2" role="alert" v-if="error != null">
          {{ error }}
        </div>
      </div>
      <div class="col-md-3 align-self-end submitButton">
        <button
          type="button"
          :disabled="
            enableCustomer
              ? customerMsg == null || message == null || message.length == 0
              : message == null || message.length == 0
          "
          class="btn btn-lg btn-block mb-4 mw-80 text-white"
          @click="saveMessage"
        >
          {{ enableCustomer ? "Skicka" : "Spara" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "new-note",
  props: ["customer", "users", "enableCustomer", "damageID"],
  data() {
    return {
      message: null,
      submitStatus: null,
      user: null,
      error: null,
      customerMsg: null,
      notification: "mail",
    };
  },
  computed: {
    ...mapState(["hidden", "baseUrl", "apiKey", "accesslevel"]),
  },
  validations: {
    message: { required },
  },

  methods: {
    saveMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        if (this.customerMsg == "mail" && !this.customer.email) {
          this.submitStatus = "ERROR";
          this.error = "Kunden saknar e-mailadress i systemet.";
          return;
        } else if (this.customerMsg == "sms" && !this.customer.mobilePhone) {
          this.submitStatus = "ERROR";
          this.error = "Kunden saknar mobiltelefonnummer i systemet.";
          return;
        }
        let userID = this.user != null ? parseInt(this.user.userID) : 0;
        if (
          this.customerMsg != null &&
          (this.customerMsg == "mail" || this.customerMsg == "sms")
        ) {
          var result = confirm(
            'Är du säker på att du vill skicka detta meddelande till kunden? "' +
              this.message +
              '"'
          );
          if (!result) {
            return;
          }
        }
        var postdata = new FormData();
        postdata.append("key", this.apiKey);
        postdata.append("id", this.customer.id);
        postdata.append("note", this.message);
        postdata.append("userID", userID);
        postdata.append("notification", this.notification);
        postdata.append("customerMsg", this.customerMsg);
        if (this.damageID) {
          postdata.append("damageID", this.damageID);
        }
        window.axios
          .post(this.baseUrl + "api/saveNote.php", postdata)
          .then((response) => {
            if (response.data.status == "ok") {
              this.submitStatus = "PENDING";
              // this.messages.unshift({
              //   message: this.message,
              //   date: moment(),
              //   author: localStorage.realname
              // });
              this.message = null;
              this.user = null;
              this.customerMsg = null;
              this.$emit("update", true);
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
$color: #5aa196;

.submitButton {
  button {
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #dde3eb;
    margin: 4px;
    background-color: $color;
  }
}
h5 {
  font-size: 1rem;
  line-height: 1.5;
  vertical-align: bottom;
}
.form-green {
  border: 1px solid #5ca296;
  border-radius: 5px;
}
</style>
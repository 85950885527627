<template>
  <div class="invoice mb-3">
    <div class="boxheadline">
      <h5>Skapa faktura</h5>
    </div>
    <div class="content p-3">
      <div class="form-row">
        <div class="col-md-5">
          <b-form-input v-model="year"></b-form-input>
        </div>
        <div class="col-md-3">
          <button
            type="button"
            class="btn btn-block btnLargeGr"
            @click="print()"
          >
            Skriv ut
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      year: moment().format("YYYY"),
      startDate: moment().format("YYYY") + "-01-01",
      endDate: moment().format("YYYY") + "-12-31",
    };
  },

  methods: {
    print() {
      this.startDate = this.year + "-01-01";
      this.endDate = this.year + "-12-31";
      this.$emit("onClick", { startDate: this.startDate, endDate: this.endDate });
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice {
  background: #fff;
}
</style>
<template>
  <div class="damage-management w-100">
    <navbar />
    <div class="content p-4">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Kund/skadenummer"
                    v-model="keyword"
                    @keyup="fetchDamages"
                  />
                </div>
                <div class="col">
                  <input
                    autocomplete="off"
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Rapporterat av"
                    v-model="user"
                    @keyup="fetchDamages"
                  />
                </div>
                <div class="col">
                  <select
                    class="form-control form-control-lg"
                    name="category"
                    id="category"
                    v-model="category"
                    @change="fetchDamages()"
                  >
                    <option value selected>Alla kategorier</option>
                    <template v-if="categories.length > 0">
                      <option
                        v-for="(category, index) in categories"
                        :key="index"
                        :value="category"
                      >{{ category.category }}</option>
                    </template>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="table-responsive" v-if="damages.length > 0">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Skadedatum</th>
                  <th scope="col">Skadenummer</th>
                  <th scope="col">Kund</th>
                  <th scope="col">Kategori</th>
                  <th scope="col">Rapporterat av</th>
                  <th scope="col">Tid</th>
                  <th scope="col">&nbsp;</th>
                  <!-- <th scope="col">&nbsp;</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(damage, index) in damages" :key="index">
                  <td>{{ damage.damageDate }}</td>
                  <td>
                      <router-link
                        :to="{ name: 'damage-details', params: { damageID: damage.damageID }}"
                      >
                        {{ damage.damageNr ? damage.damageNr : '(saknas)' }}
                      </router-link>
                  </td>
                  <td>
                    <router-link
                      class="customerName"
                      :to="{ name: 'customer-show', params: { id: damage.id } }"
                    >{{ damage.companyName ? damage.companyName : damage.firstName + ' ' + damage.lastName }}</router-link>
                  </td>
                  <td>{{ damage.category }}</td>
                  <td>{{ damage.realname }}</td>
                  <td>{{ damage.ts }}</td>
                  <td class="actions">
                    <span class="external">
                    </span>
                    <span class="edit">
                      <router-link
                        :to="{ name: 'edit-damage', params: { damageID: damage.damageID }}"
                      >
                        <font-awesome-icon icon="edit" />
                      </router-link>
                    </span>
                    <span class="trash">
                      <a href="#" @click="deleteDamage(damage)">
                        <font-awesome-icon icon="trash-alt" />
                      </a>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="alert alert-secondary"
            role="alert"
            v-if="damages.length == 0"
          >Ingen skada hittades</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar.vue";
export default {
  name: "damage-management",
  data() {
    return {
      keyword: "",
      damages: [],
      user: "",
      category: "",
      categories: [],
    };
  },

  computed: {
    ...mapState(["baseUrl", "apiKey"]),
  },

  components: {
    Navbar,
  },

  mounted() {
    this.fetchDamageCategories();
    this.fetchDamages();
  },

  methods: {
    fetchDamageCategories() {
      window.axios
        .get(this.baseUrl + "api/getDamageCategories.php?key=" + this.apiKey)
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteDamage(damage) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Vill du verkligen radera skadan?",
          input: "textarea",
          inputPlaceholder: "Anledning",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Radera",
          cancelButtonText: "Avbryt",
          showLoaderOnConfirm: true,
          preConfirm: (message) => {
            if (message === "" || message === null) {
              this.$swal.showValidationMessage("Du måste ange anledning");
            } else {
              var postdata = new FormData();
              postdata.append("id", damage.damageID);

              return window.axios
                .post(
                  this.baseUrl + "api/deleteDamage.php?key=" + this.apiKey,
                  postdata
                )
                .then((response) => {
                  if (response.data.status == "ok") {
                    this.saveNote("Orsak radering av skada: " + message, damage.id);
                    this.fetchDamages();
                  } else {
                    throw new Error(response.statusText);
                  }
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(
                    `Det gick inte att radera skadan: ${error}`
                  );
                });
            }
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire("Klart!", "Skadan raderad.", "success");
          }
        });
    },

    saveNote(message, id) {
      var postdata = new FormData();
      postdata.append("id", id);
      postdata.append("note", message);
      window.axios
        .post(this.baseUrl + "api/saveNote.php?key=" + this.apiKey, postdata)
        .then((response) => {
          if (response.data.status == "ok") {
            console.log(response);
          }
        });
    },

    fetchDamages() {
      this.damages = [];
      window.axios
        .get(
          this.baseUrl +
            "api/get.php?type=damages&search=" +
            this.keyword +
            "&user=" +
            this.user +
            "&categoryID=" +
            this.category.categoryID +
            "&key=" +
            this.apiKey
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.damages = response.data.data;
            // console.log(response.data)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    searchDamages() {
      console.log("Keyword: " + this.keyword);
    },
  },
};
</script>

<style lang="scss" scoped>
.damage-management {
  .card {
    background: #f1f1f1;
  }
  .table {
    th {
      font-size: 15px;
    }
    td {
      font-size: 0.96rem;
      a {
        color: #4c4c4c;
        font-weight: 600;
        font-size: 0.9rem;
      }
      .external {
        // cursor: pointer;
        margin-right: 20px;
        a {
          color: #3498db;
        }
      }
      .edit {
        // cursor: pointer;
        margin-right: 20px;
        a {
          color: #f1c40f;
        }
      }
      .trash {
        // cursor: pointer;
        a {
          color: #e74c3c;
        }
      }
    }
    tr:hover {
      td .insuranceNr,
      td .customerName {
        color: #3498db;
      }
    }
  }
}
</style>
<template>
  <div class="row docs-holder uploader" style="margin-left: 15px">
    <div class="col-md-12 whitebox" style="margin-top: 15px; margin-left:-15px;">
      <div class="header" style="border:none;">
        <div class="row" style="margin-left: 10px;">
          <button
            type="button"
            class="btn btn-secondary"
            v-if="!dropzone"
            @click="addImages()"
          >
            <font-awesome-icon icon="plus-circle" />&nbsp;Ladda upp
          </button>
        </div>
        <div class="actions" v-if="dropzone">
          <button
            type="button"
            class="btn mr-3 btnSave"
            :disabled="files.length == 0"
            @click="saveImages()"
          >
            <span v-if="!loading">
              <font-awesome-icon icon="share-square" />&nbsp;Spara
            </span>
            <span v-if="loading">
              <pulse-loader :loading="loading" size="15px"></pulse-loader>
            </span>
          </button>
          <button
            type="button"
            class="btn btn-danger btn-cancel"
            :disabled="loading"
            @click="resetDropzone()"
          >
            <font-awesome-icon icon="times-circle" />&nbsp;Avbryt
          </button>
        </div>
      </div>
      <div
        class="alert alert-success"
        role="alert"
        v-if="alertMassage != null && !dropzone"
      >
        {{ alertMassage }}
      </div>
      <pulse-loader :loading="loading && !dropzone" size="18px"></pulse-loader>
      <div class="table-responsive docs" v-if="docs.length > 0 && !dropzone">
        <table class="table">
          <tbody>
            <tr v-for="(doc, index) in docs" :key="index">
              <td>
                <div class="thumbnail">
                  <img
                    :src="getURL(doc.fileID)"
                    class="img-thumbnail"
                    width="60px"
                    v-if="fileType(doc.mimetype) == 'IMAGE'"
                  />
                  <img
                    :src="require('@/assets/pdf.png')"
                    class="img-thumbnail"
                    width="60px"
                    v-if="fileType(doc.mimetype) == 'PDF'"
                  />
                  <a
                    class="overlay text-center"
                    target="_blank"
                    :href="getURL(doc.fileID)"
                  >
                    <font-awesome-icon
                      icon="share-square"
                      style="margin-left: 10px"
                    />
                  </a>
                </div>
              </td>
              <td>
                <span v-if="doc.description != 'null'">{{
                  doc.description
                }}</span>
                <span v-if="doc.description == 'null'">&nbsp;</span>
              </td>
              <td>{{ doc.ts }}</td>
              <td>
                <span class="edit" @click="deleteFile(doc.fileID)">
                  <font-awesome-icon icon="trash-alt" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="alert alert-primary"
        role="alert"
        v-if="docs.length == 0 && !dropzone && !loading"
      >
        Inga filer uppladdade
      </div>
      <div class="image-zone" v-if="dropzone">
        <div class="table-responsive preview" v-if="files.length > 0">
          <table class="table">
            <tbody>
              <tr v-for="(file, index) in files" :key="index">
                <td>
                  <img
                    :src="createURL(file.file)"
                    class="img-thumbnail"
                    width="60px"
                    v-if="fileType(file.file.type) == 'IMAGE'"
                  />
                  <img
                    :src="require('@/assets/pdf.png')"
                    class="img-thumbnail"
                    width="60px"
                    v-if="fileType(file.file.type) == 'PDF'"
                  />
                </td>
                <td>{{ file.name }}</td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Ange beskrivning"
                    v-model="file.description"
                  />
                </td>
                <td>{{ file.uploadedAt }}</td>
                <td>
                  <span class="remove" @click="removeImage(index)">
                    <font-awesome-icon icon="times-circle" />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="field imageDropzone">
          <small>Filformat som stöds är pdf, jpg och png.</small>
          <div
            id="dropzone"
            :class="['dropzone', dragging ? 'dragover' : '']"
            @drop="handleImageDrop"
            @dragstart="dragging = true"
            @dragover="dragging = true"
            @dragenter="dragging = true"
            @dragleave="dragging = false"
            @dragend="dragging = false"
          >
            <h2 class="title">Dra och släpp filen här</h2>
            <p>eller</p>
            <div class="custom-file mb-3">
              <input
                type="file"
                class="custom-file-input"
                id="file"
                @change="onFileChange"
                required
              />
              <label class="custom-file-label" for="file" data-browse="Välj fil"
                >Välj fil...</label
              >
              <div class="invalid-feedback">
                Example invalid custom file feedback
              </div>
            </div>
          </div>
        </div>
        <div
          class="alert alert-danger"
          role="alert"
          v-if="errorMessage != null"
        >
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "file-upload",
  props: ["uploadUrl", "docs"],
  data() {
    return {
      // client: null,
      isOpen: false,
      dropzone: false,
      dragging: false,
      alertMassage: null,
      loading: false,
      extensions: ["png", "jpg", "jpeg", "pdf"],
      errorMessage: null,
      url: null,
      files: [],
    };
  },
  components: {
    PulseLoader,
  },
  computed: {
    ...mapState(["hidden", "baseUrl", "apiKey", "accesslevel"]),
  },

  mounted() {
    document.addEventListener("drop", function (e) {
      e.preventDefault();
    });
    document.addEventListener("dragover", function (e) {
      e.preventDefault();
    });
  },

  methods: {
    handleDragOver() {
      this.dragging = true;
    },

    handleImageDrop(e) {
      this.errorMessage = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (this.isImageValidity(files[i])) {
          let fileName = files[i].name.split(".");
          this.files.push({
            file: files[i],
            name: fileName[0],
            description: null,
          });
        } else {
          this.errorMessage = "En eller några filer är inte tillåtna att välja";
        }
      }
    },

    deleteFile(fileID) {
      if (confirm("Är du säker på att du vill ta bort filen?")) {
        window.axios
          .get(
            this.baseUrl +
              "api/edit.php?type=deleteFile&fileID=" +
              fileID +
              "&key=" +
              this.apiKey
          )
          .then(() => {
            this.$emit("update", true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getURL(fileID) {
      return (
        this.baseUrl +
        "api/getFile.php?fileID=" +
        fileID +
        "&key=" +
        this.apiKey
      );
    },

    addImages() {
      this.dropzone = !this.dropzone;
      this.alertMassage = null;
    },

    createURL(file) {
      let url = URL.createObjectURL(file);

      return url;
    },

    resetDropzone() {
      this.dropzone = !this.dropzone;
      this.files = [];
      this.errorMessage = null;
    },

    removeImage(selectedIndex) {
      this.$delete(this.files, selectedIndex);
    },

    onFileChange(e) {
      this.errorMessage = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      if (this.isImageValidity(files[0])) {
        let fileName = files[0].name.split(".");
        this.files.push({
          file: files[0],
          name: fileName[0],
          description: null,
        });
      } else {
        this.errorMessage = "En eller några filer är inte tillåtna att välja";
      }
    },

    isImageValidity(file) {
      const name = file.name.split(".");
      const extension = name[1];
      const check = this.extensions.includes(extension);

      if (check) {
        return true;
      }

      return false;
    },

    fileType(mimetype) {
      if (
        mimetype == "image/png" ||
        mimetype == "image/jpeg" ||
        mimetype == "image/jpg"
      ) {
        return "IMAGE";
      } else if (mimetype == "application/pdf") {
        return "PDF";
      }
    },

    saveImages() {
      this.loading = true;
      let damageID = this.$route.params.damageID;
      this.files.forEach((file, index) => {
        var formData = new FormData();
        formData.append("file", file.file);
        formData.append("description", file.description);
        formData.append("damageID", damageID);
        window.axios
          .post(
            this.baseUrl + this.uploadUrl + "key=" + this.apiKey,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.status == "ok") {
              if (index === this.files.length - 1) {
                this.loading = false;
                this.alertMassage = "Filerna uppladdade";
                this.files = [];
                this.dropzone = !this.dropzone;
                this.$emit("update", true);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
};
</script>

<style lang="scss">
.divborder {
  border-radius: 5px;
  //border: 1px solid #dde3eb;
}
.divbg {
  background: white;
}
.toolbar {
  img {
    margin: 8px;
  }
  button {
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    //border: 1px solid #dde3eb;
    margin: 4px;
    background-color: white;
  }
}
.uploader {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #d7d7dd;
    border-bottom: 1px solid #d7d7dd;
    padding: 15px 10px;
    background: white;
    margin: 20px 0;
    h4 {
      margin: 0;
      padding: 0;
    }
  }
  .docs {
    table {
      tr:hover {
        background: #f1f1f1;
        td .overlay {
          display: block;
        }
      }
      td {
        vertical-align: middle;
        .thumbnail {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 60px;
          height: 60px;
          .overlay {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.65);
            z-index: 9999;
            color: #fff;
            cursor: pointer;
            border-radius: 4px;
            svg {
              font-size: 20px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        .edit {
          cursor: pointer;
          &:hover {
            color: #209cee;
          }
        }
      }
      p {
        margin: 0;
        padding: 0;
      }
      img {
        border: 0;
      }
    }
  }
  .image-zone {
    .preview {
      table {
        tr:hover {
          background: #f1f1f1;
        }
        td {
          vertical-align: middle;
        }
        p {
          margin: 0;
          padding: 0;
        }
        img {
          border: 0;
        }
        svg {
          color: #e74c3c;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
    .imageDropzone {
      margin: 15px 0;
    }

    .dropzone {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 2px dashed #8e8e8e;
      padding: 35px 0;
      .custom-file {
        width: 400px;
      }
      .title {
        text-transform: uppercase;
        color: #6f6f6f;
        font-size: 22px;
      }
      .file {
        align-items: center;
        justify-content: center;
      }
    }
    .dropzone.dragover {
      border-color: #209cee;
      background: #efefef;
    }
  }
}
</style>